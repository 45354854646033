import axios from '../axios/app';

const AttachmentRepository = {

    downloadAttachment: (id) => {
        return axios.get("/rest/attachment/download/"+id)
    },

    addAttachmentForInstitution: (institutionId, formData) => {
        return axios.post(`/rest/attachment/${institutionId}`, formData);
    },

    addAttachmentForUser: (userId, formData) => {
        return axios.post(`/rest/attachment/user/${userId}`, formData);
    }
};
export default AttachmentRepository;