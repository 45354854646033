import React, {Component} from "react";
import "./css/FullReport.css";
import FullReportRepository from "../../repository/FullReportRepository";
import CriteriaRepository from "../../repository/CriteriaRepository";
import {strings} from "../../Localization/Localization";
import {toast} from "react-toastify";
import Select from "react-select";
import {Modal} from "@material-ui/core";
import {faAngleDoubleRight, faCheck, faDownload, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReportElementRepository from "../../repository/ReportElementRepository";
import ShareEvaluatedReport from "./HelperModals/ShareEvaluatedReport";
import EditReportElement from "../ReportElement/EditReportElement";
import InstitutionCategoryRepository from "../../repository/InstitutionCategoryRepository";
import ReactPaginate from "react-paginate";
import {LOCALHOST} from "../../shared/utility";

class EvaluationOfFullReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            criterias: [],
            criteria: "",
            optionsInstitutions: [],
            institution: {},
            institutionName: null,
            institutionId: null,
            listCriterias: [],
            currentCriteriaId: 0,
            showCreateModal: false,
            modalBody: null,
            idFullReport: this.props.match.params.id,
            criteriaName: null,
            counter: 0,
            reportName: null,
            numberOfCriterias: 0,
            listOfInstitutions: [],
            reportElement: {},
            allScores: [],
            allActiveInstitutions: 0,
            allCategories: [],
            categoryId: 0,
            categoryName: null,
            category: {},
            pageCount: 1,
        }
    }

    getFullReport = () => {
        let id = this.props.match.params.id;
        FullReportRepository.findFullReport(id).then(res => {
            this.setState({
                data: res.data,
                reportName: res.data.name
            })
        }).catch(err => {
            console.log(err);
        })
    }

    componentDidMount() {
        this.fetchData();
    }

    handleAfterEvaluation = () => {
        if (this.props.history.location.state) {
            this.setState({
                categoryId: this.props.history.location.state.category.value,
                categoryName: this.props.history.location.state.category.label,
                institutionId: this.props.history.location.state.institution.value,
                institutionName: this.props.history.location.state.institution.label,
                listOfInstitutions: this.props.history.location.state.listOfInstitutions,
                optionsInstitutions: [],
                category: this.props.history.location.state.category,
                institution: this.props.history.location.state.institution,
            })
            // await this.getRefreshedInstitutions(this.props.history.location.state.category.value)
        }
    }



    fetchData() {
        this.getFullReport();
        this.getAllCriterias();
        // this.getAllInstitutions();
        this.countCriterias();
        this.getListOfInstitutions();
        // this.getAllInstitutionsListForReportNotEvaluated();
        this.getAllCategories();
        this.handleAfterEvaluation();
        // this.handleInstitutionsWithoutCategory();
    }

    countCriterias = () => {
        CriteriaRepository.countCriterias().then(res => {
            this.setState({
                numberOfCriterias: res.data
            })
        })
    }

    getAllCategories = () => {
        InstitutionCategoryRepository.getAllInstitutionCategoryList().then(res => {
            let allCategories = []
            for (let i = 0; i < res.data.length; i++) {
                allCategories.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk
                })
            }

            this.setState({
                allCategories: allCategories
            })
        }).catch(err => {
            console.log(err);
        })
    }

    getListOfInstitutions = async (categoryId, selectedPage = 0) => {
        await FullReportRepository.getInstitutionsForReportPaged(this.state.idFullReport, categoryId, selectedPage).then(res => {
            this.setState({
                listOfInstitutions: res.data.content,
                pageCount: res.data.totalPages
            })
        })
    }

    handlePageClick = data => {
        this.getListOfInstitutions(this.state.categoryId, data.selected);
    }

    getAllCriterias = () => {
        CriteriaRepository.getAllCriteria().then(async (res) => {
            if (this.state.institutionId) {
                if (res.data.length > 0) {
                    let data = res.data;
                    let counter = 0;
                    for (var i = 0; i < data.length; i++) {
                        let status = null;
                        await ReportElementRepository.checkEvaluated(this.state.institutionId, data[i].id, this.state.idFullReport).then(snapshot => {
                            if (snapshot.data) {
                                status = snapshot.data;
                                counter++;
                            }
                        });
                        data[i]["statusChecked"] = status;
                    }
                    this.setState({
                        criterias: data,
                        counter: counter,
                    })
                }
            } else {
                this.setState({
                    criterias: res.data,
                })
            }
        })
    }


    getAllInstitutionsListForReportNotEvaluated = async (categoryId) => {
        await FullReportRepository.getAllInstitutionsListForReport(this.state.idFullReport, categoryId).then(res => {
            let optionsInstitutions = [...this.state.optionsInstitutions]
            for (let i = 0; i < res.data.length; i++) {
                optionsInstitutions.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk + ' (Не е евалуирано)',
                })
            }
            this.setState({
                optionsInstitutions: optionsInstitutions
            })

        }).catch(err => {
            toast.error(strings.failTryAgain);
            console.log(err);
        })
    }

    getAllInstitutions = async (categoryId) => {
        await FullReportRepository.getInstitutionsForReport(this.state.idFullReport, categoryId).then(res => {
            let optionsInstitutions = [...this.state.optionsInstitutions]
            for (let i = 0; i < res.data.length; i++) {
                optionsInstitutions.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk,
                })
            }
            this.setState({
                optionsInstitutions: optionsInstitutions
            })

        }).catch(err => {
            toast.error(strings.failTryAgain);
            console.log(err);
        })
    }


    getAllInstitutionsWithoutCategoryNotEvaluated = () => {
        FullReportRepository.getAllInstitutionsWithoutCategoryNotEvaluated(this.state.idFullReport).then(res => {
            let optionsInstitutions = [...this.state.optionsInstitutions]
            for (let i = 0; i < res.data.length; i++) {
                optionsInstitutions.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk + ' (Не е евалуирано)',
                })
            }
            this.setState({
                optionsInstitutions: optionsInstitutions
            })

        }).catch(err => {
            toast.error(strings.failTryAgain);
            console.log(err);
        })
    }

    getAllInstitutionsWithoutCategoryEvaluated = () => {
        FullReportRepository.getInstitutionsWithoutCategoryEvaluated(this.state.idFullReport).then(res => {
            let optionsInstitutions = [...this.state.optionsInstitutions]
            for (let i = 0; i < res.data.length; i++) {
                optionsInstitutions.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk,
                })
            }
            this.setState({
                optionsInstitutions: optionsInstitutions
            })

        }).catch(err => {
            toast.error(strings.failTryAgain);
            console.log(err);
        })
    }


    onSelectedInstitutionHandler = (value, action) => {
        if (value !== null) {
            this.setState({
                institutionName: value.label,
                institutionId: value.value,
                institution: value
            })
            this.getAllCriterias();
        } else {
            this.setState({
                institutionName: null,
                institutionId: null,
            })
        }
    }

    onSelectedCategoryHandler = (value, action) => {
        const categoryId = value.value;
        if (value !== null) {
            this.setState({
                categoryName: value.label,
                categoryId: value.value,
                optionsInstitutions: [],
                institution: {},
                institutionName: null,
                institutionId: null,
                category: value
            })
        } else {
            this.setState({
                categoryName: null,
                categoryId: null,
                optionsInstitutions: [],
            })
        }
        this.getAllInstitutions(categoryId);
        this.getAllInstitutionsListForReportNotEvaluated(categoryId);
        this.getListOfInstitutions(categoryId);
    }

    openModal = async (e) => {
        const criteria = e.target.id;
        const criteriaName = e.target.name;
        let reportElement = {};

        await ReportElementRepository.findByInstitutionAndCriteriaAndReport(this.state.institutionId, criteria, this.state.idFullReport).then(res => {
            reportElement = res.data

            this.setState({
                modalBody: (
                    <EditReportElement criteria={criteria}
                                       criteriaName={criteriaName}
                                       institution={this.state.institution}
                                       institutionId={this.state.institutionId}
                                       institutionName={this.state.institutionName}
                                       idFullReport={this.state.idFullReport}
                                       score={reportElement.score}
                                       link={reportElement.link}
                                       comment={reportElement.comment}
                                       reportElementType={reportElement.reportElementType}
                                       category={this.state.category}
                                       listOfInstitutions={this.state.listOfInstitutions}
                                       optionsInstitutions={this.state.optionsInstitutions}/>
                )
            })
        })
        this.setState({showCreateModal: true});
    }

    closeModal = () => {
        this.setState({
            showCreateModal: false
        });
    }

    saveReport = () => {
        FullReportRepository.reviewAndSaveReport(this.state.idFullReport).then(res => {
            // toast.success(strings.saveEvaluation)
            window.location = '/ListOfReports'
        }).catch(err => {
            toast.error(strings.failTryAgain)
        })
    }

    handleInstitutionsWithoutCategory = () => {
        if (this.state.categoryName === null) {
            this.getAllInstitutionsWithoutCategoryEvaluated();
            this.getAllInstitutionsWithoutCategoryNotEvaluated();
        }
    }

    // getRefreshedInstitutions =  (categoryId) => {
    //     if(categoryId !== null && categoryId !== undefined && categoryId !== 0){
    //         this.getAllInstitutions(categoryId);
    //         this.getAllInstitutionsListForReportNotEvaluated(categoryId);
    //         this.getListOfInstitutions(categoryId);
    //     }
    // }

    sortCriteria = () => {
        this.state.criterias.sort((a, b) => (a.status < b.status) ? 1 : -1).sort((a, b) => (a.statusChecked > b.statusChecked) ? -1 : 1)
    }

    render() {
        {
            this.sortCriteria();
        }
        return (
            <div className="col-12">
                <Modal
                    open={this.state.showCreateModal}
                    onClose={this.closeModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {this.state.modalBody}
                </Modal>
                <div className="row">
                    <h1>{this.state.data.name}</h1>
                </div>

                <div className="row">
                    <div className="col-4 mt-4">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.institutionCategory}</label>
                        <Select
                            placeholder={this.state.categoryName ? this.state.categoryName : " "}
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            options={this.state.allCategories}
                            onChange={this.onSelectedCategoryHandler}
                            name="categoryId"
                            value={this.state.categoryId}
                            defaultValue={{
                                value: this.state.categoryId,
                                label: this.state.categoryName,
                            }}
                        />

                        <br/>

                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.institution}</label>
                        <Select
                            placeholder={this.state.institutionName ? this.state.institutionName : " "}
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            options={this.state.optionsInstitutions}
                            onChange={this.onSelectedInstitutionHandler}
                            name="institutionId"
                            value={this.state.institutionId}
                            defaultValue={{
                                value: this.state.institutionId,
                                label: this.state.institutionName,
                            }}
                        />
                    </div>

                    <div className="col-1"/>
                    <div className="col-7">
                        <h4 className="text-center">{strings.institutionsInEvaluation}</h4>
                        <table className="table-hover newTable" style={{width: "100%"}}>
                            <thead className="tableHead">
                            <th className="tableHeading" style={{borderRadius: '15px 15px 0px 0px'}}>{strings.name}</th>
                            </thead>
                            <tbody>
                            {this.state.listOfInstitutions.map(this.renderInstitutions)}
                            <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                                <td style={{borderRadius: "0 0 15px 15px"}}/>
                            </tr>
                            </tbody>
                        </table>
                        <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            breakLabel={'...'}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div>
                        <h2 className="align-items-center justify-content-center position-relative">{strings.allCriterias}</h2>
                        <table className="table-hover newTable mt-2" style={{width: "100%"}}>
                            <thead className="tableHead">
                            {/*<th>{strings.name}</th>*/}
                            {/*<th>{strings.parentToCriteria}</th>*/}
                            {/*<th>{strings.actions}</th>*/}
                            {/*<th>{strings.actions}</th>*/}
                            {/*<th>{strings.evaluate}</th>*/}
                            {/*<th>{strings.evaluated}</th>*/}
                            <th className="tableHeading firstHeading" style={{width: '70%'}}>{strings.name}</th>
                            <th className="tableHeading" style={{width: '11%'}}>{strings.parentToCriteria}</th>
                            <th className="tableHeading" style={{width: '11%'}}>{strings.evaluate}</th>
                            <th className="tableHeading lastHeading" style={{width: '8%'}}>{strings.evaluated}</th>
                            </thead>
                            <tbody>
                            {this.state.criterias.map(this.renderCriterias)}
                            <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                                <td style={{borderRadius: "0 0 0 15px"}}/>
                                <td/>
                                <td/>
                                <td style={{borderRadius: "0 0 15px 0"}}/>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-10"/>
                    <div className="col-2 mt-3 text-right">
                        {/*<button className="btn btn-sm btn-success"*/}
                        {/*        onClick={this.saveReport}>{strings.saveEvaluateReport}</button>*/}

                        <ShareEvaluatedReport btnClass={"btn importantButton"}
                                              showText={true}
                                              prompt={strings.shareEvaluatedReport}
                                              doDelete={() => this.saveReport()}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-10"/>
                    <div className="col-2 mt-3 text-right">
                        {this.state.institutionId !== null &&
                        <a href={LOCALHOST + "rest/fullReport/export/" + this.state.data.id + "/" + this.state.institutionId}
                           className="btn downloadButton">
                            <FontAwesomeIcon icon={faDownload} size="lg"
                                             style={{paddingRight: '4px'}}/>
                            {strings.exportCSV}</a>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-10"/>
                    <div className="col-2 mt-3 text-right">
                        {this.state.institutionId !== null &&
                        <a href={LOCALHOST + "rest/fullReport/singleReportExport/" + this.state.data.id + "/" + this.state.institutionId}
                           className="btn btn-sm downloadButtonSecond">
                            <FontAwesomeIcon icon={faDownload} size="lg"
                                             style={{paddingRight: '4px'}}/>
                            {strings.exportSingle}
                        </a>
                        }
                    </div>
                </div>

            </div>
        )
    }


    renderCriterias = (criteria) => {
        return (
            <tr key={criteria.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{criteria.name}</td>
                <td className="tableData">{criteria.parentCriteria ? criteria.parentCriteria.name : "No parent criteria"}</td>
                {/*<td>*/}
                {/*    <CrudModal*/}
                {/*        entity={criteria}*/}
                {/*        icon="edit"*/}
                {/*        btnClass="btn-primary btn-sm"*/}
                {/*        title={strings.edit}*/}
                {/*        showText={true}*/}
                {/*        onSubmit={this.onEdit}*/}
                {/*        body={CriteriaEdit}*/}
                {/*    />*/}
                {/*</td>*/}
                {/*{criteria.status ?*/}
                {/*    <td>*/}
                {/*        <DeleteModal btnClass={"btn-sm"}*/}
                {/*                     showText={true}*/}
                {/*                     prompt={strings.deleteCriteriaForCriteria}*/}
                {/*                     doDelete={() => this.deleteCriteria(criteria.id)}/>*/}
                {/*    </td>*/}
                {/*    :*/}
                {/*    <td>*/}
                {/*        <UnDeleteModal btnClass={"btn-sm"}*/}
                {/*                       showText={true}*/}
                {/*                       prompt={strings.shareCriteria}*/}
                {/*                       doDelete={() => this.unDeleteCriteria(criteria.id)}/>*/}
                {/*    </td>*/}
                {/*}*/}

                {criteria.statusChecked ?
                    <td className="tableData">
                        <button id={criteria.id} name={criteria.name} className="btn-sm btn-success defaultBtn"
                                onClick={this.openModal}
                                style={{border: '0'}}>
                            <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" style={{paddingRight: '4px'}}/>
                            {strings.takeLook}
                        </button>
                    </td>
                    :
                    <td className="tableData">
                        <button id={criteria.id} name={criteria.name} className="btn-sm btn-secondary defaultBtn"
                                onClick={this.openModal}
                                style={{border: '0'}}>
                            <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" style={{paddingRight: '4px'}}/>
                            {strings.takeLook}
                        </button>
                    </td>
                }


                <td className="tableData" style={{justifyContent: 'center', alignItems: 'center', borderBottom: '0'}}>
                    {criteria.statusChecked ? <FontAwesomeIcon icon={faCheck} size="lg" style={{
                            marginLeft: '30px',
                            color: 'green',
                            border: '0.5px solid black',
                            borderRadius: '10px',
                            width: '25px',
                            height: '25px'
                        }}/> :
                        <FontAwesomeIcon icon={faTimes} size="lg" style={{marginLeft: '30px'}}/>}
                </td>

            </tr>
        )
    }

    renderInstitutions = (institution) => {
        return (
            <tr key={institution.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{institution.nameMk}</td>
            </tr>
        )
    }
}

export default (EvaluationOfFullReport);