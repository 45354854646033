import React, {Component} from "react";
import {toast} from "react-toastify";
import FeedbackPublicationRepository from "../../repository/FeedbackPublicationRepository";
import {strings} from "../../Localization/Localization";
import {NavLink} from "react-router-dom";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FeedbackRepository from "../../repository/FeedbackRepository";


class VisualizeAnswers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: [],
            feedbackPublication: null,
            feedbackId: 0,
            options: [],
            feedbackTitle: null
        }
    }

    componentDidMount() {
        this.getAllFeedbackAnswers();

        let feedbackPubId = this.props.match.params.id;

        FeedbackPublicationRepository.getFeedbackPublicationById(feedbackPubId).then(async res => {
            this.setState({
                feedbackPublication: res.data
            })
            const feedbackId = res.data.feedback.id
            await FeedbackRepository.getFeedbackById(feedbackId).then(res => {
                this.setState({
                    feedbackTitle: res.data.name
                })
            })
        })
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h2 style={{color: "#1C4857"}}
                            className="mt-4 mb-4 text-center">{strings.previewAnswer}: {this.state.feedbackTitle ? this.state.feedbackTitle : ""}</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-1"/>
                    <div className="col-10">
                        {this.state.answers.map(this.displayChoice)}
                    </div>
                </div>


                <div className="row">
                    <div className="col-1"/>
                    <div className="col-10 mt-3 mb-3 text-right">
                        {this.state.feedbackPublication &&
                        this.state.feedbackPublication.feedback.isFeedback
                            ?
                            <NavLink to="/myfeedbackpublications">
                                <button type="button" className="btn btn-dark mr-3 visualizeFeedbackButtons">
                                    <FontAwesomeIcon icon={faArrowLeft} size="lg"
                                                     style={{paddingRight: '4px'}}/>{strings.goBack}</button>
                            </NavLink>
                            :
                            <NavLink to="/myProactivityPublications">
                                <button type="button" className="btn btn-dark mr-3 visualizeFeedbackButtons">
                                    <FontAwesomeIcon icon={faArrowLeft} size="lg"
                                                     style={{paddingRight: '4px'}}/>{strings.goBack}</button>
                            </NavLink>
                        }

                    </div>
                </div>
            </div>
        )
    }


    displayChoice = (items, index) => {
        let finalOptions = []
        if (items.item.options) {
            let optionsQuestion = items.item.options.map((item, index) => {
                if (items.item.type === "SINGLE_CHOICE") {
                    let e;
                    if (items.value === item) {
                        e = <div key={index}>
                            <input name={items.item.id}
                                   style={{color: 'black', backgroundColor: 'black'}}
                                   className="visualizeFeedbackCheckInput"
                                   readOnly={true}
                                   value={items.value}
                                   checked={true}
                                   disabled={true}
                                   type={"radio"}/>
                            <label className="form-check-label ml-1">{item}</label>
                        </div>
                        finalOptions.push(e)
                    } else {
                        e = <div key={index}>
                            <input name={items.item.id}
                                   style={{color: 'black', backgroundColor: 'black'}}
                                   readOnly={true}
                                   className="visualizeFeedbackCheckInput"
                                   value={items.value}
                                   disabled={true}
                                   type={"radio"}/>
                            <label className="form-check-label ml-1">{item}</label>
                        </div>
                        finalOptions.push(e)
                    }
                    return e
                } else if (items.item.type === "MULTIPLE_CHOICE") {
                    let values = items.value.split(";"); // c i s
                    let e;
                    let flag;
                    flag = values.includes(item)
                    if (flag) {
                        e = <div key={index}>
                            <input
                                className="visualizeFeedbackCheckInput"
                                style={{color: 'black', backgroundColor: 'black'}}
                                checked={true}
                                readOnly={true}
                                disabled={true}
                                type={"checkbox"}/>
                            <label className="form-check-label ml-1">{item}</label>
                        </div>
                        finalOptions.push(e)

                    } else {
                        e = <div key={index}>
                            <input
                                className="visualizeFeedbackCheckInput"
                                style={{color: 'black', backgroundColor: 'black'}}
                                readOnly={true}
                                disabled={true}
                                type={"checkbox"}/>
                            <label className="form-check-label ml-1">{item}</label>
                        </div>
                        finalOptions.push(e)

                    }
                    flag = false;
                    return e
                }

            })
        }

        if (items.item.type === "NUMERIC_FIELD") {
            let y = <div key={index}>
                <input className="visualizeFeedbackCheckInput"
                       value={items.value}
                       readOnly={true}
                       type={"text"}/>
            </div>
            finalOptions.push(y)
        } else if (items.item.type === "TEXT_FIELD") {
            let x = <div key={index}>
                <input className="visualizeFeedbackCheckInput"
                       value={items.value}
                       readOnly={true}
                       type={"text"}/>
            </div>
            finalOptions.push(x)
        }

        return (items.item.subQuestion ?
                (<div className="visualizeFeedbackQuestionContainerChild" key={index} style={{marginLeft: '200px'}}>
                    <div className="row visualizeFeedbackQuestionHeaderDiv">
                        <div className="col-12">
                            <h4 title="Question">{items.item.name}</h4>
                        </div>
                    </div>
                    <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                        {/*<div className="col-12">*/}
                        {/*    <h3>{items.item.description}</h3>*/}
                        {/*</div>*/}
                        <div className="col-12 form-group kt-checkbox">
                            {finalOptions}
                        </div>
                    </div>
                </div>)
                :
                (<div className="container-fluid visualizeFeedbackQuestionContainer" key={index}>
                    <div className="row visualizeFeedbackQuestionHeaderDiv">
                        <div className="col-12">
                            <h4 title="Question">{items.item.name}</h4>
                        </div>
                    </div>
                    <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                        {/*<div className="col-12">*/}
                        {/*    <h3>{items.item.description}</h3>*/}
                        {/*</div>*/}
                        <div className="col-12 form-group kt-checkbox">
                            {finalOptions}
                        </div>
                    </div>
                </div>)
        )
    }

    getAllFeedbackAnswers = () => {
        let feedbackPubId = this.props.match.params.id;
        FeedbackPublicationRepository.getAnswers(feedbackPubId).then(res => {
            this.setState({
                answers: res.data,
            });
        }).then(() => {
            this.state.answers.map(item => {
                this.setState(prevState => ({
                    options: [...prevState.options, item.item.options]
                }))
            })
        }).catch(() => {
            toast.error(strings.failedToLoadAnswers)
        })
    }

}

export default VisualizeAnswers;