import React, {Component} from "react";
import FullReportRepository from "../../repository/FullReportRepository";
import {strings} from "../../Localization/Localization";
import {Link} from "react-router-dom";
import "./css/FullReport.css";
import ReactPaginate from "react-paginate";
import DeleteModal from "../Crud/DeleteModal";
import InfoPopup from "../ErrorHandler/InfoPopup";
import {toast} from "react-toastify";
import CrudModal from "../Crud/CrudModal";
import AddFullReport from "./AddFullReport";
import {ExportCSV} from "../ExportToCSV/ExportCSV";

class ListOfReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeLanguage: localStorage.getItem("activeLanguage"),
            allReports: [],
            pageCount: 1,
            allScores: [],
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = (selectedPage = 0) => {
        FullReportRepository.findAllPageable(selectedPage).then(res => {
            this.setState({
                allReports: res.data.content,
                pageCount: res.data.totalPages,
            })
        }).catch(err => {
            console.log(err);
        })
    }

    handlePageClick = data => {
        this.fetchData(data.selected);
    };

    onAdd = (entity) => {
        return FullReportRepository.createReport(entity).then(() => {
            toast.success(strings.succAddReport)
            this.fetchData();
        }).catch((err) => {
            console.log(err);
            toast.error(strings.unSucAddReport);
        })
    }

    deleteReport = (fullReportId) => {
        return FullReportRepository.deleteFullReport(fullReportId).then(() => {
            toast.success(strings.succDeleteReport)
            this.fetchData()
        }).catch(() => {
            toast.error(strings.unsuccDeleteReport)
        })
    }

    getScoresForInstitutions = (e) => {
        let idFullReport = e.target.id;
        FullReportRepository.getInstitutionsForExcel(idFullReport).then(res => {
            for (let i = 0; i < res.data.length; i++) {
                FullReportRepository.getScoresForInstitutions(idFullReport, res.data[i].id).then(snapshot => {
                    const finalSnap = snapshot.data.filter(snap => snap.score !== 0);

                    this.setState({
                        allScores: [...this.state.allScores, finalSnap[0]]
                    })
                })
            }
        })
        document.getElementById(e.target.name).style.visibility = "visible";
        document.getElementById(e.target.id).setAttribute("disabled", "disabled");

    }

    sortScores = () => {
        this.state.allScores.sort((a, b) => (a.score < b.score) ? 1 : -1)
    }


    render() {
        {
            this.sortScores()
        }
        console.log("sc", this.state.allScores);

        return (
            <div className="container">
                <h1 className="align-items-center justify-content-center"
                    style={{textAlign: 'center', color: "#1C4857"}}>{strings.myReports}</h1>
                <div className="row mt-5" style={{paddingTop: '15px'}}>
                    <CrudModal
                        entity={{}}
                        icon="add"
                        btnClass="btn mt-4 defaultBtnAdd"
                        title={strings.createReport}
                        showText={true}
                        onSubmit={this.onAdd}
                        body={AddFullReport}
                    />
                    {/*<Link to="/allForEvaluation" className="btn btn-dark ml-3 mt-4 defaultBtn"><i*/}
                    {/*    className="fa fa-history"/> {strings.reportsForEvalation}</Link>*/}
                    <br/>
                </div>


                {this.state.allReports.length !== 0 ?
                    this.state.allReports.map((item, index) => {
                        return (
                            <div key={index} className="row ReportMainDiv">
                                <div className="col-12">

                                    <div className="row ReportRow">
                                        <div className="col-12">
                                            {this.state.activeLanguage === 'mk' ?
                                                !item.readyForEvaluation ?
                                                    <a href={"/fullReport/" + item.id} className="mb-0" style={{
                                                        fontSize: '22px',
                                                        fontWeight: '600',
                                                        color: 'black'
                                                    }}>{item.name}
                                                        {item.version === 2 ?
                                                            <button
                                                                id={item.id}
                                                                name={item.name}
                                                                className="btn defaultBtn btn-sm"
                                                                style={{
                                                                    float: 'right',
                                                                    fontSize: '15px',
                                                                    background: '#FFBF00',
                                                                    color: 'black'
                                                                }}
                                                                onClick={this.getScoresForInstitutions}>
                                                                {strings.generateResults}
                                                            </button>
                                                            :
                                                            <div/>
                                                        }
                                                    </a>
                                                    :
                                                    <h5 className="mb-0">{item.name}
                                                        {item.version === 2 ?
                                                            <button
                                                                id={item.id}
                                                                name={item.name}
                                                                className="btn defaultBtn btn-sm"
                                                                style={{
                                                                    float: 'right',
                                                                    fontSize: '15px',
                                                                    background: '#FFBF00',
                                                                    color: 'black'
                                                                }}
                                                                onClick={this.getScoresForInstitutions}>
                                                                {strings.generateResults}
                                                            </button>
                                                            :
                                                            <div/>
                                                        }
                                                    </h5>
                                                : <div/>}


                                            {this.state.activeLanguage === 'al' ?
                                                !item.readyForEvaluation ?
                                                    <a href={"/fullReport/" + item.id} className="mb-0" style={{
                                                        fontSize: '22px',
                                                        fontWeight: '600',
                                                        color: 'black'
                                                    }}>{item.name}
                                                        {item.version === 2 ?
                                                            <button
                                                                id={item.id}
                                                                name={item.name}
                                                                className="btn defaultBtn btn-sm"
                                                                style={{
                                                                    float: 'right',
                                                                    fontSize: '15px',
                                                                    background: '#FFBF00',
                                                                    color: 'black'
                                                                }}
                                                                onClick={this.getScoresForInstitutions}>
                                                                {strings.generateResults}
                                                            </button>
                                                            :
                                                            <div/>
                                                        }
                                                    </a>
                                                    :
                                                    <h5 className="mb-0">{item.name}
                                                        {item.version === 2 ?
                                                            <button
                                                                id={item.id}
                                                                name={item.name}
                                                                className="btn defaultBtn btn-sm"
                                                                style={{
                                                                    float: 'right',
                                                                    fontSize: '15px',
                                                                    background: '#FFBF00',
                                                                    color: 'black'
                                                                }}
                                                                onClick={this.getScoresForInstitutions}>
                                                                {strings.generateResults}
                                                            </button>
                                                            :
                                                            <div/>
                                                        }
                                                    </h5>
                                                : <div/>}

                                            {this.state.activeLanguage === 'en' &&
                                            <h5 className="mb-0">{item.name}
                                                {item.version === 2 ?
                                                    <button
                                                        id={item.id}
                                                        name={item.name}
                                                        className="btn defaultBtn btn-sm"
                                                        style={{
                                                            float: 'right',
                                                            fontSize: '15px',
                                                            background: '#FFBF00',
                                                            color: 'black'
                                                        }}
                                                        onClick={this.getScoresForInstitutions}>
                                                        {strings.generateResults}
                                                    </button>
                                                    :
                                                    <div></div>
                                                }
                                            </h5>
                                            }
                                        </div>
                                    </div>

                                    <div className="container-fluid ReportPublicationDivContainer">
                                        {/*<div className="row announcementTitleRow">*/}
                                        {/*    <div className="col-12">*/}
                                        {/*        <h2 className="announcementTitle">{item.title}</h2>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="row ReportAnnouncementBodyRow">
                                            <div className="col-12" style={{textAlign: 'center'}}>
                                                {!item.readyForEvaluation
                                                    ?
                                                    <a style={{
                                                        display: "inline-block",
                                                        textAlign: 'center',
                                                        fontSize: '20px'
                                                    }}>
                                                        <Link
                                                            to={"/fullReport/" + item.id}
                                                            className="announcementLinkButton">{strings.detailsForReport}</Link>
                                                    </a>
                                                    :
                                                    item.version === 1
                                                        ?
                                                        <a style={{
                                                            display: "inline-block",
                                                            textAlign: 'center',
                                                            fontSize: '20px'
                                                        }}>
                                                            <h4>{strings.alreadySent}</h4>
                                                        </a>
                                                        :
                                                        <div>
                                                            <h4 style={{
                                                                display: 'inline',
                                                                padding: '6px',
                                                                // textDecoration: 'underline solid green 4px',
                                                                borderBottom: '3px solid green',
                                                                borderRadius: '20px'
                                                            }}>{strings.evaluatedFinished}
                                                            </h4>
                                                        </div>


                                                }
                                            </div>
                                        </div>
                                        {/*<div className="row announcementFooterRow">*/}
                                        {/*    <div className="col-12 announcementFooterCol">*/}
                                        {/*        <h6 style={{float: 'right', color: '#343a40'}}>*/}
                                        {/*            <FontAwesomeIcon icon={faComments} size="lg"*/}
                                        {/*                             style={{paddingRight: '4px'}}/>*/}
                                        {/*            {this.state.numOfElements}*/}
                                        {/*        </h6>*/}

                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="col-12 ReportAnnouncementFooterCol">
                                            <h6 style={{float: 'left', color: '#343a40'}}>
                                                <h6>{strings.authorCreator} {this.state.allReports[index].reportCreator.email}</h6>
                                                <h6>{strings.institution}: {this.state.allReports[index].reportCreator.institution !== null ? this.state.allReports[index].reportCreator.institution.nameMk : "/"}</h6>
                                                <span>{strings.yearCreate}: {this.state.allReports[index].yearOfCreation}</span>
                                            </h6>
                                        </div>

                                        <span style={{float: 'right', visibility: 'hidden'}} id={item.name}>
                                                        {item.version === 2 &&
                                                        <ExportCSV csvData={this.state.allScores}
                                                                   fileName={item.name + "_сумарен"}/>
                                                            // <a href={"http://localhost:9091/rest/fullReport/exportFullReport/" + item.id}
                                                            //    className="btn downloadButton">
                                                            //     <FontAwesomeIcon icon={faDownload} size="lg"
                                                            //                      style={{paddingRight: '4px'}}/>
                                                            //     {strings.exportCSV}
                                                            // </a>
                                                        }
                                        </span>

                                    </div>
                                    <div className="row">
                                        <div className="col-12 ">
                                            {!item.readyForEvaluation &&
                                            <DeleteModal btnClass={"ml-1 ReportAdminActionButton defaultBtn"}
                                                         showText={true}
                                                         prompt={strings.deleteReportForReport}
                                                         doDelete={() => this.deleteReport(item.id)}/>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    }) : <div style={{display: 'flex', alignItems: 'center', height: '100%'}} className="mt-3">
                        <InfoPopup infoMessage={strings.noReports}/>
                    </div>
                }

                {this.state.allReports.length !== 0 ?
                    <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    /> : <div></div>
                }
            </div>
        )
    }
}

export default (ListOfReports);