import axios from "../axios/app";

const CriteriaTypeOptionRepository = {

    createOption: (criteriaTypeId, option) => {
        return axios.post(`/rest/criteriaTypeOption/create/${criteriaTypeId}`, option)
    },

    editOption: (option) => {
        return axios.put(`/rest/criteriaTypeOption/editOption`, option)
    },

    deleteOption: (optionId) => {
        return axios.delete(`/rest/criteriaTypeOption/delete/${optionId}`)
    }

}

export default CriteriaTypeOptionRepository;