import axios from '../axios/app';

const AnnualReportRepository = {

    getAllByYear: (year) => {
        return axios.get("/rest/yearlyreport/all?year=" + year)
    },
    getAllByYearAndKeywordPaginated: (year, keyword, page) => {
        return axios.get(`/rest/yearlyreport/allPaged?year=${year}&keyword=${keyword}&page=${page}&size=10`)
    },
    getByYearAndInstitution: (institutionId, year) => {
        return axios.get(`/rest/yearlyreport?institutionId=${institutionId}&year=${year}`)
    },
    saveByYear: (form) => {
        return axios.post("/rest/yearlyreport/savereport", form)
    },

    updateAnnualReport: (form) => {
        return axios.patch("/rest/yearlyreport/updateReport", form)
    },

    submitAnnualReport: (pyrId) => {
        return axios.post("/rest/yearlyreport/submitReport?pyrId="+pyrId)
    },

    uploadSignedFile: (request) => {
        return axios.post("/rest/yearlyreport/uploadAttachment",request)
    },

    getAllLinksByReport: id => {
      return axios.get(`/rest/yearlyreport/links-by-report/${id}`)
    },

};

export default AnnualReportRepository;
