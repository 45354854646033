import React, {Component} from "react";
import {Button, ButtonToolbar} from "react-bootstrap";
import FeedbackRepository from "../../repository/FeedbackRepository";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import ReactPaginate from 'react-paginate';
import CrudModal from "../Crud/CrudModal";
import DeleteModal from "../Crud/DeleteModal";
import {strings} from "../../Localization/Localization";
import {faDownload, faEdit, faPalette, faSearch, faShare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './css/FeedbackCrudTable.css';
import {LOCALHOST} from "../../shared/utility";
import EditProactiveTransparency from "./EditProactiveTransparency";
import AddProactiveTransparency from "./AddProactiveTransparency";

class ProactiveTransparencyCrudTable extends Component {

    state = {
        feedbacks: [],
        pageCount: 1,
        userRole: localStorage.getItem('role'),
        userEmail: localStorage.getItem('email')
    }

    componentDidMount() {
        this.fetchData();
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
    }

    render() {
        return (

            <div>
                <div className="row mt-3">
                    <div className="col-6">
                        <h2 style={{color: "#1C4857"}} className="mt-3">{strings.proactiveTransparency}</h2>
                    </div>
                    <div className="col-6 justify-content-end text-right">
                        <CrudModal entity={{}}
                                   icon="add"
                                   showText={true}
                                   btnClass="btn-primary mb-2 mt-4 defaultBtnAdd"
                                   title={strings.add}
                                   onSubmit={this.onAdd}
                                   body={AddProactiveTransparency}/>
                    </div>
                </div>


                <table className="table-hover newTable mt-3" style={{width: "100%"}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '8%'}}>{strings.creator}</th>
                    <th className="tableHeading" style={{width: '8%'}}>{strings.name}</th>
                    <th className="tableHeading" style={{width: '12%'}}>{strings.description}</th>
                    <th className="tableHeading" style={{width: '5%'}}>{strings.evaluationYear}</th>
                    <th className="tableHeading" style={{width: '7%'}}>{strings.dueDate}</th>
                    <th className="tableHeading" style={{width: '10%'}}>{strings.allAnswered}</th>
                    <th className="tableHeading lastHeading text-center" style={{width: '50%'}}>{strings.actions}</th>
                    </thead>
                    <tbody>
                    {this.state.userRole === "ROLE_ADMIN" ? this.state.feedbacks.map(this.renderFeedbackItemAdmin) : this.state.feedbacks.map(this.renderFeedbackItemUser)}
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>

                    </tbody>
                </table>


                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        )
    }


    fetchData = (selectedPage = 0) => {
        FeedbackRepository.getAllNotFeedback(selectedPage).then(res => {
            this.setState({
                feedbacks: res.data.content,
                pageCount: res.data.totalPages
            })
        }).catch(err => {
            console.log(err)
        })
    }


    handlePageClick = data => {
        this.fetchData(data.selected);
    };


    onAdd = (entity) => {
        const form = new FormData();
        form.append('name', entity.name);
        form.append('description', entity.description);
        // const dueDate = entity.dueDate || new Date();
        form.append('dueDate', moment(entity.dueDate).format("MM/DD/YYYY"));
        form.append('isFeedback', "false");
        form.append('selectedYear', moment(entity.selectedYear).format("YYYY"));

        if (entity.name !== "" && entity.description !== "") {
            return FeedbackRepository.addFeedback(form).then(res => {
                toast.success(strings.successfullyAddedProactivity)
                this.props.history.push("/designTransparency/" + res.data.id);
            }).catch(err => {
                toast.error(strings.failTryAgain)
            });
        }
    }

    onEdit = (entity) => {
        return FeedbackRepository.editFeedback(entity).then(async () => {
            toast.success(strings.feedbackSuccessfullyEdited)
            await this.sleep(4000);
            window.location.reload();
            this.fetchData();
        });
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    deleteFeedback = (id) => {
        return FeedbackRepository.deleteFeedback(id).then(() => {
            this.fetchData();
            toast.success(strings.successfullyDeletedProactivity)
        }).catch(() => {
            toast.error(strings.failTryAgain)
        });
    }

    renderFeedbackItemUser = feedback => {
        return (<tr key={feedback.id} style={{border: "1px solid lightgray"}}>
            <td className="tableData">{feedback.creator.firstName + " " + feedback.creator.lastName}</td>
            {feedback.creator.institution !== null ?
                <td className="tableData">{localStorage.getItem("activeLanguage") === "mk" ? feedback.creator.institution.nameMk : feedback.creator.institution.nameAl}</td> :
                <td className="tableData">{strings.noInstitution} </td>}
            <td className="tableData">{feedback.name}</td>
            <td className="tableData">
                <div style={{wordWrap: "break-word"}}>{feedback.description}</div>
            </td>
            <td className="tableData">{feedback.selectedYear}</td>
            <td className="tableData">{moment(feedback.dueDate).local().format('DD.MM.YYYY')}</td>
            <td colSpan={4} className="tableData">
                {localStorage.getItem('email') === feedback.creator.email ? <ButtonToolbar>
                    {feedback.isPublished === true ?
                        <Button className="btn btn-primary btn-sm defaultBtn" disabled={true}>
                            <FontAwesomeIcon icon={faEdit} size="lg" style={{paddingRight: '4px'}}/>{strings.edit}
                        </Button>

                        :
                        <CrudModal entity={feedback}
                                   icon="edit"
                                   btnClass="btn-primary btn-sm defaultBtn"
                                   title={strings.edit}
                                   showText={true}
                                   onSubmit={this.onEdit}
                                   body={EditProactiveTransparency}/>}

                    {feedback.isPublished === true ?
                        <button disabled={true} className="btn btn-sm ml-1 defaultBtn"
                                style={{"backgroundColor": "#f0ad4e "}}><FontAwesomeIcon icon={faPalette} size="lg"
                                                                                         style={{paddingRight: '4px'}}/>{strings.questions}
                        </button>
                        : <Link to={"/designTransparency/" + feedback.id} className="btn btn-sm ml-1 defaultBtn"
                                style={{"backgroundColor": "#f0ad4e "}}><FontAwesomeIcon icon={faPalette} size="lg"
                                                                                         style={{paddingRight: '4px'}}/>{strings.questions}
                        </Link>}
                    <Link to={"/designTransparency/" + feedback.id}
                          className="btn btn-success btn-sm ml-1 defaultBtn"><FontAwesomeIcon icon={faShare} size="lg"
                                                                                              style={{paddingRight: '4px'}}/>{strings.publish}
                    </Link>
                    {/*<Link to={"/analyse/" + feedback.id}*/}
                    {/*      className="btn btn-dark btn-sm ml-1 defaultBtn"><FontAwesomeIcon icon={faChartBar} size="lg"*/}
                    {/*                                                                       style={{paddingRight: '4px'}}/>{strings.analyse}*/}
                    {/*</Link>*/}
                    <a href={LOCALHOST + "rest/feedback/export/" + feedback.id}
                       className="btn btn-dark btn-sm ml-1 defaultBtn"
                       target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faDownload} size="lg"
                                         style={{paddingRight: '4px'}}
                        />
                        {strings.analyse}
                    </a>
                </ButtonToolbar> : null}

            </td>
        </tr>);
    }


    renderFeedbackItemAdmin = feedback => {
        return (<tr key={feedback.id} style={{border: "1px solid lightgray"}}>
            <td className="tableData">{feedback.creator.firstName + " " + feedback.creator.lastName}</td>
            <td className="tableData">{feedback.name}</td>
            <td className="tableData">
                <div style={{wordWrap: "break-word"}}>{feedback.description}</div>
            </td>
            <td className="tableData">{feedback.selectedYear}</td>
            <td className="tableData">{moment(feedback.dueDate).local().format('DD.MM.YYYY')}</td>
            <td className="tableData">
                <a className="btn defaultBtnPreview" href={`/proactiveFinished/${feedback.id}`}>
                    <FontAwesomeIcon icon={faSearch} size="lg"
                                     style={{paddingRight: '4px'}}/>{strings.preview}</a>
            </td>
            <td className="tableData" align="center">
                <ButtonToolbar style={{display: 'block'}}>
                    {this.state.userEmail === feedback.creator.email ?
                        <CrudModal entity={feedback}
                                   icon="edit"
                                   btnClass="btn-primary btn-sm defaultBtnEdit"
                                   title={strings.edit}
                                   showText={true}
                                   onSubmit={this.onEdit}
                                   body={EditProactiveTransparency}/>
                        : null}

                    {this.state.userEmail === feedback.creator.email ?
                        <DeleteModal btnClass={"ml-1 defaultBtn"}
                                     showText={true}
                                     title={strings.remove}
                                     prompt={strings.removeProactivity}
                                     doDelete={() => this.deleteFeedback(feedback.id)}/>
                        : null}

                    {this.state.userEmail === feedback.creator.email ?
                        <Link to={"/designTransparency/" + feedback.id}
                              className="btn btn-sm ml-1 btn-primary defaultBtn"
                              style={{"backgroundColor": "#f0ad4e "}}><FontAwesomeIcon icon={faPalette} size="lg"
                                                                                       style={{paddingRight: '4px'}}/>{strings.questions}
                        </Link>
                        : null}

                    {this.state.userEmail === feedback.creator.email ?
                        <Link to={"/publishProactivity/" + feedback.id}
                              className="btn btn-sm ml-1 defaultBtn"
                              style={{background: 'rgb(64 117 140)', color: 'white'}}>
                            <FontAwesomeIcon icon={faShare}
                                             size="lg"
                                             style={{paddingRight: '4px'}}
                            />
                            {strings.publish}
                        </Link>
                        : null}

                    {/*<Link to={"/analyse/" + feedback.id}*/}
                    {/*      className="btn btn-dark btn-sm ml-1 defaultBtn"><FontAwesomeIcon icon={faChartBar} size="lg"*/}
                    {/*                                                                       style={{paddingRight: '4px'}}/>{strings.analyse}*/}
                    {/*</Link>*/}

                    <a href={LOCALHOST + "rest/feedback/export/" + feedback.id}
                       className="btn btn-dark btn-sm ml-1 defaultBtn"
                       target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faDownload} size="lg"
                                         style={{paddingRight: '4px'}}
                        />
                        {strings.analyse}
                    </a>
                </ButtonToolbar>
            </td>
        </tr>);
    }

}

export default ProactiveTransparencyCrudTable;
