import React, {Component} from 'react';
import CrudModal from "../Crud/CrudModal";
import './css/ResponsiblePerson.css';
import {strings} from "../../Localization/Localization";
import DeleteModal from "../Crud/DeleteModal";
import InstitutionalUserEdit from "../User/InstitutionalUserEdit";
import InstitutionRepository from "../../repository/InstitutionRepository";
import {toast} from "react-toastify";
import UserDisabledInstitutionAdd from "../User/UserDisabledInstitutionAdd";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";


class InstitutionResponsiblePersons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            institution: {},
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        let id = this.props.match.params.id;
        InstitutionRepository.getListOfUsersForInstitution(id).then(res => {
            this.setState({
                users: res.data,
            })
        })

        InstitutionRepository.getInstitutionById(id).then(res => {
            this.setState({
                institution: res.data
            })
        })
    }

    removeUserFromList = (id) => {
        return InstitutionRepository.removeUserFromInstitution(id).then(res => {
            toast.success(strings.userSuccessfullyDeleted);
            this.fetchData();
        }).catch(err => {
            console.log(err);
        })
    }

    onEdit = (entity) => {
        return InstitutionRepository.editResponsiblePersonForInstitution(entity).then(res => {
            toast.success(strings.editModal);
            this.fetchData();
            setTimeout(() => {
                window.location = '/responsiblePersons/' + this.state.institution.id;
            }, 5000)
        }).catch(err => {
            console.log(err);
        })
    }

    sortPersons = () => {
        this.state.users.sort((a, b) => (a.responsiblePersonVersion > b.responsiblePersonVersion) ? 1 : -1)
    }

    onAdd = (entity) => {
        return InstitutionRepository.createUserForPredefinedInstitution(this.state.institution.id, entity).then(res => {
            toast.success(strings.addModal);
            this.fetchData();
            setTimeout(() => {
                window.location = '/responsiblePersons/' + this.state.institution.id;
            }, 5000)
        }).catch(err => {
            console.log(err);
        })
    }

    render() {
        {
            this.sortPersons();
        }

        return (
            <div>

                <div className="row mt-3">
                    <div className="col-6">
                        <h2 className="mt-3 ml-3" style={{textAlign: "left", color: "#1C4857"}}>
                            {strings.responsiblePersonInstitution}
                            {localStorage.getItem("activeLanguage") === "mk" ? this.state.institution.nameMk : this.state.institution.nameAl}
                        </h2>
                    </div>
                    <div className="col-6 mt-1 justify-content-end text-right">
                        <Link to={'/users/inactive/' + this.state.institution.id}
                              className="btn btn-dark btn-sm mr-2 mt-3 defaultBtn" style={{maxWidth: '200px'}}>
                            <i className="fa fa-history mr-2"/>{strings.inactiveUsersInstitution}
                        </Link>

                        <CrudModal
                            institution={this.state.institution}
                            entity={{}}
                            icon="add"
                            style={{maxWidth: '200px'}}
                            btnClass="btn-primary mt-3 btn-sm defaultBtnAddChanged"
                            title={strings.addResponsiblePerson}
                            showText={true}
                            onSubmit={this.onAdd}
                            body={UserDisabledInstitutionAdd}
                        />
                    </div>
                </div>


                <table className="table-hover newTable mt-4" style={{width: "100%"}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '25%'}}>{strings.firstName}</th>
                    <th className="tableHeading" style={{width: '25%'}}>{strings.lastName}</th>
                    <th className="tableHeading" style={{width: '20%'}}>{strings.email}</th>
                    <th className="tableHeading" style={{width: '10%'}}>{strings.versonForPerson}</th>
                    <th className="tableHeading " style={{width: '10%'}}>{strings.actions}</th>
                    <th className="tableHeading lastHeading" style={{width: '10%'}}>{strings.delete}</th>
                    </thead>
                    <tbody>
                    {this.state.users.map(this.renderData)}
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                    </tbody>
                </table>

                <div className="row">
                    <div className="col-11"/>
                    <div className="col-1">
                        <Link to={'/institutions'}
                              className="btn btn-dark mt-5 defaultBtn">
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-1"/>{strings.goBack}</Link>
                    </div>
                </div>
            </div>
        );
    }


    renderData = (user) => {
        return (
            <tr key={user.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">
                    {localStorage.getItem('activeLanguage') === 'mk'
                        ? user.firstName
                        : user.firstNameAl
                    }
                </td>
                <td className="tableData">
                    {localStorage.getItem('activeLanguage') === 'mk'
                        ? user.lastName
                        : user.lastNameAl
                    }

                </td>
                <td className="tableData">
                    {user.email}
                </td>
                <td className="tableData">
                    {user.responsiblePersonVersion}
                </td>
                <td className="tableData">
                    <CrudModal entity={user}
                               icon="edit"
                               btnClass="btn-success btn-sm defaultBtnEdit"
                               title={strings.edit}
                               showText={true}
                               onSubmit={this.onEdit}
                               body={InstitutionalUserEdit}
                    />
                </td>
                <td className="tableData">
                    <DeleteModal btnClass={"ml-1 defaultBtn"}
                                 prompt={strings.confirmRemoveUserInstitution}
                                 showText={true}
                                 doDelete={() => this.removeUserFromList(user.id)}
                    />
                </td>
            </tr>
        )
    }
}

export default InstitutionResponsiblePersons;
