import React, {useEffect, useState} from "react";
import InstitutionRepository from "../../repository/InstitutionRepository";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {strings} from "../../Localization/Localization";
import Select from "react-select";

const UserEdit = (props) => {
    const [institutions, setInstitutions] = useState([]);
    const [listInstitutions, setListInstitutions] = useState([]);

    strings.setLanguage(localStorage.getItem("activeLanguage"));

    const getInstitutions = async () => {
        InstitutionRepository.allActiveInstitutions().then(res => {
            setInstitutions(res.data)
            res.data.map(item => {
                listInstitutions.push({value: item.id, label: item.nameMk, name: "institution_id"})
            })
        }).catch(err => toast.error(strings.failedToLoadData));
    }

    useEffect(() => {
        getInstitutions();
    }, []);

    const onChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value);
    };
    // const onActiveChangeHandler = (e) => {
    //     props.onChange(e.target.name, e.target.checked);
    // };
    const onRoleChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value);
    };

    const onInfoChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value);
    };

    const onSelectedInstitutionChangeHandler = (institution) => {
        if (institution !== null) {
            props.onChange(institution.name, institution.value)
        } else {
            props.onChange("institution_id", null)
        }
    }


    let rolefront;
    let role = localStorage.getItem('role');
    role === "ROLE_ADMIN" ? rolefront = <div className="col-12">
        <label className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.role}</label>

        <select
            name={"role"}
            className="form-control custom-select"
            onChange={(e) => onRoleChangeHandler(e)}
            value={props.entity.role !== undefined ? props.entity.role : ""}
        >
            {/*<option value="">{strings.rolePlaceholder}</option>*/}
            <option value="ROLE_ADMIN">{strings.admin}</option>
            <option value="ROLE_INSTITUTIONAL_MODERATOR">{strings.responsiblePerson}</option>
            {/*<option value="ROLE_EVALUATOR">{strings.evaluator}</option>*/}
        </select>
    </div> : rolefront = <div></div>;

    let additionalRoleInfo;

    role === "ROLE_ADMIN" ? additionalRoleInfo = <div className="col-12">
        <label
            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.additionalRole}</label>

        <select
            name={"additionalRoleInfo"}
            className="form-control custom-select"
            onChange={(e) => onInfoChangeHandler(e)}
            value={props.entity.additionalRoleInfo !== undefined ? props.entity.additionalRoleInfo : ""}
        >
            {/*<option value="">{strings.chooseStr}</option>*/}
            <option value="SIMPLE_ROLE">{strings.simpleRole}</option>
            <option value="QUESTIONS_ROLE">{strings.questionsRole}</option>
        </select>
    </div> : additionalRoleInfo = <div></div>;

    return (
        <div className="col-12">
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.firstName}</label>

                <input

                    defaultValue={props.entity.firstName}
                    name={"firstName"}
                    type={"text"}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                />
            </div>
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.lastName}</label>

                <input
                    name={"lastName"}
                    defaultValue={props.entity.lastName}
                    type={"text"}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"

                />
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-9">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.phone}</label>

                        <input
                            name={"phone"}
                            defaultValue={props.entity.phone}
                            type={"text"}
                            onChange={(e) => onChangeHandler(e)}
                            className="form-control"

                        />
                    </div>
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                        <input
                            name={"locales"}
                            defaultValue={props.entity.locales}
                            type={"text"}
                            onChange={(e) => onChangeHandler(e)}
                            className="form-control"

                        />
                    </div>
                </div>
            </div>
            <br/>

            <div className="col-12">
                <div className="row">
                    <div className="col-9">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativePhone}</label>

                        <input
                            name={"alternativePhone"}
                            defaultValue={props.entity.alternativePhone}
                            type={"text"}
                            onChange={(e) => onChangeHandler(e)}
                            className="form-control"

                        />
                    </div>
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                        <input
                            name={"alternativeLocales"}
                            defaultValue={props.entity.alternativeLocales}
                            type={"text"}
                            onChange={(e) => onChangeHandler(e)}
                            className="form-control"

                        />
                    </div>
                </div>
            </div>
            <br/>

            <div className="col-12">
                <div className="row">
                    <div className="col-9">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeSecondPhone}</label>

                        <input
                            name={"alternativeSecondPhone"}
                            defaultValue={props.entity.alternativeSecondPhone}
                            type={"text"}
                            onChange={(e) => onChangeHandler(e)}
                            className="form-control"

                        />
                    </div>
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                        <input
                            name={"alternativeSecondLocales"}
                            defaultValue={props.entity.alternativeSecondLocales}
                            type={"text"}
                            onChange={(e) => onChangeHandler(e)}
                            className="form-control"

                        />
                    </div>
                </div>
            </div>
            <br/>

            <div className="col-12">
                <div className="row">
                    <div className="col-9">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeThirdPhone}</label>

                        <input
                            name={"alternativeThirdPhone"}
                            defaultValue={props.entity.alternativeThirdPhone}
                            type={"text"}
                            onChange={(e) => onChangeHandler(e)}
                            className="form-control"

                        />
                    </div>
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                        <input
                            name={"alternativeThirdLocales"}
                            defaultValue={props.entity.alternativeThirdLocales}
                            type={"text"}
                            onChange={(e) => onChangeHandler(e)}
                            className="form-control"

                        />
                    </div>
                </div>
            </div>
            <br/>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.email}</label>

                <input
                    name={"email"}
                    defaultValue={props.entity.email}
                    type={"email"}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"

                />
            </div>
            <br/>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                <input
                    name={"alternativeEmail"}
                    defaultValue={props.entity.alternativeEmail}
                    type={"email"}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"

                />
            </div>
            <br/>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                <input
                    name={"alternativeSecondEmail"}
                    defaultValue={props.entity.alternativeSecondEmail}
                    type={"email"}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"

                />
            </div>

            <br/>
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                <input
                    name={"alternativeThirdEmail"}
                    defaultValue={props.entity.alternativeThirdEmail}
                    type={"email"}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"

                />
            </div>
            <br/>

            {rolefront}
            <br/>

            {role === "ROLE_ADMIN" ?
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.institution}</label>
                    <Select
                        placeholder={strings.searchForInstitution}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        options={listInstitutions}
                        onChange={onSelectedInstitutionChangeHandler}
                        name={"institution_id"}
                        defaultValue={props.entity.institution !== null && props.entity.institution !== undefined ? {
                            value: props.entity.institution.id,
                            label: props.entity.institution.nameMk,
                            name: "institution"
                        } : ""}

                    />
                </div> : <div/>
            }
            <br/>
            {/*<div className="col">*/}
            {/*    <label*/}
            {/*        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.active}</label>*/}
            {/*    {props.entity.active !== null && props.entity.active !== undefined && props.entity.active ?*/}
            {/*        <input*/}
            {/*            name={"active"}*/}
            {/*            type={"checkbox"}*/}
            {/*            checked={true}*/}
            {/*            onChange={(e) => onActiveChangeHandler(e)}*/}
            {/*            className="form-control"*/}
            {/*        /> :*/}
            {/*        <input*/}
            {/*            name={"active"}*/}
            {/*            type={"checkbox"}*/}
            {/*            onChange={(e) => onActiveChangeHandler(e)}*/}
            {/*            className="form-control"*/}
            {/*        />}*/}
            {/*</div>*/}

            {additionalRoleInfo}
        </div>
    );
};
export default UserEdit;
