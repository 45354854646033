import React from "react";
import {strings} from "../../Localization/Localization";
import Button from "react-bootstrap/Button";
import "./Tutorial.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

export default function TutorialVideo() {

    var FileSaver = require('file-saver')

    return (
        <div className="container-fluid" style={{height: "100%"}}>

            <Button onClick={
                () => fetch(`${process.env.REACT_APP_HOST_ENV}/rest/attachment/tutorial`,
                    {
                        origin: 'same-origin',
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token')
                        }
                    }
                ).then(res => res.blob())
                    .then(file => FileSaver.saveAs(file, 'Корисничко упатство - Е-портал за Иматели.pdf'))
            }
                    className="tutorialBtn">
                <FontAwesomeIcon icon={faFilePdf}/>
                <span style={{marginLeft: '5px'}}>Корисничко упатство - Е-портал за Иматели</span>
            </Button>

            <h2 style={{textAlign: "left", color: "#1C4857"}} className="mt-3">
                {strings.tutorial}
            </h2>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Yc752OnufYk" title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
        </div>
    )

}