import React, {Component} from "react";
import InstitutionRepository from "../../repository/InstitutionRepository";
import {strings} from "../../Localization/Localization";
import InstitutionEdit from "./InstitutionEdit";
import CrudModal from "../Crud/CrudModal";
import CrudService from "../../repository/service/CrudService";
import {toast} from "react-toastify";
import './css/InstitutionTable.css';
import ReactPaginate from "react-paginate";
import InstitutionAdd from "./InstitutionAdd";
import DeleteModal from "../Crud/DeleteModal";
import {Button, FormControl, InputGroup} from "react-bootstrap";
import {Link, NavLink} from "react-router-dom";
import {faLink, faDownload} from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class InstitutionTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            institutions: [],
            keyword: "",
            pageCount: 1
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    handlePageClick = data => {
        this.fetchData(data.selected);
    };
    fetchData = (selectedPage = 0) => {
        InstitutionRepository.getInstitutionsWithModerator(this.state.keyword, selectedPage).then(res => {
            this.setState({
                institutions: res.data.content,
                pageCount: res.data.totalPages
            })
        })
    }
    handleSearch = (e) => {
        this.setState({keyword: e.target.value}
            , () => {
                this.fetchData();
            })
    }


    deleteInstitution = (id) => {
        return InstitutionRepository.setInactiveInstitution(id).then(() => {
            toast.success(strings.institutionDeleted)
            this.fetchData();
        }).catch(() => {
            toast.error(strings.institutionNotDeleted)
        });
    };

    onAdd = (entity) => {
        return InstitutionRepository.addNewInstitution(entity).then(() => {
            toast.success(strings.successfullyAdInstitution)
            this.fetchData(this.state.keyword);
        }).catch((err) => {
            if (err.message === "Request failed with status code 406") {
                alert(strings.enterValidNumber)
            }
            toast.error(strings.failAddInstitution);
        })
    }


    render() {
        return (
            <div>
                <h2 style={{textAlign: "left", color: "#1C4857"}}
                    className="mt-4 mb-3">{strings.institutions}</h2>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">

                        <InputGroup className="mb-3">
                            <FormControl
                                value={this.state.searchValue}
                                disabled={false}
                                placeholder={strings.search}
                                onChange={this.handleSearch}
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2">
                                    <Button className={"btn btn-sm"} onClick={this.fetchDataBySearch}
                                            variant="outline-secondary"
                                            disabled={true}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>

                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 align-items-end text-right">
                    <Button
                            onClick={() => {
                                this.setState({spinner: true});
                                fetch(`${process.env.REACT_APP_HOST_ENV}/rest/institution/export`,
                                    {
                                        origin: 'same-origin',
                                        headers: {
                                            Authorization: "Bearer " + localStorage.getItem('auth_token')
                                        }
                                    }
                                ).then(res => res.blob())
                                    .then(file => FileSaver.saveAs(file, `Институции.xlsx`)).then(() => this.setState({spinner: false}))
                            }}
                            className="defaultBtn btn btn-dark mt-4 mr-3">
                            <FontAwesomeIcon icon={faDownload} size="lg"
                                             style={{paddingRight: '4px'}}
                            />
                            <span>{strings.institutions}</span>
                        </Button>
                    <Button
                        onClick={() => fetch(`${process.env.REACT_APP_HOST_ENV}/rest/user/export`,
                        {
                            origin: 'same-origin',
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem('auth_token')
                            }
                        }
                        ).then(res => res.blob())
                            .then(file => FileSaver.saveAs(file, `Корисници.xlsx`))}
                        className="defaultBtn btn btn-success mt-4 mr-1">
                        <FontAwesomeIcon icon={faDownload} size="lg"
                                         style={{paddingRight: '4px'}}
                        />
                        <span>{strings.users}</span>
                    </Button>

                        <NavLink to="/EditedInstitutions">
                            <button type="button"
                                    className="btn btn-dark defaultBtn ml-1 mr-2 mt-4">{strings.editedInstitutions}</button>
                        </NavLink>
                        <CrudModal
                            entity={{}}
                            icon="add"
                            btnClass="btn-primary defaultBtnAdd mt-4"
                            title={strings.add}
                            showText={true}
                            onSubmit={this.onAdd}
                            body={InstitutionAdd}
                        />
                    </div>
                </div>

                <table className="table-hover newTable mt-2" style={{width: "100%"}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '29%'}}> {strings.name} </th>
                    <th className="tableHeading" style={{width: '22%'}}> {strings.address} </th>
                    <th className="tableHeading" style={{width: '10%'}}> {strings.phone} </th>
                    <th className="tableHeading" style={{width: '12%'}}> {strings.listOfInformations} </th>
                    {/*<th className=" tableHeading"> {strings.direktorFirstName} </th>*/}
                    {/*<th className=" tableHeading"> {strings.direktorLastName} </th>*/}
                    <th className="tableHeading text-center" style={{width: '11%'}}> {strings.responsiblePerson} </th>
                    <th className="tableHeading text-center" style={{width: '8%'}}> {strings.actions} </th>
                    <th className="tableHeading lastHeading text-center" style={{width: '8%'}}> {strings.delete} </th>
                    </thead>
                    <tbody>
                    {this.state.institutions.map(this.renderData)}
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                    </tbody>
                </table>
                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        )
    }

    renderData = (institution) => {
        let address = null;
        let name = null
        let addressMk = institution.addressMk !== null ? institution.addressMk : "";
        let addressAl = institution.addressAl !== null ? institution.addressAl : "";
        let addressEn = institution.addressEn !== null ? institution.addressEn : "";

        if (localStorage.getItem('activeLanguage') === 'mk') {
            address = addressMk;
            name = institution.nameMk;
        }
        else if (localStorage.getItem('activeLanguage') === 'al') {
            address = addressAl;
            name = institution.nameAl;
        }
        else {
            address = addressEn;
            name = institution.nameEn;
        }
        return (

            <tr key={institution.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData"> {name !== null && name !== "" ? name : strings.notEntered} </td>
                <td className="tableData">  {address !== null && address !== "" ? address : strings.notEntered}</td>
                <td className="tableData"> {institution.institutionPhone !== null ? institution.institutionPhone : strings.notEntered}
                    {institution.institutionLocales ? " лок. " + institution.institutionLocales : ""}
                </td>
                <td className="tableData">
                    {institution.webSite !== null ? institution.webSite : ""}
                    <br/>
                    {institution.noticeBoard ? "*" + strings.infoNoticeBoard : ""}
                    <br/>
                    {(institution.infoCenter !== null && institution.infoCenter !== "") ? "*" + institution.infoCenter : ""}
                </td>
                {/*<td className="tableData"> {institution.direktorFirstName !== null ? institution.direktorFirstName : strings.notEntered} </td>*/}
                {/*<td className="tableData"> {institution.direktorLastName !== null ? institution.direktorLastName : strings.notEntered}</td>*/}
                <td className="tableData">
                    <Link to={{
                        pathname: `/responsiblePersons/${institution.id}`,
                        state: {
                            item: institution
                        }
                    }}
                    >
                        <button type="button"
                                className="btn btn-sm defaultBtn" style={{background: '#BCDFEF'}}>
                            <FontAwesomeIcon icon={faLink} className="mr-1"/>
                            {strings.responsiblePerson}
                        </button>
                    </Link>
                </td>
                <td className="tableData">
                    <CrudModal entity={institution}
                               icon="edit"
                               btnClass="btn-success btn-sm defaultBtnEdit ml-3"
                               title={strings.edit}
                               showText={true}
                               onSubmit={this.onEdit}
                               body={InstitutionEdit}
                    />
                </td>
                <td className="tableData lastData">
                    <DeleteModal btnClass={"ml-1 defaultBtn"}
                                 prompt={strings.instDeleteQuestion}
                                 showText={true}
                                 doDelete={() => this.deleteInstitution(institution.id)}
                    />
                </td>
            </tr>


        )
    }
    onEdit = (entity) => {
        return CrudService.edit("/rest/institution", entity).then(async () => {
            toast.success(strings.successfullyEditInstitution);
            await this.sleep(4000);
            window.location.reload();
            this.fetchData();
        }).catch((err) => {
            if (err.message === "Request failed with status code 406") {
                alert(strings.enterValidNumber)
            }
            toast.error(strings.failEditInstitution)
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
}

export default InstitutionTable;
