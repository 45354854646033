import axios from '../../axios/app'

const AuthenticationService = {
    loginUser: (request) => {
        return axios.post('/rest/login', request);
    },

    resetPassword: (email) => {
        return axios.post(`/rest/user/forgotPassword/password?email=${email}`)
    },

    setNewPassword: (token, password) => {
        return axios.post(`/rest/user/resetPassword/password?token=${token}`, password, {
            headers: {
                'Content-Type': 'application/json',
                'password': password
            }
        })
    },

    getNewPasswordPage: (token) => {
        return axios.get(`/rest/user/resetPassword/token/${token}`)
    },
};

export default AuthenticationService;