import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import '../../assets/css/theme/base.css'
import {strings} from "../../Localization/Localization";
import './Nav.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import agencySidebarIcon from "../../assets/images/ikonaAgencija.png"

class Nav extends Component {


    componentDidMount() {
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
    }

    state = {
        userEmail: localStorage.getItem('email'),
        userRole: localStorage.getItem('role'),
        menuShown: false,
        active: 'Dashboard'
    };


    onSetLanguageToMacedonian = () => {
        strings.setLanguage('mk');

        localStorage.setItem("activeLanguage", "mk")

        this.setState({});
        window.location.reload();
    };

    onSetLanguageToAlbanian = () => {
        strings.setLanguage('al');

        localStorage.setItem("activeLanguage", "al")

        this.setState({});
        window.location.reload();
    };

    onSetLanguageToEnglish = () => {
        strings.setLanguage('en');

        localStorage.setItem("activeLanguage", "en")

        this.setState({});
        window.location.reload();
    };

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar navbar-dark" style={{background: '#2e5b6e'}}>
                    <button type="button" className="navbar-toggler" data-toggle="collapse"
                            data-target="#navbarCollapse1">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse1">

                        <div className="navbar-nav mr-auto" style={{verticalAlign: "middle"}}>
                            <a href="https://aspi.mk/" target="_blank" rel="noopener noreferrer"
                               style={{height: "50%", marginLeft: "20px"}}>
                                <img src={agencySidebarIcon} className="sidebarLogoImg" alt="agencySidebarIcon"
                                     style={{width: "45px", height: "50%", marginTop: "0"}}
                                />
                            </a>

                            <div className="ml-3 mt-2">
                                <p style={{
                                    marginTop: "5px", fontSize: "130%",
                                    letterSpacing: "1.5px", color: "white",
                                    fontWeight: "530"
                                }}>
                                    {strings.aspi}
                                </p>

                            </div>
                        </div>

                        <div className="navbar-nav">

                            <button type="button" className="btn btn-link p-0 text-white"
                                    onClick={this.onSetLanguageToMacedonian}>MK|
                            </button>
                            <button type="button" className="btn btn-link p-0 text-white"
                                    onClick={this.onSetLanguageToAlbanian}>AL|
                            </button>
                            <button type="button" className="btn btn-link p-0 text-white"
                                    onClick={this.onSetLanguageToEnglish}>EN
                            </button>

                            <div className="navbar-nav">
                            <span>

                                <div className='dropdown ml-2'>
                                    <button className="btn dropdown-toggle" type="button" style={{color: 'white'}}
                                            data-toggle="dropdown">
                                        <FontAwesomeIcon icon={faUser} style={{color: 'white'}} size="lg"
                                                         className="mr-2"/>
                                        {this.state.userEmail.substring(0, this.state.userEmail.indexOf("@"))}
                                    </button>

                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li className='dropdown-header'
                                            style={{fontFamily: 'sans-serif', color: 'black'}}
                                        >{strings.logged}: {localStorage.getItem('email')}</li>


                                        <br/>

                                        <li style={{marginLeft:"15px"}}><FontAwesomeIcon icon={faLock} size="lg"/><Link to="/changePassword" style={{marginLeft:"0px",color:"black"}} className='profile-dropdown-link text-center'> {strings.changePassword}</Link></li>
                                        <hr/>
                                        <li style={{marginLeft:"5px"}}><a href="/logout" style={{color:"black"}} className='profile-dropdown-link ml-0 pl-0'> <img className="img-icon ml-0"
                                                                                                                                           src={require(`../../assets/images/dashboard_icons/icons8-sign-out-50.png`)}
                                                                                                                                           alt=""/>{strings.logout}</a></li>
                                    </ul>
                                </div>
                             </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>


        );
    }
}

export default Nav;