import React, {useEffect, useState} from "react";
import {strings} from "../../Localization/Localization";
import CriteriaRepository from "../../repository/CriteriaRepository";
import {toast} from "react-toastify";
import Select from "react-select";
import CriteriaTypeRepository from "../../repository/CriteriaTypeRepository";

const CriteriaEdit = (props) => {

    const [criterias, setCriterias] = useState([]);
    const [listCriterias, setListCriterias] = useState([]);
    const [criteriaTypes, setCriteriaTypes] = useState([]);
    const [listCriteriaTypes, setListCriteriaTypes] = useState([]);

    strings.setLanguage(localStorage.getItem("activeLanguage"));

    const getCriterias = async => {
        CriteriaRepository.getAllCriteria().then(res => {
            setCriterias(res.data);
            res.data.map(item => {
                listCriterias.push({value: item.id, label: item.name, name: "parentCriteria"})
            })
        }).catch(err => {
            toast.error(strings.failedToLoadData)
        })
    }

    const getCriteriaTypes = async => {
        CriteriaTypeRepository.findAllCriteriaTypes().then(res => {
            setCriteriaTypes(res.data);
            res.data.map(item => {
                listCriteriaTypes.push({value: item.id, label: item.name, name: "criteriaType"})
            })
        }).catch(err => {
            toast.error(strings.failedToLoadData)
        })
    }

    useEffect(() => {
        getCriterias();
        getCriteriaTypes();
    }, [])

    const onChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value);
    };

    const onSelectedCriteriaChangeHandler = (criteria) => {
        if (criteria !== null) {
            props.onChange(criteria.name, criteria.value)
        } else {
            props.onChange("parentCriteria", null)
        }
    }

    const onSelectedCriteriaTypeChangeHandler = (criteriaType) => {
        if (criteriaType !== null) {
            props.onChange(criteriaType.name, criteriaType.value)
        } else {
            props.onChange("criteriaType", null)
        }
    }

    return (
        <div>
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.name}</label>
                <textarea
                    defaultValue={props.entity.name}
                    name={"name"}
                    rows={3}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                />
            </div>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.parentToCriteria}</label>
                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={listCriterias}
                    onChange={onSelectedCriteriaChangeHandler}
                    name={"parentCriteria"}
                    defaultValue={props.entity.parentCriteria !== null && props.entity.parentCriteria !== undefined ? {
                        value: props.entity.parentCriteria.id,
                        label: props.entity.parentCriteria.name,
                        name: "parentCriteria"
                    } : ""}

                />
            </div>

            <br/>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.typesAnswers}</label>
                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={listCriteriaTypes}
                    onChange={onSelectedCriteriaTypeChangeHandler}
                    name={"criteriaType"}
                    defaultValue={props.entity.criteriaType !== null && props.entity.criteriaType !== undefined ? {
                        value: props.entity.criteriaType.id,
                        label: props.entity.criteriaType.name,
                        name: "criteriaType"
                    } : ""}

                />
            </div>
        </div>
    )
}
export default CriteriaEdit;
