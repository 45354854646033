import React, {Component} from "react";
import UserRepository from "../../repository/UserRepository";
import CrudModal from "../Crud/CrudModal";
import {strings} from "../../Localization/Localization";
import UserEdit from "./UserEdit";
import ReactPaginate from "react-paginate";
import CrudService from "../../repository/service/CrudService";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import UserAdd from "./UserAdd";
import "./Css/UsersTable.css"
import Deactivation from "./Deactivation";
import {Button, FormControl, InputGroup} from "react-bootstrap";

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            instName: "",
            users: [],
            pageCount: 1,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    handleFirstAndLastName = (e) => {
        let firstAndLastName = e.target.value.split(" ");
        let firstName = firstAndLastName[0];
        let lastName = firstAndLastName[1];
        if (lastName === undefined) {
            lastName = firstName;
        }

        this.setState({
            firstName: firstName,
            lastName: lastName
        }, () => {
            this.fetchData()
        })
    }
    handleInstitutionName = (e) => {
        this.setState({
            instName: e.target.value
        }, () => {
            this.fetchData();
        })
    }
    handlePageClick = data => {
        this.fetchData(data.selected);
    };
    fetchData = (selectedPage = 0) => {
        UserRepository.getAllUsers(this.state.firstName, this.state.lastName, this.state.instName, selectedPage).then(r => {
            this.setState({
                users: r.data.content,
                pageCount: r.data.totalPages
            })
        })
    }

    render() {
        return (
            <div className="container">
                <h2 style={{textAlign: "left", color: "#1C4857"}}
                    className="mt-4 mb-3">{strings.users}</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4">

                        <InputGroup className="mb-3">
                            <FormControl
                                value={this.state.searchValue}
                                disabled={false}
                                placeholder={strings.firstlastname}
                                onChange={this.handleFirstAndLastName}
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2">
                                    <Button className={"btn btn-sm"} onClick={this.fetchDataBySearch}
                                            variant="outline-secondary"
                                            disabled={true}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">

                        <InputGroup className="mb-3">
                            <FormControl
                                value={this.state.searchValue}
                                disabled={false}
                                placeholder={strings.institution}
                                onChange={this.handleInstitutionName}
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2">
                                    <Button className={"btn btn-sm"} onClick={this.fetchDataBySearch}
                                            variant="outline-secondary"
                                            disabled={true}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <CrudModal
                            entity={{}}
                            icon="add"
                            btnClass="btn-primary btn-sm mt-5"
                            title={strings.add}
                            showText={true}
                            onSubmit={this.onAdd}
                            body={UserAdd}
                        />
                        <Link to="/users/inactive" className="btn btn-dark btn-sm ml-2 mt-5"><i
                            className="fa fa-history"/>{strings.inactiveUsers}</Link>
                    </div>
                </div>
                <div className="row">
                    <table className="table-striped table-bordered mt-2" style={{width: "100%"}}>
                        <thead>
                        <th>{strings.firstName}</th>
                        <th>{strings.lastName}</th>
                        <th>{strings.email}</th>
                        <th>{strings.phone}</th>
                        <th>{strings.institution}</th>
                        {/*<th>{strings.dateCreated}</th>*/}
                        {/*<th>{strings.dateUpdated}</th>*/}
                        <th>{strings.actions}</th>
                        <th>{strings.delete}</th>
                        </thead>
                        <tbody>
                        {this.state.users.map(this.renderUsers)}
                        </tbody>
                    </table>
                    < ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        )
    }

    onAdd = (entity) => {
        return CrudService.save("/rest/user", entity).then(res => {
            toast.success(strings.successfullyAddedUser);
            this.fetchData();
        }).catch(() => {
            toast.error(strings.failedToAddUser);
        })
    }

    onEdit = (entity) => {
        return CrudService.edit("/rest/user", entity).then(res => {
            toast.success(strings.successfullyEditedUser);
            this.fetchData();
        }).catch(() => {
            toast.error(strings.failedToEditUser);
        })
    }

    // deleteUser = (id) => {
    //     return UserRepository.deleteUser(id).then(() => {
    //         toast.success("Корисникот е успешно избришан")
    //         this.fetchData();
    //     }).catch(() => {
    //         toast.error("Корисникот не е избришан")
    //     });
    // };

    handleDeleteUser = (Id) => {
        return UserRepository.setInactiveUser(Id).then(() => {
            toast.success(strings.userSuccessfullyDeleted)
            this.fetchData();
        }).catch(() => {
            toast.error(strings.userNotDeleted)
        });
    }


    renderUsers = (user) => {
        let lang = localStorage.getItem('activeLanguage');
        return (
            <tr key={user.id}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.phone}
                    {user.locales ? " лок. " + user.locales : ""}
                </td>
                {lang === 'mk' ?
                    <td>{user.institution !== null ? user.institution.nameMk : strings.noInstitution} </td> :
                    <td>{user.institution !== null ? user.institution.nameAl : strings.noInstitution} </td>}
                {/*<td>{user.dateCreated}</td>*/}
                {/*<td>{user.dateUpdated}</td>*/}
                <td>
                    <CrudModal
                        entity={user}
                        icon="edit"
                        btnClass="btn-success btn-sm"
                        title={strings.edit}
                        showText={true}
                        onSubmit={this.onEdit}
                        body={UserEdit}
                    />
                </td>
                <td>
                    <Deactivation
                        handleDeleteUser={() => this.handleDeleteUser(user.id)}
                        prompt={strings.deleteQuestionForUser}
                    />
                </td>
            </tr>
        )
    }
}

export default UsersTable;
