import React, {Component} from "react";
import QuestionRepository from "../../repository/QuestionRepository.js"
import "./css/Question.css"
import {strings} from "../../Localization/Localization";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import {toast} from "react-toastify";


class EditQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.location.state.item.id,
            question: {},
        }
    }


    componentDidMount() {
        strings.setLanguage(localStorage.getItem("activeLanguage"));

        QuestionRepository.getQuestionById(this.state.id).then(res => {
            this.setState({question: res.data});
        });
    }

    EditQuestion = () => {
        QuestionRepository.updateQuestion(this.state.question).then(res => {
            toast.success(strings.succEditQuestion);
            this.props.history.push("/Question")
        }).catch(err => {
            toast.error(strings.unSucEditQuestion)
        })

    }

    onChangeHandler = (e) => {
        const target = e.target || e;
        let update = this.state.question;
        update[target.name] = target.value;
        this.setState({entity: update});
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 mt-5 centerDiv" style={{textAlign: "center", color: "#1C4857"}}><h2
                        style={{fontSize: '35px'}}>{strings.edit}</h2></div>

                    <div className="col-12 mt-4">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.questionn}</label>

                        <textarea
                            required
                            placeholder=""
                            name={"title"}
                            rows={3}
                            onChange={this.onChangeHandler}
                            className="form-control"
                            value={this.state.question.title}
                        />
                    </div>

                    <div className="col-12 mt-4 mb-4">
                        <button type="button" onClick={this.EditQuestion}
                                className="btn btn-info QuestionAdminActionButton defaultBtn">
                            <FontAwesomeIcon icon={faEdit} size="lg" style={{paddingRight: '4px'}}/>
                            {strings.save}
                        </button>
                        <NavLink to="/Question">
                            <button type="button" className="btn btn-dark mr-2 QuestionAdminActionButton defaultBtn">
                                <FontAwesomeIcon icon={faArrowLeft} size="lg" style={{paddingRight: '4px'}}/>
                                {strings.goBack}
                            </button>
                        </NavLink>
                    </div>

                </div>
            </div>

        )
    }
}


export default (EditQuestion);