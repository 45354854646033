import React from 'react'
import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {strings} from "../../Localization/Localization";

export const ExportCSV = ({csvData, fileName}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        ws.A1.v = "Институција";
        ws.B1.v = "Бодови";
        ws['!cols'] = [{width: 30}, {width: 7}];

        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};

        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
        window.location = '/ListOfReports';
    }

    return (
        <Button className="btn-success btn btn-sm mt-5 defaultBtn" variant="warning"
                onClick={(e) => exportToCSV(csvData, fileName)}>{strings.takeResults}</Button>
    )
}