import React, {useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {Col, Form, Row} from "react-bootstrap";
import DatePicker,{ registerLocale } from "react-datepicker";
import mk from "date-fns/locale/mk";
import "react-datepicker/dist/react-datepicker.css";
import {strings} from "../../Localization/Localization";

registerLocale("mk", mk);

const AddFeedback = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));
    const [startDate, setStartDate] = useState(new Date());
    const [activeLanguage, setActiveLanguage] = useState(localStorage.getItem("activeLanguage"));


    const updateDueDate = (v) => {
        setStartDate(v);
        props.handleChange({name: 'dueDate', value: v})
    };


    return (
        <Row>
            <Col>
                <Form onSubmit={props.onSubmit} className="mt-2">
                    <Form.Group controlId="name">
                        <Form.Label><span style={{color: "red", fontSize: '18px'}}
                                          className="mr-1">*</span>{strings.nameFeedback}</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            required
                            onChange={props.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label><span style={{color: "red", fontSize: '18px'}}
                                          className="mr-1">*</span>{strings.descShortFeedback}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            type="text"
                            name="description"
                            required
                            onChange={props.handleChange}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><span style={{color: "red", fontSize: '18px'}}
                                          className="mr-1">*</span>{strings.dueDate}</Form.Label>
                        <div>
                            {activeLanguage === "mk" && <DatePicker
                                locale="mk"
                                id="dueDate"
                                selected={startDate}
                                onChange={updateDueDate}
                                className="form-control"
                                dateFormat="dd MMM yyyy"
                                popperPlacement="top-start"
                            />}
                            {activeLanguage==="al" &&<DatePicker
                                id="dueDate"
                                selected={startDate}
                                onChange={updateDueDate}
                                className="form-control"
                                dateFormat="dd MMM yyyy"
                                popperPlacement="top-start"
                            />}
                        </div>
                    </Form.Group>
                </Form>
            </Col>
        </Row>
    );
};
export default AddFeedback;
