import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import {strings} from "../../Localization/Localization";
import CriteriaRepository from "../../repository/CriteriaRepository";
import Select from "react-select";

const AddPublicDocumentType = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));
    const [criterias, setCriterias] = useState([]);
    const [listCriterias, setListCriterias] = useState([]);

    const getCriterias = async => {
        CriteriaRepository.getAllCriteria().then(res => {
            setCriterias(res.data)
            res.data.map(item => {
                listCriterias.push({value: item.id, label: item.name, name: "criteria"})
            });
        })
    }

    useEffect(() => {
        getCriterias();
    }, [])

    const onSelectedCriteriaChangeHandler = (criteria) => {
        if (criteria !== null) {
            props.onChange(criteria.name, criteria.value)
        } else {
            props.onChange("criteria", null)
        }
    }


    return (
        <div className="col-12">
            <div className="col-12">
                <label className=""><span style={{color: "red", fontSize: '18px'}}
                                          className="mr-1">*</span>{strings.publicDocType}:</label>
                <form onSubmit={props.onSubmit}>

                    <div className="col-12">
                        <textarea
                            className="mt-2"
                            style={{width: "100%"}}
                            rows={3}
                            name="name"
                            required
                            onChange={props.handleChange}/>
                    </div>

                    <div className="col-12 mt-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.oneCriteria}</label>
                        <Select
                            placeholder={""}
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            options={listCriterias}
                            onChange={onSelectedCriteriaChangeHandler}
                            name={"criteria"}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
export default AddPublicDocumentType;
