import axios from '../axios/app';

const CriteriaRepository = {

    getAllCriteria: () => {
        return axios.get("/rest/criteria/allCriteria")
    },
    createCriteria: (criteria) => {
        return axios.post("/rest/criteria/createCriteria", criteria)
    },
    updateCriteria: (criteria) => {
        return axios.patch(`/rest/criteria/updateCriteria`, criteria)
    },
    findCriteria: (criteriaId) => {
        return axios.get(`/rest/criteria/${criteriaId}`)
    },
    deleteCriteria: (criteriaId) => {
        return axios.patch(`/rest/criteria/${criteriaId}`)
    },
    unDeleteCriteria: (criteriaId) => {
        return axios.patch(`/rest/criteria/undelete/${criteriaId}`)
    },
    countCriterias: () => {
        return axios.get("/rest/criteria/countCriterias")
    },
};
export default CriteriaRepository;