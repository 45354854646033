import React, {Component} from "react";
import {Button, ButtonToolbar, FormControl, InputGroup} from "react-bootstrap";
import InstitutionCategoryRepository from "../../repository/InstitutionCategoryRepository";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import EditInstitutionCategory from "./EditInstitutionCategory";
import ReactPaginate from 'react-paginate';
import CrudModal from "../Crud/CrudModal";
import AddInstitutionCategory from "./AddInstitutionCategory";
import {strings} from "../../Localization/Localization";
import './css/InstitutionCategoryCrudTable.css'
import DeleteModal from "../Crud/DeleteModal";

class InstitutionCategoriesCrudTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keyword: "",
            institutionCategories: [],
            pageCount: 1,
            numberInstitutions: 0,
            numberChild: 0,
        }

    }

    handleSearchByName = (e) => {
        this.setState({
            keyword: e.target.value
        }, () => {
            this.fetchData()
        })
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return (

            <div>
                <h2 style={{color: "#1C4857"}}
                    className="mt-4">{strings.institutionCategory}</h2>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        {/*<input className="form-control-sm mb-2 mt-5 searchInstitutionsCategoryInputStyle"*/}
                        {/*       placeholder={strings.search}*/}
                        {/*       style={{width: "80%"}}*/}
                        {/*       onChange={this.handleSearchByName}*/}
                        {/*/>*/}
                        <InputGroup className="mt-3">
                            <FormControl
                                value={this.state.searchValue}
                                disabled={false}
                                placeholder={strings.search}
                                onChange={this.handleSearchByName}
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2">
                                    <Button className={"btn btn-sm"} onClick={this.fetchDataBySearch}
                                            variant="outline-secondary"
                                            disabled={true}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>

                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 justify-content-end text-right mt-5">
                        <CrudModal entity={{}}
                                   icon="add"
                                   btnClass="btn-primary defaultBtnAdd"
                                   title={strings.add}
                                   showText={true}
                                   onSubmit={this.onAdd}
                                   body={AddInstitutionCategory}/>
                    </div>
                </div>

                <table className="table-hover newTable mt-2" style={{width: "100%"}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '25%'}}>{strings.name}</th>
                    <th className="tableHeading" style={{width: '25%'}}>{strings.parentCategory}</th>
                    <th className="tableHeading" style={{width: '10%'}}>{strings.dateCreated}</th>
                    {/*<th>{strings.dateUpdated}</th>*/}
                    <th className="tableHeading" style={{width: '10%'}}>{strings.institutionsInCategory}</th>
                    <th className="tableHeading" style={{width: '10%'}}>{strings.childCategories}</th>
                    <th className="tableHeading " style={{width: '10%'}}>{strings.actions}</th>
                    <th className="tableHeading lastHeading" style={{width: '10%'}}>{strings.delete}</th>
                    </thead>
                    <tbody>
                    {this.state.institutionCategories.map(this.renderCategory)}
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                    </tbody>
                </table>


                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        )
    }


    fetchData = (selectedPage = 0) => {
        InstitutionCategoryRepository.getAllInstitutionCategory(this.state.keyword, selectedPage).then(async res => {
            console.log("DATA ", res.data.content);
            const contentData = res.data.content;
            for (var i = 0; i < contentData.length; i++) {
                let numberInst = 0;
                let numberSubCat = 0;
                await InstitutionCategoryRepository.getInstitutionsForCategory(contentData[i].id).then(resData => {
                    numberInst = resData.data;
                });
                await InstitutionCategoryRepository.getChildCategories(contentData[i].id).then(resData => {
                    numberSubCat = resData.data;
                });
                contentData[i]["numberSubCat"] = numberSubCat;
                contentData[i]["numberInst"] = numberInst;

            }

            this.setState({
                institutionCategories: contentData,
                pageCount: res.data.totalPages
            })
        }).catch(err => {
            console.log(err)
        })
    }


    handlePageClick = data => {
        this.fetchData(data.selected);
    };


    onAdd = (entity) => {
        if (entity.nameMk !== "") {
            return InstitutionCategoryRepository.AddInstitutionCategory(entity).then(res => {
                toast.success(strings.categoryInstAdded)
                this.fetchData();
            }).catch(err => {
                toast.error(strings.error)
            });
        }
    }

    onEdit = (entity) => {
        if (entity.parentCategory === null) {
            entity["parentCategory"] = null
        } else if (entity.parentCategory.id > 0) {
            entity["parentCategory"] = entity.parentCategory.id
        } else {
            entity["parentCategory"] = entity.parentCategory
        }

        return InstitutionCategoryRepository.editInstitutionCategory(entity).then(async res => {
            toast.success(strings.categoryInstEdit)
            await this.sleep(4000);
            window.location.reload();
            this.fetchData();
        }).catch((error) => {
            toast.error(strings.error)
        });
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    deleteInstitutionCategory = (id) => {
        return InstitutionCategoryRepository.deleteInstitutionCategory(id).then(() => {
            toast.success(strings.categoryInstDelete)
            this.fetchData();
        }).catch(() => {
            toast.error(strings.categoryInstNotDelete)
        });
    };

    renderCategory = institutionCategories => {

        let lang = localStorage.getItem('activeLanguage')
        return (<tr key={institutionCategories.id} style={{border: "1px solid lightgray"}}>
            {lang === 'mk' ?
                <td className="tableData" style={{wordWrap: "break-word"}}>{institutionCategories.nameMk}</td> : ""}
            {lang === 'al' ?
                <td className="tableData" style={{wordWrap: "break-word"}}>{institutionCategories.nameAl}</td> : ""}
            {lang === 'en' ?
                <td className="tableData" style={{wordWrap: "break-word"}}>{institutionCategories.nameEn}</td> : ""}

            {lang === 'mk' ?
                <td className="tableData"
                    style={{wordWrap: "break-word"}}>{institutionCategories.parentCategory !== null ? institutionCategories.parentCategory.nameMk : strings.noParentCategory}</td> : ""}
            {lang === 'al' ?
                <td className="tableData"
                    style={{wordWrap: "break-word"}}>{institutionCategories.parentCategory !== null ? institutionCategories.parentCategory.nameAl : strings.noParentCategory}</td> : ""}
            {lang === 'en' ?
                <td className="tableData"
                    style={{wordWrap: "break-word"}}>{institutionCategories.parentCategory !== null ? institutionCategories.parentCategory.nameEn : strings.noParentCategory}</td> : ""}
            <td className="tableData">{institutionCategories.dateCreated.replaceAll("-", ".")}</td>
            {/*<td>{institutionCategories.dateUpdated}</td>*/}
            <td className="tableData ml-3">{institutionCategories.numberInst}</td>
            <td className="tableData ml-3">{institutionCategories.numberSubCat}</td>
            <td className="text-center tableData">
                <ButtonToolbar>
                    <CrudModal entity={institutionCategories}
                               icon="edit"
                               btnClass="btn-success btn-sm defaultBtnEdit"
                               title={strings.edit}
                               showText={true}
                               onSubmit={this.onEdit}
                               body={EditInstitutionCategory}
                    />
                </ButtonToolbar>
            </td>
            <td className="tableData">
                <DeleteModal btnClass={"ml-1 defaultBtn"}
                             prompt={strings.deleteCatQuestion}
                             showText={true}
                             doDelete={() => this.deleteInstitutionCategory(institutionCategories.id)}
                />
            </td>
        </tr>);
    }

}

export default InstitutionCategoriesCrudTable;
