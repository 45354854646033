import React, {Component} from 'react';
import CriteriaTypeRepository from "../../repository/CriteriaTypeRepository";
import {toast} from "react-toastify";
import {strings} from "../../Localization/Localization";
import CrudModal from "../Crud/CrudModal";
import DeleteModal from "../Crud/DeleteModal";
import CriteriaTypeOptionRepository from "../../repository/CriteriaTypeOptionRepository";
import CriteriaTypeOptionEdit from "./CriteriaTypeOptionEdit";
import CriteriaTypeOptionAdd from "./CriteriaTypeOptionAdd";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

class CriteriaTypeOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            criteriaTypeId: this.props.match.params.id,
            criteriaType: {}
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        CriteriaTypeRepository.findCriteriaTypeById(this.state.criteriaTypeId).then(res => {
            this.setState({
                criteriaType: res.data
            })
        }).catch(err => {
            toast.error(strings.failedToLoadData);
        })

        CriteriaTypeRepository.getOptionsForCriteriaType(this.state.criteriaTypeId).then(res => {
            this.setState({
                options: res.data
            })
        })
    }

    addCriteriaTypeOption = (option) => {
        return CriteriaTypeOptionRepository.createOption(this.state.criteriaTypeId, option).then(res => {
            toast.success(strings.succAdded);
            this.fetchData();
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    editCriteriaTypeOption = (option) => {
        return CriteriaTypeOptionRepository.editOption(option).then(async () => {
            toast.success(strings.succChanged);
            await this.sleep(4000);
            window.location.reload();
            this.fetchData()
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    deleteCriteriaTypeOption = (criteriaTypeOptionId) => {
        return CriteriaTypeOptionRepository.deleteOption(criteriaTypeOptionId).then(res => {
            toast.success(strings.deleteModal);
            this.fetchData();
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    render() {
        return (
            <div>
                <div className="row mt-5">
                    <div className="col-6">
                        <h2 style={{color: "#1C4857"}}>
                            {strings.addOption} : {this.state.criteriaType ? this.state.criteriaType.name : ""}
                        </h2>
                    </div>

                    <div className="col-6 text-right justify-content-end">
                        <CrudModal entity={{}}
                                   icon="add"
                                   showText={true}
                                   btnClass="btn-primary defaultBtnAdd"
                                   title={strings.add}
                                   onSubmit={this.addCriteriaTypeOption}
                                   body={CriteriaTypeOptionAdd}/>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                        <table className="table-hover newTable mt-1" style={{width: "100%"}}>
                            <thead className="tableHead">
                            <th className="tableHeading firstHeading" style={{width: '35%'}}>{strings.name}</th>
                            <th className="tableHeading" style={{width: '35%'}}>{strings.valueOption}</th>
                            <th className="tableHeading" style={{width: '15%'}}>{strings.options}</th>
                            <th className="tableHeading lastHeading" style={{width: '15%'}}>{strings.delete}</th>
                            </thead>
                            <tbody>{this.state.options.map(this.renderOptions)}</tbody>
                        </table>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12 text-right">
                        <NavLink to="/criteriaType">
                            <button type="button" className="btn btn-dark defaultBtn">
                                <FontAwesomeIcon icon={faArrowLeft} size="lg" style={{paddingRight: '4px'}}/>
                                {strings.goBack}
                            </button>
                        </NavLink>
                    </div>
                </div>

            </div>
        );
    }

    renderOptions = (option) => {
        return (
            <tr key={option.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{option.name}</td>
                <td className="tableData">{option.value}</td>
                <td className="tableData">
                    <CrudModal entity={option}
                               icon="edit"
                               btnClass="btn-primary btn-sm defaultBtnEdit"
                               title={strings.edit}
                               showText={true}
                               onSubmit={this.editCriteriaTypeOption}
                               body={CriteriaTypeOptionEdit}/>
                </td>
                <td className="tableData">
                    <DeleteModal btnClass={"ml-1 defaultBtn"}
                                 showText={true}
                                 prompt={strings.deleteOption}
                                 doDelete={() => this.deleteCriteriaTypeOption(option.id)}/>
                </td>
            </tr>
        )
    }
}

export default CriteriaTypeOptions;