import React from 'react'
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faChalkboardTeacher,
    faChartBar,
    faComment,
    faFileAlt,
    faPencilAlt,
    faPoll,
    faQuestion,
    faScroll,
    faUserCog
} from "@fortawesome/free-solid-svg-icons";
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import './Sidebar.css'
import {strings} from "../../Localization/Localization";
import {NavLink} from "react-router-dom";
import agencySidebarIcon from "../../assets/images/ikonaAgencija.png"
import UserRepository from "../../repository/UserRepository";


class SidebarMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userRole: localStorage.getItem('role'),
            additionalInfo: localStorage.getItem('additionalRoleInfo'),
            email: localStorage.getItem('email'),
            user: {},
        }
    }

    componentDidMount() {
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
        UserRepository.findUserByEmail(this.state.email).then(res => {
            this.setState({
                user: res.data
            })
        })

    }

    render() {

        let administrationAdmin = [
            // {
            //     label: strings.users,
            //     imgSrc: 'icon-point-contact.png',
            //     path: '/users'
            // },
            {
                label: strings.institutions,
                imgSrc: 'icon-point-contact.png',
                path: '/institutions'
            },
            {
                label: strings.tags,
                imgSrc: 'icon-profile.png',
                path: '/tags'
            },
            {
                label: strings.institutionCategory,
                imgSrc: 'icon-profile.png',
                path: '/institutioncategory'
            }
        ]
        let announcAdmin = [
            {
                label: strings.allannouncements,
                imgSrc: 'icon-documentation.png',
                path: '/allannouncements'
            },
            {
                label: strings.report,
                imgSrc: 'icon-documentation.png',
                path: '/announcement/report'
            }
        ]
        let questionsAdmin = [
            {
                label: strings.questions,
                imgSrc: 'icon-offerings.png',
                path: '/Question'
            },
        ]
        let feedbackAdmin = [
            {
                label: strings.feedbacks,
                imgSrc: 'icon-audit.png',
                path: '/feedbacks'
            },
            // {
            //     label: strings.myfeedbackpublications,
            //     imgSrc: 'icon-audit.png',
            //     path: '/myfeedbackpublications'
            // }
        ]
        let proactiveTransparencyAdmin = [
            {
                label: strings.proactiveTransparency,
                imgSrc: 'icon-audit.png',
                path: '/proactivity'
            },
            // {
            //     label: strings.myfeedbackpublications,
            //     imgSrc: 'icon-audit.png',
            //     path: '/myfeedbackpublications'
            // }
        ]
        let pubDocAdmin = [
            {
                label: strings.PublicDocumentsRegistry,
                imgSrc: 'icon-audit.png',
                path: '/publicdocument'
            },
            {
                label: strings.PublicDocumentTypes,
                imgSrc: 'icon-audit.png',
                path: '/publicdoctypes'
            },
            {
                label: strings.monitoringOnPublicBodies,
                imgSrc: 'icon-audit.png',
                path: '/monitoring'
            }
        ]

        let evaluationAdmin = [
            {
                label: strings.allReports,
                imgSrc: 'icon-point-contact.png',
                path: '/ListOfReports'
            },
            {
                label: strings.sendNotificationForDocuments,
                imgSrc: 'icon-point-contact.png',
                path: '/sendRequest'
            },
            {
                label: strings.typesAnswers,
                imgSrc: 'icon-point-contact.png',
                path: '/criteriaType'
            }
        ]
        let administrationModerator = [
            {
                label: strings.users,
                imgSrc: 'icon-point-contact.png',
                path: '/users'
            },
            {
                label: strings.myInst,
                imgSrc: 'icon-point-contact.png',
                path: '/myInstitution'
            },
        ]
        let announcModerator = [
            {
                label: strings.mypublications,
                imgSrc: 'icon-documentation.png',
                path: '/mypublications'
            },
        ]
        let feedbackModerator = [

            {
                label: strings.myfeedbackpublications,
                imgSrc: 'icon-audit.png',
                path: '/myfeedbackpublications'
            }
        ];
        let pubDocModerator = [
            {
                label: strings.PublicDocumentsRegistry,
                imgSrc: 'icon-audit.png',
                path: '/publicdocument',
            }
        ]
        let announcUser = [
            {
                label: strings.mypublications,
                imgSrc: 'icon-documentation.png',
                path: '/mypublications'
            },
            {
                label: strings.allannouncements,
                imgSrc: 'icon-documentation.png',
                path: '/allannouncements'
            }]

        let feedbackUser = [
            {
                label: strings.feedbacks,
                imgSrc: 'icon-audit.png',
                path: '/feedbacks'
            },
            {
                label: strings.myfeedbackpublications,
                imgSrc: 'icon-audit.png',
                path: '/myfeedbackpublications'
            }
        ];
        let pubDocUser = [
            {
                label: strings.PublicDocumentsRegistry,
                imgSrc: 'icon-audit.png',
                path: '/publicdocument'
            }
        ];

        let reportsEvaluator = [
            {
                label: strings.allReports,
                imgSrc: 'icon-audit.png',
                path: '/reports'
            }
        ];

        let items = null;

        // Admin menu items
        if (this.state.userRole === "ROLE_ADMIN") {
            items = <>

                <SubMenu title={strings.administration} suffix={<Badge pill variant="danger">Admin</Badge>}
                         icon={<FontAwesomeIcon icon={faUserCog}/>}>
                    {administrationAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.announcement} icon={<FontAwesomeIcon icon={faScroll}/>}>
                    {announcAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                {/*<SubMenu title={strings.questions} icon={<FontAwesomeIcon icon={faQuestion}/>}>*/}
                {/*    {questionsAdmin.map((item, index) => {*/}
                {/*        return (<MenuItem key={index}>*/}
                {/*            <NavLink to={item.path}*/}
                {/*                     // onClick={() => this.setActiveItem(item.label)}*/}
                {/*            >*/}
                {/*                {item.label}*/}
                {/*            </NavLink>*/}
                {/*        </MenuItem>)*/}
                {/*    })}*/}
                {/*</SubMenu>*/}

                {/*<SubMenu title={strings.feedbacks} icon={<FontAwesomeIcon icon={faPoll}/>}>*/}
                {/*    {feedbackAdmin.map((item, index) => {*/}
                {/*        return (<MenuItem key={index}>*/}
                {/*            <NavLink to={item.path}*/}
                {/*                // onClick={() => this.setActiveItem(item.label)}*/}
                {/*            >*/}
                {/*                {item.label}*/}
                {/*            </NavLink>*/}
                {/*        </MenuItem>)*/}
                {/*    })}*/}
                {/*</SubMenu>*/}

                {/*commented public document menu*/}
                {/*<SubMenu title={strings.publicDocuments} icon={<FontAwesomeIcon icon={faFileAlt}/>}>*/}
                {/*    {pubDocAdmin.map((item, index) => {*/}
                {/*        return (<MenuItem key={index}>*/}
                {/*            <NavLink to={item.path}*/}
                {/*                // onClick={() => this.setActiveItem(item.label)}*/}
                {/*            >*/}
                {/*                {item.label}*/}
                {/*            </NavLink>*/}
                {/*        </MenuItem>)*/}
                {/*    })}*/}
                {/*</SubMenu>*/}

                {/*commented evaluation menu*/}
                {/*<SubMenu title={strings.evaluation} icon={<FontAwesomeIcon icon={faChartBar}/>}>*/}
                {/*    {evaluationAdmin.map((item, index) => {*/}
                {/*        return (<MenuItem key={index}>*/}
                {/*            <NavLink to={item.path}*/}
                {/*                // onClick={() => this.setActiveItem(item.label)}*/}
                {/*            >*/}
                {/*                {item.label}*/}
                {/*            </NavLink>*/}
                {/*        </MenuItem>)*/}
                {/*    })}*/}
                {/*</SubMenu>*/}

                {/*commented menu proactivity*/}
                {/*<SubMenu title={strings.proactiveTransparency} icon={<FontAwesomeIcon icon={faPoll}/>}>*/}
                {/*    {proactiveTransparencyAdmin.map((item, index) => {*/}
                {/*        return (<MenuItem key={index}>*/}
                {/*            <NavLink to={item.path}*/}
                {/*                // onClick={() => this.setActiveItem(item.label)}*/}
                {/*            >*/}
                {/*                {item.label}*/}
                {/*            </NavLink>*/}
                {/*        </MenuItem>)*/}
                {/*    })}*/}
                {/*</SubMenu>*/}

                <MenuItem
                    icon={<FontAwesomeIcon icon={faPoll}/>}
                    to='/feedbacks'
                >
                    {strings.feedbacks}
                    <NavLink
                        to="/feedbacks"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>

                <MenuItem
                    icon={<FontAwesomeIcon icon={faPoll}/>}
                    to='/proactivity'
                >
                    {strings.proactiveTransparency}
                    <NavLink
                        to="/proactivity"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>

                <MenuItem
                    icon={<FontAwesomeIcon icon={faQuestion}/>}
                    to='/Question'
                >
                    {strings.questions}
                    <NavLink
                        to="/Question"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>

                <MenuItem
                    icon={<FontAwesomeIcon icon={faChalkboardTeacher}/>}
                    to='/tutorial'
                >
                    {strings.tutorial}
                    <NavLink
                        to="/tutorial"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>
            </>
        }

        // Moderator menu items
        if (this.state.userRole === "ROLE_INSTITUTIONAL_MODERATOR") {
            items = <>

                {this.state.user.reportMaker === 1 &&
                <SubMenu title={strings.administration} icon={<FontAwesomeIcon icon={faUserCog}/>}>
                    {administrationModerator.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>
                }

                {/*<SubMenu title={strings.announcement} icon={<FontAwesomeIcon icon={faScroll}/>}>*/}
                {/*    {announcModerator.map((item, index) => {*/}
                {/*        return (<MenuItem key={index}>*/}
                {/*            <NavLink to={item.path}*/}
                {/*                // onClick={() => this.setActiveItem(item.label)}*/}
                {/*            >*/}
                {/*                {item.label}*/}
                {/*            </NavLink>*/}
                {/*        </MenuItem>)*/}
                {/*    })}*/}
                {/*</SubMenu>*/}

                {/*{this.state.additionalInfo === "QUESTIONS_ROLE" ?*/}
                {/*    <SubMenu title={strings.questions} icon={<FontAwesomeIcon icon={faQuestion}/>}>*/}
                {/*        {questionsAdmin.map((item, index) => {*/}
                {/*            return (<MenuItem key={index}>*/}
                {/*                <NavLink to={item.path}*/}
                {/*                    // onClick={() => this.setActiveItem(item.label)}*/}
                {/*                >*/}
                {/*                    {item.label}*/}
                {/*                </NavLink>*/}
                {/*            </MenuItem>)*/}
                {/*        })}*/}
                {/*    </SubMenu> : null}*/}

                {/*<SubMenu title={strings.feedbacks} icon={<FontAwesomeIcon icon={faPoll}/>}>*/}
                {/*    {feedbackModerator.map((item, index) => {*/}
                {/*        return (<MenuItem key={index}>*/}
                {/*            <NavLink to={item.path}*/}
                {/*                // onClick={() => this.setActiveItem(item.label)}*/}
                {/*            >*/}
                {/*                {item.label}*/}
                {/*            </NavLink>*/}
                {/*        </MenuItem>)*/}
                {/*    })}*/}
                {/*</SubMenu>*/}

                <MenuItem
                    icon={<FontAwesomeIcon icon={faPoll}/>}
                    to='/mypublications'
                >
                    {strings.announcements}
                    <NavLink
                        to="/mypublications"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>


                <MenuItem
                    icon={<FontAwesomeIcon icon={faPoll}/>}
                    to='/myfeedbackpublications'
                >
                    {strings.feedbacks}
                    <NavLink
                        to="/myfeedbackpublications"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>

                {this.state.user.reportMaker === 1 &&
                <MenuItem
                    icon={<FontAwesomeIcon icon={faPoll}/>}
                    to='/myProactivityPublications'
                >
                    {strings.proactiveTransparency}
                    <NavLink
                        to="/myProactivityPublications"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>
                }

                {/*<SubMenu title={strings.publicDocuments} icon={<FontAwesomeIcon icon={faFileAlt}/>}>*/}
                {/*    {pubDocModerator.map((item, index) => {*/}
                {/*        return (<MenuItem key={index}>*/}
                {/*            <NavLink to={item.path}*/}
                {/*                // onClick={() => this.setActiveItem(item.label)}*/}
                {/*            >*/}
                {/*                {item.label}*/}
                {/*            </NavLink>*/}
                {/*        </MenuItem>)*/}
                {/*    })}*/}
                {/*</SubMenu>*/}

                {this.state.additionalInfo === "QUESTIONS_ROLE" ?
                    <MenuItem
                        icon={<FontAwesomeIcon icon={faQuestion}/>}
                        to='/Question'
                    >
                        {strings.questions}
                        <NavLink
                            to="/Question"
                            // onClick={() => this.setActiveItem(strings.annualReport)}
                        />
                    </MenuItem> : null}

                <MenuItem
                    icon={<FontAwesomeIcon icon={faChalkboardTeacher}/>}
                    to='/tutorial'
                >
                    {strings.tutorial}
                    <NavLink
                        to="/tutorial"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>
            </>
        }

        if (this.state.userRole === "ROLE_EVALUATOR") {
            items = <>
                <SubMenu title={strings.administration} icon={<FontAwesomeIcon icon={faUserCog}/>}>
                    {administrationModerator.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.announcement} icon={<FontAwesomeIcon icon={faScroll}/>}>
                    {announcModerator.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.questions} icon={<FontAwesomeIcon icon={faQuestion}/>}>
                    {questionsAdmin.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.feedbacks} icon={<FontAwesomeIcon icon={faPoll}/>}>
                    {feedbackModerator.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.publicDocuments} icon={<FontAwesomeIcon icon={faFileAlt}/>}>
                    {pubDocModerator.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.allReports} icon={<FontAwesomeIcon icon={faFileAlt}/>}>
                    {reportsEvaluator.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <MenuItem
                    icon={<FontAwesomeIcon icon={faChalkboardTeacher}/>}
                    to='/tutorial'
                >
                    {strings.tutorial}
                    <NavLink
                        to="/tutorial"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>
            </>
        }

        // User menu items
        if (this.state.userRole === "ROLE_USER") {
            items = <>
                <SubMenu title={strings.announcement} icon={<FontAwesomeIcon icon={faScroll}/>}>
                    {announcUser.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.feedbacks} icon={<FontAwesomeIcon icon={faPoll}/>}>
                    {feedbackUser.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <SubMenu title={strings.publicDocuments} icon={<FontAwesomeIcon icon={faFileAlt}/>}>
                    {pubDocUser.map((item, index) => {
                        return (<MenuItem key={index}>
                            <NavLink to={item.path}
                                // onClick={() => this.setActiveItem(item.label)}
                            >
                                {item.label}
                            </NavLink>
                        </MenuItem>)
                    })}
                </SubMenu>

                <MenuItem
                    icon={<FontAwesomeIcon icon={faChalkboardTeacher}/>}
                    to='/tutorial'
                >
                    {strings.tutorial}
                    <NavLink
                        to="/tutorial"
                        // onClick={() => this.setActiveItem(strings.annualReport)}
                    />
                </MenuItem>
            </>
        }

        return (
            <ProSidebar>
                <SidebarHeader>
                    <div
                        style={{
                            padding: '24px',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            fontSize: 14,
                            letterSpacing: '1px',
                            // overflow: 'hidden',
                            // textOverflow: 'ellipsis',
                            // whiteSpace: 'nowrap',
                            wordWrap: 'brake-word',
                            textAlign: 'center'
                        }}
                    >
                        {strings.agencyName}
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="circle">
                        {items}
                        {this.state.userRole === "ROLE_ADMIN" || this.state.user.reportMaker === 1 ?
                            <MenuItem
                                icon={<FontAwesomeIcon icon={faPencilAlt}/>}
                                to='/AnnualReport'
                            >
                                {strings.annualReport}
                                <NavLink
                                    to="/AnnualReport"
                                    // onClick={() => this.setActiveItem(strings.annualReport)}
                                />
                            </MenuItem> : <div/>}

                        {/*{this.state.userRole === "ROLE_ADMIN" ?*/}
                        {/*    <MenuItem*/}
                        {/*        icon={<FontAwesomeIcon icon={faFileArchive}/>}*/}
                        {/*        to='/ListOfReports'*/}
                        {/*    >*/}
                        {/*        {strings.allReports}*/}
                        {/*        <NavLink*/}
                        {/*            to="/ListOfReports"*/}
                        {/*            // onClick={() => this.setActiveItem(strings.allReports)}*/}
                        {/*        />*/}
                        {/*    </MenuItem> : <div></div>}*/}
                    </Menu>
                </SidebarContent>

                <SidebarFooter style={{textAlign: 'center'}}>

                    <a href="http://komspi.mk/" target="_blank" rel="noopener noreferrer">
                        <img src={agencySidebarIcon} className="sidebarLogoImg" alt="agencySidebarIcon"/>
                    </a>

                    <div className="sidebar-btn-wrapper">
                        <NavLink
                            className="sidebar-btn"
                            to={'/FAQ'}
                            style={{height: '50px'}}
                        >
                            <div>
                                <div style={{borderRadius: '14px'}}>
                                    <span style={{color: "lightgray", fontWeight: "bold"}}
                                          className="mt-2 mb-2">{strings.faq}</span>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </SidebarFooter>

            </ProSidebar>
        );
    }
}

export default SidebarMenu;
