import React, {Component} from "react";
import {toast} from "react-toastify";
import FeedbackPublicationRepository from "../../repository/FeedbackPublicationRepository";
import {strings} from "../../Localization/Localization";
import {NavLink} from "react-router-dom";
import {faArrowLeft, faExclamation, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FeedbackRepository from "../../repository/FeedbackRepository";
import FeedbackItemRepository from "../../repository/FeedbackItemRepository";
import './css/DesignPageAnswerAndSend.css'


class PreviewAnsweredAndSendReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: [],
            feedbackPublication: null,
            feedbackId: 0,
            options: [],
            feedbackTitle: null,
            feedItems: [],
            dataAnswers: {},
            institutionName: "",
            textFromAdmin: "",
        }
    }

    componentDidMount() {
        this.getAllFeedbackAnswers();

        let feedbackPubId = this.props.match.params.id;

        FeedbackPublicationRepository.getFeedbackPublicationById(feedbackPubId).then(async res => {
            const feedbackId = res.data.feedback.id;
            await this.setState({
                institutionName: res.data.institution.nameMk,
                feedbackId: feedbackId,
                feedbackPublication: res.data
            })
            await FeedbackRepository.getFeedbackById(feedbackId).then(res => {
                this.setState({
                    feedbackTitle: res.data.name,
                })
            })
            this.getFeedbackItemsByFeedbackId(feedbackId);
        })

    }

    sortFeedbackItems = () => {
        this.state.feedItems.sort((a, b) => (a.dateCreated > b.dateCreated) ? 1 : -1)
    }

    submitAnswers = () => {

        FeedbackPublicationRepository.submitAnswersFromAdmin(this.state.feedbackPublication.id, this.state.dataAnswers, this.state.textFromAdmin).then(r => {
            toast.success(strings.successfullySubmittedAnswers)
            this.props.history.push(`/proactiveFinished/${this.state.feedbackId}`);
        }).catch(error => {
            toast.error(strings.failedTSubmitAnswers)
        });
    }

    onTextChangeHandler = (e) => {
        this.setState({
            textFromAdmin: e.target.value
        })
    }

    render() {

        {
            this.sortFeedbackItems()
        }

        const questions = this.state.feedItems.map(item => {
            let display = null;
            if (item.type === "SINGLE_CHOICE") {
                display = this.singleChoice(item);
            } else if (item.type === "MULTIPLE_CHOICE") {
                display = this.multipleChoice(item);
            } else if (item.type === "NUMERIC_FIELD") {
                display = this.numericField(item);
            } else if (item.type === "TEXT_FIELD") {
                display = this.textField(item);
            }
            return display;
        });

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h2 style={{color: "#1C4857"}}
                            className="mt-4 mb-4 text-center">{strings.previewAnswer}: {this.state.feedbackTitle ? this.state.feedbackTitle : ""}</h2>
                        <h2 style={{color: "#1C4857"}}
                            className="mt-4 mb-4 text-center">{this.state.institutionName ? this.state.institutionName : ""}</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-5">
                        {this.state.answers.map(this.displayChoice)}
                    </div>
                    <div className="col-2"
                         style={{
                             borderLeft: '3px solid black',
                             position: 'sticky',
                             left: '50%'
                         }}
                    />

                    <div className="col-5">
                        {questions}
                    </div>
                </div>

                <div className="row">
                    <div className="col-6"/>
                    <div className="col-6 text-right">
                        <textarea
                            id="textFromAdmin"
                            required
                            rows="6"
                            placeholder="Внесете порака за корисникот"
                            name="textFromAdmin"
                            style={{border: '1px solid gray', borderRadius: '20px'}}
                            onChange={this.onTextChangeHandler}
                            className="form-control mt-3 mb-3"
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="col-12 mt-3 mb-3 text-right">
                        <NavLink to={`/proactiveFinished/${this.state.feedbackId}`}>
                            <button type="button" className="btn btn-dark mr-3 visualizeFeedbackButtons">
                                <FontAwesomeIcon icon={faArrowLeft} size="lg"
                                                 style={{paddingRight: '4px'}}/>{strings.goBack}</button>
                            <button className="btn btn-pill btn-info visualizeFeedbackButtons"
                                    onClick={this.submitAnswers}>
                                <FontAwesomeIcon icon={faPaperPlane} size="lg"
                                                 style={{paddingRight: '4px'}}/>{strings.answer}</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        )
    }


    getAllFeedbackAnswers = () => {
        let feedbackPubId = this.props.match.params.id;
        FeedbackPublicationRepository.getAnswers(feedbackPubId).then(res => {
            this.setState({
                answers: res.data,
            });
        }).then(() => {
            this.state.answers.map(item => {
                this.setState(prevState => ({
                    options: [...prevState.options, item.item.options]
                }))
            })
        }).catch(() => {
            toast.error(strings.failedToLoadAnswers)
        })
    }

    getFeedbackItemsByFeedbackId = (feedbackId) => {
        FeedbackItemRepository.getAllFeedbackItemsRest(feedbackId).then((res) => {
            this.setState({feedItems: res.data})
        }).catch(() => {
            toast.error(strings.failedToLoadData)
        })
    }

    singleChoice = (item) => {
        return this.showChoice(item, 'radio');
    }


    multipleChoice = (item) => {
        return this.showChoice(item, 'checkbox')
    }

    showChoice = (item, type) => {
        const options = item.options.map(option => (<div key={option}>
            <input name={item.id}
                   className="visualizeFeedbackCheckInput"
                   onChange={this.updateAnswer}
                   value={option}
                   type={type}/>
            <label className="form-check-label ml-1">{option}</label>
        </div>));
        return (item.subQuestion ?
                (
                    <div className="visualizeFeedbackQuestionContainerChild" key={item.id}>
                        <div className="row visualizeFeedbackQuestionHeaderDiv">
                            <div className="col-12">
                                <h4 title="Question">{item.name}</h4>
                            </div>
                        </div>
                        <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                            {/*<div className="col-12">*/}
                            {/*    <h3>{item.description}</h3>*/}
                            {/*</div>*/}
                            <div className="col-12 form-group kt-checkbox">
                                {options}
                            </div>
                        </div>
                        {item.required && <div className="row w-100">
                            <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                                <span className="badge badge-pill badge-danger p-2 ">
                                    <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1"/>
                                    {strings.required}
                                </span>
                            </div>
                        </div>}
                    </div>
                )
                :
                (<div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
                    <div className="row visualizeFeedbackQuestionHeaderDiv">
                        <div className="col-12">
                            <h4 title="Question">{item.name}</h4>
                        </div>
                    </div>
                    <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                        {/*<div className="col-12">*/}
                        {/*    <h3>{item.description}</h3>*/}
                        {/*</div>*/}
                        <div className="col-12 form-group kt-checkbox">
                            {options}
                        </div>
                    </div>
                    {item.required && <div className="row w-100">
                        <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                            <span className="badge badge-pill badge-danger p-2 ">
                                <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1"/>
                                {strings.required}
                            </span>
                        </div>
                    </div>}
                </div>)
        )
    }


    textField = (item) => {
        return this.displayInput(item, 'text');
    }

    numericField = (item) => {
        return this.displayInput(item, 'number');
    }

    displayInput = (item, type) => {
        // return (<div className="col-12" key={item.id}>
        //     <h4 title="Question">{item.name}</h4>
        //     <p>{item.description}</p>
        //     <div className="col-12">
        //         <input name={item.id}
        //                type={type}
        //                onChange={this.updateAnswer}/>
        //     </div>
        // </div>);

        return (item.subQuestion ?
                (<div className=" visualizeFeedbackQuestionContainerChild" key={item.id}>
                    <div className="row visualizeFeedbackQuestionHeaderDiv">
                        <div className="col-12">
                            <h4 title="Question">{item.name}</h4>
                        </div>
                    </div>
                    <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                        {/*<div className="col-12">*/}
                        {/*    <h3>{item.description}</h3>*/}
                        {/*</div>*/}
                        <div className="col-12 w-100 form-group">
                            <input name={item.id}
                                   className="w-100 pl-2 pr-2 visualizeFeedbackTextInput"
                                   type={type}
                                   onChange={this.updateAnswer}/>
                            {type === "number" &&
                            <small id="emailHelp" className="form-text text-muted">{strings.numberOnly}</small>}
                        </div>
                    </div>
                    {item.required && <div className="row w-100">
                        <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                    <span className="badge badge-pill badge-danger p-2 ">
                        <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1"/>
                        {strings.required}
                    </span>
                        </div>
                    </div>}
                </div>)
                :
                (<div className="container-fluid visualizeFeedbackQuestionContainer" key={item.id}>
                    <div className="row visualizeFeedbackQuestionHeaderDiv">
                        <div className="col-12">
                            <h4 title="Question">{item.name}</h4>
                        </div>
                    </div>
                    <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                        {/*<div className="col-12">*/}
                        {/*    <h3>{item.description}</h3>*/}
                        {/*</div>*/}
                        <div className="col-12 w-100 form-group">
                            <input name={item.id}
                                   className="w-100 pl-2 pr-2 visualizeFeedbackTextInput"
                                   type={type}
                                   onChange={this.updateAnswer}/>
                            {type === "number" &&
                            <small id="emailHelp" className="form-text text-muted">{strings.numberOnly}</small>}
                        </div>
                    </div>
                    {item.required && <div className="row w-100">
                        <div className="col-12 w-100 visualizeFeedbackQuestionFooterDiv">
                    <span className="badge badge-pill badge-danger p-2 ">
                        <FontAwesomeIcon icon={faExclamation} size="sm" className="mr-1"/>
                        {strings.required}
                    </span>
                        </div>
                    </div>}
                </div>)
        )

    }

    updateAnswer = (e) => {
        const checked = e.target.checked;
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;

        this.setState((state) => {
            const dataAnswers = state.dataAnswers || {};
            if (type === 'checkbox') {
                const prev = dataAnswers[name] || [];
                if (checked) {
                    prev.push(value);
                } else {
                    prev.splice(prev.indexOf(value), 1);
                }
                dataAnswers[name] = prev;
            } else {
                dataAnswers[name] = value;
            }
            return {dataAnswers: dataAnswers};
        });
    }


    displayChoice = (items, index) => {
        let finalOptions = []
        if (items.item.options) {
            let optionsQuestion = items.item.options.map((item, index) => {
                if (items.item.type === "SINGLE_CHOICE") {
                    let e;
                    if (items.value === item) {
                        e = <div key={index}>
                            <input
                                // name={items.item.id}
                                className="visualizeFeedbackCheckInput"
                                readOnly={true}
                                value={items.value}
                                checked={true}
                                disabled={true}
                                type={"radio"}/>
                            <label className="form-check-label ml-1">{item}</label>
                        </div>
                        finalOptions.push(e)
                    } else {
                        e = <div key={index}>
                            <input
                                // name={items.item.id}
                                readOnly={true}
                                className="visualizeFeedbackCheckInput"
                                value={items.value}
                                disabled={true}
                                type={"radio"}/>
                            <label className="form-check-label ml-1">{item}</label>
                        </div>
                        finalOptions.push(e)
                    }
                    return e
                } else if (items.item.type === "MULTIPLE_CHOICE") {
                    let values = items.value.split(";"); // c i s
                    let e;
                    let flag;
                    flag = values.includes(item)
                    if (flag) {
                        e = <div key={index}>
                            <input
                                className="visualizeFeedbackCheckInput"
                                checked={true}
                                readOnly={true}
                                disabled={true}
                                type={"checkbox"}/>
                            <label className="form-check-label ml-1">{item}</label>
                        </div>
                        finalOptions.push(e)

                    } else {
                        e = <div key={index}>
                            <input
                                className="visualizeFeedbackCheckInput"
                                readOnly={true}
                                disabled={true}
                                type={"checkbox"}/>
                            <label className="form-check-label ml-1">{item}</label>
                        </div>
                        finalOptions.push(e)

                    }
                    flag = false;
                    return e
                }

            })
        }

        if (items.item.type === "NUMERIC_FIELD") {
            let y = <div key={index}>
                <input className="visualizeFeedbackCheckInput"
                       value={items.value}
                       readOnly={true}
                       disabled={true}
                       type={"text"}/>
            </div>
            finalOptions.push(y)
        } else if (items.item.type === "TEXT_FIELD") {
            let x = <div key={index}>
                <input className="visualizeFeedbackCheckInput"
                       value={items.value}
                       readOnly={true}
                       disabled={true}
                       type={"text"}/>
            </div>
            finalOptions.push(x)
        }

        return (items.item.subQuestion ?
                (<div className="visualizeFeedbackQuestionContainerChild" key={index} style={{marginLeft: '200px'}}>
                    <div className="row visualizeFeedbackQuestionHeaderDiv">
                        <div className="col-12">
                            <h4 title="Question">{items.item.name}</h4>
                        </div>
                    </div>
                    <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                        {/*<div className="col-12">*/}
                        {/*    <h3>{items.item.description}</h3>*/}
                        {/*</div>*/}
                        <div className="col-12 form-group kt-checkbox">
                            {finalOptions}
                        </div>
                    </div>
                </div>)
                :
                (<div className="container-fluid visualizeFeedbackQuestionContainer" key={index}>
                    <div className="row visualizeFeedbackQuestionHeaderDiv">
                        <div className="col-12">
                            <h4 title="Question">{items.item.name}</h4>
                        </div>
                    </div>
                    <div className="row w-100 visualizeFeedbackQuestionBodyDiv">
                        {/*<div className="col-12">*/}
                        {/*    <h3>{items.item.description}</h3>*/}
                        {/*</div>*/}
                        <div className="col-12 form-group kt-checkbox">
                            {finalOptions}
                        </div>
                    </div>
                </div>)
        )
    }
}

export default PreviewAnsweredAndSendReport;