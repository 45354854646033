import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import {Col, Row} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {strings} from "../../Localization/Localization";

const MailModal = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));


    return (
        <Row>
            <Col>
                <label className="mb-2"><span style={{color: "red", fontSize: '18px'}}
                                              className="mr-1">*</span>{strings.message}:</label>
                <form onSubmit={props.onSubmit}>
                    <textarea
                        className="mb-5"
                        style={{height: "10em", width: "100%"}}
                        name="message"
                        required
                        onChange={props.handleChange}/>
                </form>
            </Col>
        </Row>
    );
};
export default MailModal;
