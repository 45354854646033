import React, {Component} from "react";
import PublicDocumentTypeRepository from "../../repository/PublicDocumentTypeRepository";
import PublicDocumentsRegistryRepository from "../../repository/PublicDocumentsRegistryRepository";
import InstitutionRepository from "../../repository/InstitutionRepository";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt, faFilePdf, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {strings} from "../../Localization/Localization";
import {toast} from "react-toastify";
import Select from "react-select";
import {Link} from "react-router-dom";
import './css/PublicDocument.css';
import {LOCALHOST} from "../../shared/utility";

class PublicDocument extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allYears: [],//site godini
            year: new Date().getFullYear(), // selektirana godina
            publicDocTypes: [], // site tipovi na javni dokumenti
            document: null, // javen dokument
            typeForPubDoc: null, // selektiran tip na javen dokument
            role: localStorage.getItem('role'), // ulogata na najaveniot korisnik
            selectedInstitution: localStorage.getItem('institutionId') !== "-1" ? localStorage.getItem('institutionId') : null,//prikaz za odbranta institucija
            allInstitutions: [], // site institucii,
            active: "", // klasa za aktiven div
            hasDoc: true,
            institution: {},
            institutionName: '',
            institutionId: '',
        }
    }

    componentDidMount() {
        this.getAllYears();
        this.getAllInstitutions();
        this.getAllPublicDocumentTypes();

    }


    onSelectedInstitutionChangeHandler = (institution) => {
        if (institution !== null) {
            this.setState({
                institutionName: institution.label,
                selectedInstitution: institution.value,
                institution: institution,
                document: null,
                active: ""
            })
        } else {
            this.setState({
                institutionName: null,
                institutionId: null
            })
        }
    }

    render() {
        return (
            <div className="container">
                <h2 style={{textAlign: "left", color: "#1C4857"}}
                    className="mt-4 mb-4">{strings.PublicDocumentsRegistry}</h2>

                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 mt-3">
                        <label>
                            {strings.selectYear}
                        </label>
                        <select
                            name="year"
                            value={this.state.year}
                            onChange={this.yearChangeHandler}
                            style={{borderRadius: '10px'}}
                            className="form-control custom-select">
                            {this.state.allYears.map((year, index) => {
                                return (<option value={year} key={index}>{year}</option>)
                            })}
                        </select>
                    </div>

                    <div className="col-lg-5 col-md-5 col-sm-5 mt-3 ml-5">
                        {this.state.role === "ROLE_ADMIN" ?
                            <div style={{marginLeft: "auto"}}>
                                <label>
                                    {strings.institution}
                                </label>
                                {/*<select name="institutions" className="form-control custom-select"*/}
                                {/*        onChange={this.institutionChangeHandler}>*/}
                                {/*    <option>{strings.pleaseChooseInstitution}</option>*/}

                                {/*    {this.state.allInstitutions.map((institution) => {*/}
                                {/*        return (<option*/}
                                {/*            value={institution.id}*/}
                                {/*            key={institution.id}>{localStorage.getItem('activeLanguage') === 'mk' ? institution.nameMk : institution.nameAl}</option>)*/}
                                {/*    })}*/}
                                {/*</select>*/}
                                <Select
                                    placeholder={strings.pleaseChooseInstitution}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={this.state.allInstitutions}
                                    onChange={this.onSelectedInstitutionChangeHandler}
                                    name="institutions"
                                />
                            </div> : null}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 mt-5">

                        <div style={{maxHeight: "850px", overflowY: "auto"}}>
                            <ul className="list-group">
                                {this.state.publicDocTypes.length > 0 ?
                                    this.state.publicDocTypes.map((type, index) => {
                                        let name = type.name
                                        return (
                                            <li
                                                className={
                                                    "list-group-item " +
                                                    (name === this.state.active ? "active" : "")
                                                }
                                                key={index}
                                                style={{
                                                    background: "rgb(200 216 222)",
                                                    color: 'black',
                                                    fontSize: '18px'
                                                }}
                                        >
                                            {name.slice(0, 40) + "..."}
                                            <div style={{display: "flex", float: "right"}}>
                                                {this.state.selectedInstitution !== null ?
                                                    this.state.hasDoc === true && name === this.state.active ?
                                                        <button className="btn btn-sm ml-1 defaultBtn"
                                                                style={{
                                                                    "backgroundColor": "green",
                                                                    "color": "white",
                                                                    fontSize: '15px'
                                                                }}
                                                                onClick={() => this.getPubDoc(type)}>
                                                            <FontAwesomeIcon
                                                                icon={faExternalLinkAlt}
                                                                style={{paddingRight: '2px'}}
                                                                className="mr-1"
                                                            />
                                                            {strings.open}
                                                        </button>
                                                        :
                                                        <button className="btn btn-sm ml-1 btn-danger defaultBtn"
                                                                style={{"color": "white", fontSize: '15px'}}
                                                                onClick={() => this.getPubDoc(type)}>
                                                            <FontAwesomeIcon
                                                                icon={faExternalLinkAlt}
                                                                style={{paddingRight: '2px'}}
                                                                className="mr-1"
                                                            />
                                                            {strings.open}
                                                        </button>
                                                    :
                                                    <button className="btn btn-sm ml-1 defaultBtn"
                                                            style={{
                                                                "backgroundColor": "#BCDFEF",
                                                                "color": "black",
                                                                fontSize: '15px'
                                                            }}
                                                            disabled={true}>
                                                        <FontAwesomeIcon
                                                            icon={faExternalLinkAlt}
                                                            style={{paddingRight: '2px'}}
                                                            className="mr-1"
                                                        />
                                                        {strings.open}
                                                    </button>}
                                            </div>
                                            </li>
                                        )
                                    }) : ""}
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 mt-5">
                        {this.state.document && (
                            <div className="list-group">
                                <div className="list-group-item"
                                     style={{background: "rgb(206 227 234)", color: 'black'}}>
                                    <div style={{wordWrap: "break-word", fontSize: "15px"}}>
                                        <span>{strings.publicDocType + ": " + this.state.document.type.name}</span>
                                    </div>
                                    <div className={"mb-3 mt-3"} style={{fontSize: "15px"}}><b
                                    >{strings.Url + ": "}</b>{this.state.document.url !== null ? this.state.document.url : strings.noUrl}
                                    </div>
                                    <div><b
                                        style={{fontSize: "15px"}}>PDF: </b>{this.state.document.attachment !== null ?
                                        <a style={{color: "blue", marginBottom: "4px", fontSize: '15px'}}
                                           href={LOCALHOST + "rest/attachment/download/" + this.state.document.attachment.id}
                                           target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faFilePdf}
                                                             color="red"
                                                             size="lg"
                                                             style={{paddingRight: '4px'}}
                                                             className="ml-2"
                                            />
                                            {this.state.document.attachment.name}
                                        </a> : strings.noPDF}</div>
                                    {this.state.role !== "ROLE_ADMIN" ?
                                        <div className="mt-3">
                                            <Link to={{
                                                pathname: `/addPublicDocument/${this.state.document.type.id}`,
                                                state: {
                                                    item: this.state.document.type,
                                                    year: this.state.year,
                                                    document: this.state.document
                                                }
                                            }}
                                            >
                                                <button type="button"
                                                        className="btn btn-info defaultBtn">
                                                    <FontAwesomeIcon icon={faPlusCircle} className="mr-1"/>
                                                    {strings.add}
                                                </button>
                                            </Link>
                                        </div>
                                        : <div/>}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    onAddLink = (entity) => {
        let document = {
            year: this.state.year,
            type: entity.type,
            url: entity.url,
            attachment: entity.attachment
        };
        this.setState({document: document})
        const form = new FormData()
        form.append("year", this.state.year);
        form.append("type", entity.type.id)
        form.append("url", entity.url)
        if (this.state.role === 'ROLE_ADMIN') {
            form.append('institutionId', this.state.selectedInstitution)
        } else {
            form.append('institutionId', localStorage.getItem('institutionId'))
        }
        return PublicDocumentsRegistryRepository.savePublicDocForYear(form)
            .then(() => {
                toast.success(strings.successfullyAddedLink)
                this.getPubDoc(entity.type);
            })
            .catch(() => {
                toast.error(strings.failedToAddLink)
            })
    }
    onAddPdf = (entity) => {
        const form = new FormData()
        form.append("year", this.state.year);
        form.append("type", entity.type.id)
        form.append("url", this.state.document.url)
        form.append("attachment", entity.attachment)
        if (this.state.role === 'ROLE_ADMIN') {
            form.append('institutionId', this.state.selectedInstitution)
        } else {
            form.append('institutionId', localStorage.getItem('institutionId'))
        }
        return PublicDocumentsRegistryRepository.savePublicDocForYear(form)
            .then(() => {
                toast.success(strings.successfullyAddedPdf)
                this.getPubDoc(entity.type);
            })
            .catch(() => {
                toast.error(strings.failedToAddPdf)
            })
    }
    getPubDoc = (type) => {
        let year = this.state.year;
        let typeId = type.id;
        let institutionId;
        if (this.state.role === "ROLE_ADMIN") {
            institutionId = this.state.selectedInstitution;
        } else {
            institutionId = localStorage.getItem('institutionId');
        }
        if (this.state.typeForPubDoc !== type) {
            PublicDocumentsRegistryRepository.getSingleDoc(year, institutionId, typeId).then(res => {
                if (res.data !== "") {
                    this.setState({
                        document: res.data,
                        active: type.name,
                        hasDoc: true
                    })
                    this.setState({
                        typeForPubDoc: type
                    })
                } else {
                    let document = {}
                    document.type = type;
                    document.url = null;
                    document.attachment = null;
                    this.setState({
                        document: document,
                        active: type.name,
                        hasDoc: false
                    })
                    this.setState({
                        typeForPubDoc: type
                    })
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            this.setState({
                typeForPubDoc: null,
                active: "",
                document: null
            })
        }
    }

    institutionChangeHandler = (e) => {
        this.setState({
            selectedInstitution: e.target.value,
            document: null,
            active: ""
        })
    }
    yearChangeHandler = (e) => {
        this.setState({
            year: e.target.value,
            document: null,
            active: ""
        })
    }

    getAllInstitutions = () => {
        InstitutionRepository.allActiveInstitutions().then(res => {
            let allInstitutions = [];
            for (let i = 0; i < res.data.length; i++) {
                allInstitutions.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk,
                })
            }
            this.setState({
                allInstitutions: allInstitutions
            })
        })
    }

    getAllYears = () => {
        PublicDocumentsRegistryRepository.getAllYear().then(res => {
            const allYears = res.data;
            const currentYear = this.state.year;
            if (allYears.indexOf(currentYear) < 0) {
                allYears.push(currentYear);
            }
            if (allYears.indexOf(currentYear + 1) < 0) {
                allYears.push(currentYear + 1);
            }
            if (allYears.indexOf(currentYear + 2) < 0) {
                allYears.push(currentYear + 2);
            }
            if (allYears.indexOf(currentYear - 1) < 0) {
                allYears.push(currentYear - 1);
            }
            if (allYears.indexOf(currentYear - 2) < 0) {
                allYears.push(currentYear - 2);
            }
            allYears.sort();
            this.setState({allYears: allYears})
        }).catch(err => console.log(err.message))
    }

    getAllPublicDocumentTypes = () => {
        PublicDocumentTypeRepository.getAllTypes().then(res => {
            this.setState({publicDocTypes: res.data})
        }).catch(err => {
            console.log(err)
        })
    }


}

export default PublicDocument
