import React, {useState, useEffect} from "react";
import InstitutionRepository from "../../repository/InstitutionRepository";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {strings} from "../../Localization/Localization";
import Select from "react-select";

const UserAdd = (props) => {
        strings.setLanguage(localStorage.getItem("activeLanguage"));

        const onChangeHandler = (e) => {
            props.onChange(e.target.name, e.target.value);
        };

        const onInfoChangeHandler = (e) => {
            props.onChange(e.target.name, e.target.value);
        };

        const [institutions, setInstitutions] = useState([]);
        const [listInstitutions, setListInstitutions] = useState([]);

        // async function fetchData() {
        //     InstitutionRepository.allActiveInstitutions().then(res => {
        //         setInstitutions(res.data)
        //     }).catch(err => toast.error(strings.failedToLoadData));
        // }

        const getInstitutions = async () => {
            InstitutionRepository.allActiveInstitutions().then(res => {
                setInstitutions(res.data)
                res.data.map(item => {
                    listInstitutions.push({value: item.id, label: item.nameMk, name: "institution_id"})
                })
            }).catch(err => toast.error(strings.failedToLoadData));
        }

        const onSelectedInstitutionHandle = (institution) => {
            if (institution !== null) {
                props.onChange(institution.name, institution.value)
            } else {
                props.onChange("institution_id", null)
            }
        }

        const onFileChangeHandler = (event) => {
            props.onChange(event.target.name, event.target.files[0])
        }

        useEffect(() => {
            getInstitutions();
        }, []);

        const role = localStorage.getItem('role');
        return (
            <div className="col-12">
                <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.email}</label>

                    <input
                        name={"email"}
                        type={"email"}
                        placeholder={"name@example.com"}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
                <br/>

                <div className="col-12">
                    <h5 className="weight400 text-upper control-label control-label-xl margin-top-10">
                        <span style={{color: "red", fontSize: '18px'}} className="mr-1">*</span>
                        <b>{strings.addUserFile}</b>
                    </h5>
                    <input
                        id="attachment"
                        name={"attachment"}
                        type={"file"}
                        className="form-control"
                        onChange={(e) => onFileChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
                <br/>


                <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                    <input
                        name={"alternativeEmail"}
                        type={"email"}
                        placeholder={"name@example.com"}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
                <br/>

                <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                    <input
                        name={"alternativeSecondEmail"}
                        type={"email"}
                        placeholder={"name@example.com"}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
                <br/>
                <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                    <input
                        name={"alternativeThirdEmail"}
                        type={"email"}
                        placeholder={"name@example.com"}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
                <br/>

                <div
                    className="col-12">
                    <label htmlFor={"password"}
                           className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.password}</label>

                    <input
                        id={"password"}
                        autoComplete="disabled"
                        name={"password"}
                        type={"password"}
                        placeholder={strings.password}
                        title={"six or more characters"}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                    <small id="passwordHelpBlock" className="form-text text-muted">
                        {strings.passHint}
                    </small>
                </div>
                <br/>

                <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                        <span style={{color: "red", fontSize: '18px'}} className="mr-1">*</span>
                        {strings.firstName}
                    </label>

                    <input
                        name={'firstName'}
                        type={"text"}
                        placeholder={strings.firstName}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
                <br/>


                <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                        <span style={{color: "red", fontSize: '18px'}} className="mr-1">*</span>
                        {strings.lastName}
                    </label>

                    <input
                        name={"lastName"}
                        type={"text"}
                        placeholder={strings.lastName}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
                <br/>

                <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.firstNameAlbanian}</label>

                    <input
                        name={'firstNameAl'}
                        type={"text"}
                        placeholder={strings.firstNameAlbanian}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
                <br/>

                <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.lastNameAlbanian}</label>

                    <input
                        name={"lastNameAl"}
                        type={"text"}
                        placeholder={strings.lastNameAlbanian}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
                <br/>

                <div className="col-12">
                    <div className="row">
                        <div className="col-9">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.phone}</label>

                            <input
                                name={"phone"}
                                type={"text"}
                                placeholder={strings.phone}
                                className="form-control"
                                onChange={(e) => onChangeHandler(e)}
                            />
                        </div>
                        <div className="col-3">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                            <input
                                name={"locales"}
                                type={"text"}
                                placeholder={""}
                                className="form-control"
                                onChange={(e) => onChangeHandler(e)}
                            />
                        </div>
                    </div>

                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
                <br/>

                <div className="col-12">
                    <div className="row">
                        <div className="col-9">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativePhone}</label>

                            <input
                                name={"alternativePhone"}
                                type={"text"}
                                placeholder={strings.alternativePhone}
                                className="form-control"
                                onChange={(e) => onChangeHandler(e)}
                            />
                        </div>
                        <div className="col-3">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                            <input
                                name={"alternativeLocales"}
                                type={"text"}
                                placeholder={""}
                                className="form-control"
                                onChange={(e) => onChangeHandler(e)}
                            />
                        </div>
                    </div>

                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
                <br/>

                <div className="col-12">
                    <div className="row">
                        <div className="col-9">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeSecondPhone}</label>

                            <input
                                name={"alternativeSecondPhone"}
                                type={"text"}
                                placeholder={strings.alternativeSecondPhone}
                                className="form-control"
                                onChange={(e) => onChangeHandler(e)}
                            />
                        </div>
                        <div className="col-3">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                            <input
                                name={"alternativeSecondLocales"}
                                type={"text"}
                                placeholder={""}
                                className="form-control"
                                onChange={(e) => onChangeHandler(e)}
                            />
                        </div>
                    </div>

                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>

                <br/>

                <div className="col-12">
                    <div className="row">
                        <div className="col-9">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeThirdPhone}</label>

                            <input
                                name={"alternativeThirdPhone"}
                                type={"text"}
                                placeholder={strings.alternativeThirdPhone}
                                className="form-control"
                                onChange={(e) => onChangeHandler(e)}
                            />
                        </div>
                        <div className="col-3">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                            <input
                                name={"alternativeThirdLocales"}
                                type={"text"}
                                placeholder={""}
                                className="form-control"
                                onChange={(e) => onChangeHandler(e)}
                            />
                        </div>
                    </div>

                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>

                <br/>

                {role === "ROLE_ADMIN" ?
                    <div className="col-12">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">
                            <span style={{color: "red", fontSize: '18px'}}
                                  className="mr-1">*</span>{strings.institution}</label>
                        <Select
                            placeholder={strings.institutionPlaceholder}
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            options={listInstitutions}
                            onChange={onSelectedInstitutionHandle}
                            name={"institution_id"}

                        />
                        {/*{props.error &&*/}
                        {/*<div*/}
                        {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                    </div> : <div></div>
                }
                <br/>
                {role === "ROLE_ADMIN" ? <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                        <span style={{color: "red", fontSize: '18px'}} className="mr-1">*</span>{strings.role}</label>

                    <select
                        name={"role"}
                        required
                        className="form-control custom-select"
                        onChange={(e) => onChangeHandler(e)}
                    >
                        <option value="">{strings.rolePlaceholder}</option>
                        <option value="ROLE_ADMIN">{strings.admin}</option>
                        <option value="ROLE_INSTITUTIONAL_MODERATOR">{strings.responsiblePerson}</option>
                        {/*<option value="ROLE_EVALUATOR">{strings.evaluator}</option>*/}

                    </select>
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}

                </div> : null}
                <br/>

                {role === "ROLE_ADMIN" ?
                    <div className="col-12">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">
                            <span style={{color: "red", fontSize: '18px'}}
                                  className="mr-1">*</span>{strings.additionalRole}</label>
                        <select
                            name={"additionalRoleInfo"}
                            required
                            className="form-control custom-select"
                            onChange={(e) => onInfoChangeHandler(e)}
                            style={{marginTop: '-2px'}}
                        >
                            <option value="">{strings.chooseRole}</option>
                            <option value="SIMPLE_ROLE">{strings.simpleRole}</option>
                            <option value="QUESTIONS_ROLE">{strings.questionsRole}</option>
                        </select>
                    </div>
                    : <div/>
                }

            </div>
        );

    }
;

export default UserAdd;
