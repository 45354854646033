import React, {Component} from 'react';
import axios from "../../axios/app";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {strings} from "../../Localization/Localization";

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invalidPass: null,
            psmatch: null,
            password: "",
            newpassword: "",
            repeatPassword: "",
            activeLanguage: localStorage.getItem("activeLanguage"),
        }
    }

    componentDidMount() {
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({})
    }

    handlePassword = (e) => {
        this.setState({password: e.target.value})
    };
    handleNewPassword = (e) => {
        this.setState({newpassword: e.target.value})
    };
    handleRepeatPassword = (e) => {
        this.setState({repeatPassword: e.target.value})
    };
    changePassword = () => {
        if (this.state.newpassword === this.state.repeatPassword) {
            const form = new FormData();

            form.append('oldpassword', this.state.password);
            form.append('newpassword', this.state.newpassword);
            form.append('repeatPassword', this.state.repeatPassword);
            axios.post('/rest/user/changePassword', form).then(response => {
                toast.success(strings.successfullyChangedPassword)
                this.props.history.push("/institutions");
            }).catch(err => {
                this.setState({invalidPass: err.response.data})
                toast.error(strings.failedToChangePassword)
                setTimeout(() => {
                    window.location = '/changePassword';
                }, 4000)
            });
        } else {
            toast.error(strings.failedToChangePassword)
            this.setState({
                psmatch: "Лозинките не се совпаѓаат."
            });
            setTimeout(() => {
                window.location = '/changePassword';
            }, 4000)
        }
    };

    render() {
        return (
            <div>
                <h2 className="mt-5 mb-5 text-center">{strings.changePassword}</h2>
                <div className="container mt-10" style={{width: "700px"}}>
                    <div className='row'>
                        <div className="col-sm-2 col-lg-2 col-md-2 mr-2">
                            <label htmlFor="password" className="mt-3"
                                   style={{fontSize: '15px'}}>{strings.password}: </label>
                        </div>
                        <div className="col-sm-9 col-md-9 col-lg-9 mb-4">
                            <input name="password" id="password" type="password"
                                   className="form-control" onChange={(e) => this.handlePassword(e)} required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-2 col-lg-2 col-md-2 mr-2">
                            <label htmlFor="changepassword" className="mt-1"
                                   style={{fontSize: '15px'}}>{strings.newPassword}: </label>
                        </div>
                        <div className="col-sm-9 col-md-9 col-lg-9 mb-4">
                            <input name="changepassword" id="changepassword" type="password"
                                   className="form-control" onChange={(e) => this.handleNewPassword(e)} required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-2 col-lg-2 col-md-2 mr-2">
                            <label htmlFor="repeatpassword" className="mt-1"
                                   style={{fontSize: '15px'}}>{strings.repeatPassword}: </label>
                        </div>
                        <div className="col-sm-9 col-md-9 col-lg-9 mb-4">
                            <input name="repeatpassword" id="repeatpassword" type="password"
                                   className="form-control" onChange={(e) => this.handleRepeatPassword(e)}
                                   required/>
                        </div>
                        {this.state.psmatch == null ? null : <div style={{width: "700px"}}
                                                                  className="text-center alert alert-danger error-msg">{this.state.psmatch}</div>}
                        {this.state.invalidPass == null ? null : <div style={{width: "700px"}}
                                                                      className="text-center alert alert-danger error-msg">{this.state.invalidPass}</div>}
                    </div>
                    <div className="row">
                        <div className="col-sm-2 col-lg-2 col-md-2 mr-2"/>
                        <div className="col-sm-9 col-md-9 col-lg-9 mt-5">
                            <button className="btn btn-info defaultBtn btn-lg" style={{zIndex: "100"}}
                                    type="submit"
                                    onClick={this.changePassword}>{strings.save}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}

export default ChangePassword