import React, {useEffect, useState} from "react";
import {strings} from "../../Localization/Localization";
import CriteriaRepository from "../../repository/CriteriaRepository";
import Select from "react-select";
import CriteriaTypeRepository from "../../repository/CriteriaTypeRepository";

const CriteriaAdd = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));

    const [criterias, setCriterias] = useState([]);
    const [listCriterias, setListCriterias] = useState([]);
    const [criteriaTypes, setCriteriaTypes] = useState([]);
    const [listCriteriaTypes, setListCriteriaTypes] = useState([]);

    const onChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value);
    }

    const getCriterias = async => {
        CriteriaRepository.getAllCriteria().then(res => {
            setCriterias(res.data)
            res.data.map(item => {
                listCriterias.push({value: item.id, label: item.name, name: "parentCriteria"})
            });
        })
    }

    const getCriteriaTypes = async => {
        CriteriaTypeRepository.findAllCriteriaTypes().then(res => {
            setCriteriaTypes(res.data);
            res.data.map(item => {
                listCriteriaTypes.push({value: item.id, label: item.name, name: "criteriaType"})
            })
        })
    }

    useEffect(() => {
        getCriterias();
        getCriteriaTypes();
    }, [])

    const onSelectedCriteriaChangeHandler = (criteria) => {
        if (criteria !== null) {
            props.onChange(criteria.name, criteria.value)
        } else {
            props.onChange("parentCriteria", null)
        }
    }

    const onSelectedCriteriaTypeChangeHandler = (criteriaType) => {
        if (criteriaType !== null) {
            props.onChange(criteriaType.name, criteriaType.value)
        } else {
            props.onChange("criteriaType", null)
        }
    }

    return (
        <div>
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.name}</label>
                <textarea
                    name={"name"}
                    rows={3}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                />
            </div>
            <br/>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.parentToCriteria}</label>
                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={listCriterias}
                    onChange={onSelectedCriteriaChangeHandler}
                    name={"parentCriteria"}
                />
            </div>

            <br/>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.typesAnswers}</label>
                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={listCriteriaTypes}
                    onChange={onSelectedCriteriaTypeChangeHandler}
                    name={"criteriaType"}
                />
            </div>

        </div>
    )
};

export default CriteriaAdd;