import React, {Component} from "react";
import {strings} from "../../Localization/Localization";
import AnnualReportRepository from "../../repository/AnnualReportRepository";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import {
    faCheck, faDownload, faFileExcel, faFilePdf, faPencilAlt,
    faPlusCircle, faSpinner, faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AddNewAnnualReport from "./AddNewAnnualReport";
import "./css/AnnualReport.css";
import AnnualReportSignedFileUploadModal from "./AnnualReaportSignedFileUploadModal";
import {toast} from "react-toastify";
import EditAnnualReport from "./EditAnnualReport";
import InfoPopup from "../ErrorHandler/InfoPopup";
import {LOCALHOST} from "../../shared/utility";
import ReactPaginate from "react-paginate";
import {FormControl} from "react-bootstrap";

class AnnualReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedYear: moment().format('MM') === '01' ? moment().subtract(1, 'years').format('YYYY') : moment().format('YYYY'),
            reports: [],
            showAddModal: false,
            propsData: {},
            editModalVisible: false,
            activeLanguage: localStorage.getItem("activeLanguage"),
            isWaiting: false, // loading bar,
            lengthReports: 0,
            isAdmin: localStorage.getItem('role') === "ROLE_ADMIN",
            isUser: localStorage.getItem('role') === "ROLE_INSTITUTIONAL_MODERATOR",
            keyword: "",
            pageCount: "",
            files: [],
        }
    }

    componentDidMount() {
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
        this.fetchData();
        debugger
    }

    fetchData = (selectedPage = 0) => {
        if (localStorage.getItem("role") === "ROLE_ADMIN") {
            AnnualReportRepository.getAllByYearAndKeywordPaginated(this.state.selectedYear, this.state.keyword, selectedPage).then(async res => {
                let files = []
                for (let i = 0; i < res.data.content.length; i++) {
                    if (res.data.content[i].signedDoc) {
                        files.push(res.data.content[i].signedDoc.id)
                    }
                }
                await this.setState({
                    files: files,
                    reports: res.data.content,
                    pageCount: res.data.totalPages,
                    lengthReports: res.data.content.length,
                })
            })
        } else {
            AnnualReportRepository.getByYearAndInstitution(localStorage.getItem('institutionId'), this.state.selectedYear)
                .then(async res => {
                    await this.setState({
                        reports: res.data,
                        lengthReports: res.data.length,
                    })
                })
        }

    }

    async handleDateChange(e) {
        if (this.state.isAdmin) {
            document.getElementById("keywordId").value = ""
        }
        await this.setState({
            selectedYear: moment(e._d).format('YYYY'),
            keyword: "",
        })
        await this.fetchData();
    }

    showAddModal = () => {
        this.setState({
            showAddModal: true
        })
    }

    closeAddModal = () => {
        this.setState({
            showAddModal: false
        })
    }

    openEditModal = (rowData) => {
        AnnualReportRepository.getAllLinksByReport(rowData.id).then(res => {
            rowData['links'] = res.data
            this.setState({
                propsData: rowData,
                editModalVisible: true
            })
        })
    }

    closeEditModal = () => {
        this.setState({
            editModalVisible: false
        })
    }

    submitAnnualReport = (pyrId) => {
        this.setState({
            isWaiting: true
        })
        AnnualReportRepository.submitAnnualReport(pyrId.id).then(res => {
            this.setState({
                isWaiting: false
            })
            this.fetchData();
        }).catch(() => {
            this.setState({
                isWaiting: false
            })
            toast.error(strings.failedToSubmitReport)
        })
    }

    handlePageClick = data => {
        this.fetchData(data.selected);
    };

    handleSearch = (e) => {
        this.setState(
            {
                keyword: e.target.value
            }
            , () => {
                this.fetchData();
            })
    }


    render() {
        return (
            <div>
                {!this.state.isWaiting &&
                <div>
                    <div className="row mt-3">
                        <div className="col-12 text-center">
                            <h1 className="currentYearHeading">{this.state.selectedYear}</h1>
                        </div>
                    </div>

                    <div className="row mt-3">

                        <div className="col-6 mb-2 YearPickerCol">
                            <div className="row">
                                <div className="col-6">
                                    <label style={{
                                        fontSize: '17px',
                                        fontWeight: 'bold'
                                    }}>{strings.reportsForYear}: </label>
                                    <Datetime
                                        dateFormat="YYYY"
                                        timeFormat={false}
                                        value={this.state.selectedYear}
                                        onChange={(e) => this.handleDateChange(e)}
                                    />
                                </div>
                                {this.state.isAdmin &&
                                <div className="col-6">
                                    <label
                                        style={{fontSize: '17px', fontWeight: 'bold'}}>{strings.institution}: </label>
                                    <FormControl
                                        value={this.state.keyword}
                                        id="keywordId"
                                        disabled={false}
                                        placeholder={strings.institution}
                                        onChange={this.handleSearch}
                                        aria-label="Search"
                                        aria-describedby="basic-addon2"
                                    />
                                </div>
                                }
                            </div>


                        </div>
                        <div className="col-6 text-right mt-5">
                            {/*<h2 className="currentYearHeading">{this.state.selectedYear}</h2>*/}
                            {this.state.isAdmin && this.state.lengthReports > 0 &&
                            // <button onClick={this.generateZipFiles} type="button"
                            //         className="btn defaultBtnEdit">
                            //     <FontAwesomeIcon icon={faDownload} size="lg" style={{paddingRight: '4px'}}/>
                            //     Генерирај архива
                            // </button>
                            <a href={LOCALHOST + `rest/yearlyreport/sendZipFiles/${this.state.files}`}
                               target="_blank"
                               className="btn defaultBtnGenerateArchive">
                                <FontAwesomeIcon icon={faDownload} size="lg" style={{paddingRight: '4px'}}/>
                                Генерирај архива
                            </a>
                            }

                            {this.state.isAdmin ?
                                <button onClick={this.showAddModal} type="button"
                                        className="btn btn-primary defaultBtnAdd ml-2" data-toggle="modal"
                                        data-target=".AddNewAnnualReportModal">
                                    <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{paddingRight: '4px'}}/>
                                    {strings.add}
                                </button>
                                :
                                <button onClick={this.showAddModal} type="button"
                                        className="btn btn-info defaultBtn" data-toggle="modal"
                                        data-target=".AddNewAnnualReportModal">
                                    <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{paddingRight: '4px'}}/>
                                    {strings.add}
                                </button>
                            }
                        </div>
                    </div>

                    {this.state.lengthReports > 0 ?
                        <div>
                            <table className="table-hover newTable mt-2" style={{width: "100%"}}>
                                <thead className="tableHead">
                                <th className="tableHeading firstHeading"
                                    style={{width: '20%'}}>{strings.institutionName}</th>
                                <th className="tableHeading" style={{width: '20%'}}>{strings.submitter}</th>
                                <th className="tableHeading" style={{width: '20%'}}>{strings.status}</th>
                                <th className="tableHeading" style={{width: '20%'}}>{strings.form}</th>
                                <th className="tableHeading" style={{width: '10%'}}>{strings.file}</th>
                                <th className="tableHeading lastHeading"
                                    style={{width: '10%'}}>{strings.signedDocument}</th>
                                </thead>
                                <tbody>
                                {this.state.reports.map(this.renderReports)}


                                <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                                    <td style={{borderRadius: "0 0 0 15px"}}/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td style={{borderRadius: "0 0 15px 0"}}/>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="mt-5" style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <InfoPopup class infoMessage={strings.noAnnualReportsFoundForThisYear}/>
                        </div>
                    }


                    <div className="row mt-3">
                        {this.state.reports.length > 0 ?
                            <div className="col-12 text-right">
                                <a href={LOCALHOST + `rest/yearlyreport/exportToXlsx?year=${this.state.selectedYear}`}
                                   className="btn btn-dark defaultBtn" style={{cursor: "pointer"}}> <FontAwesomeIcon
                                    icon={faFileExcel} size="lg" className="mr-2"/>{strings.report}</a>
                            </div>
                            :
                            <div className="col-12 text-right">
                                {/*<a className="btn btn-dark disabled defaultBtn" style={{cursor: "pointer"}}>*/}
                                {/*    <FontAwesomeIcon*/}
                                {/*        icon={faFileExcel} size="lg" className="mr-2"/>{strings.report}</a>*/}
                            </div>
                        }
                    </div>

                    {this.state.showAddModal && <AddNewAnnualReport closeAddModal={this.closeAddModal}/>}

                    {this.state.editModalVisible &&
                    <EditAnnualReport data={this.state.propsData} closeEditModal={this.closeEditModal}/>}
                </div>
                }
                {this.state.isWaiting &&
                <div className="container-fluid loadingContainer">
                    <div className="spinner-grow text-secondary" style={{height: "50px", width: "50px"}}
                         role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-secondary" style={{height: "50px", width: "50px"}}
                         role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-secondary" style={{height: "50px", width: "50px"}}
                         role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                }
                {this.state.isAdmin && this.state.lengthReports > 0 &&
                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
                }
            </div>
        )


    }

    renderReports = (annualReportDataRow) => {
        if (this.state.lengthReports > 0) {
            let status;
            if (annualReportDataRow.status === "SUBMITTED") {
                status =
                    <p className="annualReportStatusSubmitted">
                        <FontAwesomeIcon icon={faCheck} size="lg" className="mr-1"/>{strings.submitted}
                    </p>
            } else {
                status =
                    <p className="annualReportStatusInProgress">
                        <FontAwesomeIcon icon={faSpinner} size="lg" className="mr-1"/>{strings.inProgress}
                    </p>
            }

            return (
                <tr key={annualReportDataRow.id} style={{border: "1px solid lightgray"}}>
                    <td className="tableData">
                        {this.state.activeLanguage === "mk" && annualReportDataRow.institution.nameMk}
                        {this.state.activeLanguage === "al" && annualReportDataRow.institution.nameAl}
                    </td>
                    <td className="tableData">{annualReportDataRow.report.podatociSluzbLice}</td>
                    <td className="tableData">{status}</td>
                    <td onClick={() => this.openEditModal(annualReportDataRow)} className="tableData newBtnClass"
                        data-toggle="modal" data-target=".EditAnnualReportModal">
                        <FontAwesomeIcon icon={faPencilAlt} className="mr-1"/>{strings.review}
                    </td>
                    {annualReportDataRow.status === "SAVED" &&
                    <td onClick={() => this.submitAnnualReport(annualReportDataRow)} className="tableData newBtnClass"
                        data-toggle="modal" data-target=".EditAnnualReportModal">
                        <FontAwesomeIcon icon={faThumbsUp} className="mr-1"/>{strings.submit}
                    </td>}

                    <td className="tableData">
                        {annualReportDataRow.status === "SUBMITTED" &&
                        <a href={LOCALHOST + `rest/attachment/download/` + annualReportDataRow.doc?.id}
                           target="_blank" rel="noopener noreferrer" className="AnnualReportTableRowCheck">
                            <FontAwesomeIcon icon={faFilePdf} size="lg" className="mr-1"/>{strings.print}
                        </a>
                        }
                    </td>
                    {annualReportDataRow.status === "SUBMITTED" ?
                        annualReportDataRow.signedDoc === null ?
                            <td className="tableData">
                                <AnnualReportSignedFileUploadModal reportID={annualReportDataRow.id}
                                                                   reportSignedDocInfo={annualReportDataRow.signedDoc}/>
                            </td>
                            :
                            <td className="tableData">
                                <a style={{color: "red"}}
                                   href={LOCALHOST + "rest/attachment/download/" + annualReportDataRow.signedDoc.id}
                                   target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faFilePdf} color="red" size="lg" className="mt-2"
                                                     style={{paddingRight: '4px'}}/>
                                    {annualReportDataRow.signedDoc.name}
                                </a>
                            </td>
                        : <div/>
                    }
                </tr>
            )
        }
    }

}

export default AnnualReport;
