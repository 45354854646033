import React, {Component} from "react";
import {Link} from "react-router-dom";
import FeedbackPublicationRepository from "../../repository/FeedbackPublicationRepository";
import ReactPaginate from "react-paginate";
import {strings} from "../../Localization/Localization";
import InfoPopup from "../ErrorHandler/InfoPopup";
import "./css/MyFeedbackPublications.css";
import {
    faCalendarAlt,
    faCalendarPlus,
    faChevronRight,
    faSearch,
    faUniversity,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";

class MyFeedbackPublications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedbackpubs: [],
            pageCount: 1,
            numOfElements: 0,
            activeLanguage:localStorage.getItem("activeLanguage")

        }
    }

    componentDidMount() {
        this.fetchData();
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
    }

    fetchData = (selectedPage = 0) => {
        let institutionId = localStorage.getItem('institutionId');
        FeedbackPublicationRepository.getFeedbackPublicationsForInstitution(institutionId, selectedPage).then(res => {
            this.setState({
                numOfElements: res.data.numberOfElements,
                feedbackpubs: res.data.content,
                pageCount: res.data.totalPages
            })
        }).catch(err => {
            console.log(err)
        })
    }

    handlePageClick = data => {
        this.fetchData(data.selected);
    };

    renderFeedbackPub = feedpub => {
        let button = feedpub.finished ?
            (<Link to={"/visualizeAnswers/" + feedpub.id}
                   className="btn myFeedbackPublicationsButton mb-2"
                   style={{color: 'white', background: '#7495b2', border: '1px solid #584b4b'}}>
                <FontAwesomeIcon icon={faSearch} size="lg"
                                 style={{paddingRight: '4px'}}
                />
                {strings.previewAnswer}
            </Link>) :
            (<Link to={"/visualizeFeedback/" + feedpub.id}
                   className="btn myFeedbackPublicationsButton mb-2"
                   style={{color: 'black', background: '#e8e6e6'}}>
                <FontAwesomeIcon icon={faChevronRight} size="lg"
                                 style={{paddingRight: '4px'}}
                />
                {strings.answer}
            </Link>);
        return (
            <div className="row mt-4">
                <div className="col-12 myFeedbackPublicationsContainer">

                    <div className="row w-100">
                        <div className="col-6 mb-2 myFeedbackPublicationsPublicationTitleCol">
                            <h3>{feedpub.feedback.name}</h3>
                        </div>
                        <div className="col-6 mb-2">
                            {button}
                        </div>
                    </div>
                    <div className="row myFeedbackPublicationsRowFooter">
                        <div className="col-12 mt-1 myFeedbackPublicationsColFooter">
                            <h6 className="myFeedbackPublicationsFooterText mr-3">
                                <FontAwesomeIcon icon={faUser} size="lg" className="mr-1"/>
                                {feedpub.feedback.creator.firstName + " " + feedpub.feedback.creator.lastName}
                            </h6>
                            <h6 className="myFeedbackPublicationsFooterText mr-3">
                                <FontAwesomeIcon icon={faUniversity} size="lg" className="mr-1"/>
                                {this.state.activeLanguage==='mk' &&
                                feedpub.institution.nameMk}
                                {this.state.activeLanguage==='al' &&
                                feedpub.institution.nameAl}
                            </h6>
                            <h6 className="myFeedbackPublicationsFooterText">
                                <FontAwesomeIcon icon={faCalendarPlus} size="lg" className="mr-2"/>
                                {feedpub.dateCreated}
                            </h6>
                            <h6 className="myFeedbackPublicationsFooterText myFeedbackPublicationsFooterRightText">
                                {strings.dueDate+":   "}<FontAwesomeIcon icon={faCalendarAlt} size="lg" className="mr-2"/>
                                {moment(feedpub.feedback.dueDate).local().format('DD-MM-YYYY')}
                            </h6>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    render() {
        if (this.state.numOfElements !== 0) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 style={{color: "#1C4857"}}
                                className="mt-4 text-center">{strings.myfeedbackpublications}</h1>
                        </div>
                    </div>
                    {this.state.feedbackpubs.map(this.renderFeedbackPub)}
                    <div className="row">

                        <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            breakLabel={'...'}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                    <InfoPopup infoMessage={strings.noPublishedFeedbacks}/>
                </div>
            )
        }

    }

}

export default MyFeedbackPublications;