import {strings} from "../../Localization/Localization";
import React from "react";

const CriteriaTypeOptionAdd = (props) => {

    const onChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value)
    }

    return (
        <div className="col-12">
            <div className="row">

                <div className="col-6">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.name}</label>
                    <input
                        name={"name"}
                        type={"text"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"
                    />
                </div>

                <div className="col-6">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.valueOption}</label>
                    <input
                        name={"value"}
                        type={"number"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"
                    />
                </div>

            </div>
        </div>
    )
}
export default CriteriaTypeOptionAdd;