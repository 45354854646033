import React, {Component} from "react";
import FullReportRepository from "../../repository/FullReportRepository";
import {strings} from "../../Localization/Localization";
import {Link} from "react-router-dom";
import ReactPaginate from "react-paginate";
import InfoPopup from "../ErrorHandler/InfoPopup";

class ListOfReportsForEvaluation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeLanguage: localStorage.getItem("activeLanguage"),
            allReports: [],
            pageCount: 1,
            userEmail: localStorage.getItem('email'),
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = (selectedPage = 0) => {
        FullReportRepository.findAllForEvaluation(selectedPage).then(res => {
            this.setState({
                allReports: res.data.content,
                pageCount: res.data.totalPages,
            })
        }).catch(err => {
            console.log(err);
        })
    }

    handlePageClick = data => {
        this.fetchData(data.selected);
    };

    render() {
        console.log("ap", this.state.allReports)
        return (
            <div className="container">
                <h1 className="align-items-center justify-content-center"
                    style={{textAlign: 'center', color: "#1C4857"}}>{strings.reportsForEvalation}</h1>
                <div className="row mt-5" style={{paddingTop: '15px'}}>
                    <Link to="/ListOfReports" className="btn btn-dark mt-4 defaultBtn"><i
                        className="fa fa-history"/> {strings.myReports}</Link>
                    <br/>
                </div>

                {this.state.allReports.length !== 0 ?
                    this.state.allReports.map((item, index) => {
                        return (
                            <div>
                                {this.state.userEmail !== item.reportCreator.email &&
                                <div key={index} className="row ReportMainDiv">
                                    <div className="col-12">

                                        <div className="row ReportRow">
                                            <div className="col-12">
                                                {this.state.activeLanguage === 'mk' &&
                                                <a href={"/fullReport/" + item.id} className="mb-0" style={{
                                                    fontSize: '22px',
                                                    fontWeight: '600',
                                                    color: 'black'
                                                }}>{item.name}</a>
                                                }

                                                {this.state.activeLanguage === 'al' &&
                                                <a href={"/fullReport/" + item.id} className="mb-0" style={{
                                                    fontSize: '22px',
                                                    fontWeight: '600',
                                                    color: 'black'
                                                }}>{item.name}</a>
                                                }
                                            </div>
                                        </div>

                                        <div className="container-fluid ReportPublicationDivContainer">

                                            <div className="row ReportAnnouncementBodyRow">
                                                <div className="col-12" style={{textAlign: 'center'}}>
                                                    <a style={{
                                                        display: "inline-block",
                                                        textAlign: 'center',
                                                        fontSize: '20px'
                                                    }}>
                                                        <Link
                                                            to={"/evaluationOfFullReport/" + item.id}
                                                            className="announcementLinkButton">{strings.detailsForReport}</Link>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-12 ReportAnnouncementFooterCol">
                                                <h6 style={{float: 'left', color: '#343a40'}}>
                                                    <h6>{strings.authorCreator} {this.state.allReports[index].reportCreator.email}</h6>
                                                    <h6>{strings.institution}: {this.state.allReports[index].reportCreator.institution !== null ? this.state.allReports[index].reportCreator.institution.nameMk : "/"}</h6>
                                                    <span>{strings.yearCreate}: {this.state.allReports[index].yearOfCreation}</span>
                                                </h6>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        )
                    }) : <div style={{display: 'flex', alignItems: 'center', height: '100%'}} className="mt-3">
                        <InfoPopup infoMessage={strings.noReports}/>
                    </div>
                }

                {this.state.allReports.length !== 0 ?
                    <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    /> : <div></div>
                }
            </div>
        )
    }
}

export default ListOfReportsForEvaluation;