import React, {Component} from "react";


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {year: new Date().getFullYear()}
    }

    render() {
        return (
            <footer className="page-footer">

                <div className="footer-copyright text-center py-3">© 2020 Copyright&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href="https://www.aspi.mk/"
                       style={{color: "white"}}><strong>ASPI</strong></a>
                </div>

            </footer>
        );
    }

}

export default Footer;