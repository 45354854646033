import React, {Component} from 'react';
import AnnouncementRepository from "../../repository/AnnouncementRepository";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {strings} from "../../Localization/Localization";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class AddAnnouncement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            body: '',
            file: null,
            fileName: '',
        }
    }

    componentDidMount() {
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({fileName: strings.uploadYourFile});
    }

    handleTitleChange = (event) => {
        this.setState({title: event.target.value})
    };


    handleBodyChange = (event) => {
        this.setState({body: event.target.value})
    };

    saveAnnouncement = (e) => {
        e.preventDefault();
        const form = new FormData();
        form.set('title', this.state.title);
        form.set('body', this.state.body);
        form.set('attachment', this.state.file)
        if (this.state.title !== "" && this.state.body !== "") {
            AnnouncementRepository.addAnnouncement(form).then(res => {
                this.props.history.push("/allannouncements")
                toast.success(strings.successfullyAddedAnnouncement);
            }).catch(err => {
                toast.error(strings.failedToAddAnnouncement);
            });
        }
    };

    fileOnChange = (e) => {
        this.displaySelectedFileName(e);
        this.handleAttachmentChange(e);
    }

    displaySelectedFileName = (e) => {
        let files = e.target.files
        this.setState({
            fileName: files[0].name
        })
    }

    handleAttachmentChange = (event) => {
        this.setState({file: event.target.files[0]})
    };

    render() {
        return (
            <div>
                <h1 className="text-center mt-5" style={{color: "#1C4857"}}>{strings.createAnnouncement}</h1>
                <div className="container mt-5">
                    <form onSubmit={this.saveAnnouncement}>
                        <div className="form-group">
                            <label htmlFor="title"><span style={{color: "red", fontSize: '18px'}}
                                                         className="mr-1">*</span>{strings.title}</label><input
                            type="text" id="title"
                            className="form-control"
                            required
                            onChange={this.handleTitleChange}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="body"><span style={{color: "red", fontSize: '18px'}}
                                                        className="mr-1">*</span>{strings.body}</label><textarea
                            className="form-control" type="text"
                            id="body"
                            required
                            onChange={this.handleBodyChange}/>
                        </div>
                        <div className="form-group">
                            <div className="custom-file">
                                <label htmlFor="attachment" className="custom-file-label">{this.state.fileName}</label>
                                <input className="custom-file-input"
                                       type="file"
                                       id="attachment"
                                       style={{backgroundColor: 'gray', color: 'black', fontWeight: 'bold'}}
                                       onChange={this.fileOnChange}
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-success mt-5 defaultBtnAdd"><FontAwesomeIcon
                            icon={faEdit}
                            size="lg"
                            style={{paddingRight: '4px'}}/>{strings.rememberme}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default AddAnnouncement;