import React, {Component} from "react";
import "./css/FullReport.css";
import FullReportRepository from "../../repository/FullReportRepository";
import CriteriaRepository from "../../repository/CriteriaRepository";
import {strings} from "../../Localization/Localization";
import CrudModal from "../Crud/CrudModal";
import CriteriaEdit from "../Criteria/CriteriaEdit";
import {toast} from "react-toastify";
import CriteriaAdd from "../Criteria/CriteriaAdd";
import Select from "react-select";
import UnDeleteModal from "../Crud/UnDeleteModal";
import CreateReportElement from "../ReportElement/CreateReportElement";
import {Modal} from "@material-ui/core";
import {
    faAngleDoubleRight,
    faCheck,
    faDownload,
    faFilePdf,
    faSearch,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReportElementRepository from "../../repository/ReportElementRepository";
import InstitutionRepository from "../../repository/InstitutionRepository";
import InstitutionCategoryRepository from "../../repository/InstitutionCategoryRepository";
import PublicDocumentsRegistryRepository from "../../repository/PublicDocumentsRegistryRepository";
import ReactPaginate from "react-paginate";
import StartEvaluation from "./HelperModals/StartEvaluation";
import InactivateModal from "./HelperModals/InactivateModal";
import ShareEvaluatedReport from "./HelperModals/ShareEvaluatedReport";
import CriteriaTypeRepository from "../../repository/CriteriaTypeRepository";
import {LOCALHOST} from "../../shared/utility";

class FullReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            criterias: [],
            criteria: "",
            optionsInstitutions: [],
            institution: {},
            institutionName: null,
            institutionId: null,
            listCriterias: [],
            currentCriteriaId: 0,
            showCreateModal: false,
            modalBody: null,
            idFullReport: this.props.match.params.id,
            criteriaName: null,
            counter: 0,
            reportName: null,
            numberOfCriterias: 0,
            listOfInstitutions: [],
            allScores: [],
            allActiveInstitutions: 0,
            allCategories: [],
            categoryId: 0,
            categoryName: null,
            category: {},
            setListOfDocuments: false,
            publicDocuments: [],
            pageCount: 1,
            criteriaTypeId: null,
            options: [],
            listOptions: []
        }
    }

    getFullReport = () => {
        let id = this.props.match.params.id;
        FullReportRepository.findFullReport(id).then(res => {
            this.setState({
                data: res.data,
                reportName: res.data.name
            })
        }).catch(err => {
            console.log(err);
        })
    }

    componentDidMount() {
        this.fetchData();
    }

    handleAfterEvaluation = async () => {
        if (this.props.history.location.state) {
            if (this.props.history.location.state.institution.label.includes("Не е евалуирано")) {
                await this.setState({
                    categoryId: this.props.history.location.state.category.value,
                    categoryName: this.props.history.location.state.category.label,
                    institutionId: this.props.history.location.state.institution.value,
                    institutionName: this.props.history.location.state.institution.label.substring(0, this.props.history.location.state.institution.label.indexOf("(Не е евалуирано")),
                    listOfInstitutions: this.props.history.location.state.listOfInstitutions,
                    optionsInstitutions: [],
                    category: this.props.history.location.state.category,
                    institution: this.props.history.location.state.institution,
                })
            } else {
                await this.setState({
                    categoryId: this.props.history.location.state.category.value,
                    categoryName: this.props.history.location.state.category.label,
                    institutionId: this.props.history.location.state.institution.value,
                    institutionName: this.props.history.location.state.institution.label,
                    listOfInstitutions: this.props.history.location.state.listOfInstitutions,
                    optionsInstitutions: [],
                    category: this.props.history.location.state.category,
                    institution: this.props.history.location.state.institution,
                })
            }
            await this.getRefreshedInstitutions(this.props.history.location.state.category.value)
        }
    }

    fetchData() {
        this.getFullReport();
        this.getAllCriterias();
        // this.getAllInstitutions();
        this.countCriterias();
        this.getListOfInstitutions();
        // this.getAllInstitutionsListForReportNotEvaluated();
        this.getInstitutionsFromInstitutionRepository();
        this.getAllCategories();
        this.handleAfterEvaluation();
        // this.handleInstitutionsWithoutCategory();
    }

    countCriterias = () => {
        CriteriaRepository.countCriterias().then(res => {
            this.setState({
                numberOfCriterias: res.data
            })
        })
    }

    getAllCategories = () => {
        InstitutionCategoryRepository.getAllInstitutionCategoryList().then(res => {
            let allCategories = []
            for (let i = 0; i < res.data.length; i++) {
                allCategories.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk
                })
            }

            this.setState({
                allCategories: allCategories
            })
        }).catch(err => {
            console.log(err);
        })
    }

    getInstitutionsFromInstitutionRepository = () => {
        InstitutionRepository.allActiveInstitutions().then(res => {
            this.setState({
                allActiveInstitutions: res.data.length
            })
        })
    }

    getListOfInstitutions = async (categoryId, selectedPage = 0) => {
        await FullReportRepository.getInstitutionsForReportPaged(this.state.idFullReport, categoryId, selectedPage).then(res => {
            this.setState({
                listOfInstitutions: res.data.content,
                pageCount: res.data.totalPages
            })
        })
    }

    handlePageClick = data => {
        this.getListOfInstitutions(this.state.categoryId, data.selected);
    }

    getAllCriterias = () => {
        CriteriaRepository.getAllCriteria().then(async (res) => {
            if (this.state.institutionId) {
                if (res.data.length > 0) {
                    let data = res.data;
                    let counter = 0;
                    for (var i = 0; i < data.length; i++) {
                        let status = null;
                        await ReportElementRepository.checkEvaluated(this.state.institutionId, data[i].id, this.state.idFullReport).then(snapshot => {
                            if (snapshot.data) {
                                status = snapshot.data;
                                counter++;
                            }
                        });
                        data[i]["statusChecked"] = status;
                    }
                    this.setState({
                        criterias: data,
                        counter: counter,
                    })
                }
            } else {
                this.setState({
                    criterias: res.data,
                })
            }
        })
    }

    getAllInstitutionsListForReportNotEvaluated = async (categoryId) => {
        await FullReportRepository.getAllInstitutionsListForReport(this.state.idFullReport, categoryId).then(res => {
            let optionsInstitutions = [...this.state.optionsInstitutions]
            for (let i = 0; i < res.data.length; i++) {
                optionsInstitutions.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk + ' (Не е евалуирано)',
                })
            }
            this.setState({
                optionsInstitutions: optionsInstitutions
            })

        }).catch(err => {
            toast.error(strings.failTryAgain);
            console.log(err);
        })
    }

    getAllInstitutions = async (categoryId) => {
        await FullReportRepository.getInstitutionsForReport(this.state.idFullReport, categoryId).then(res => {
            let optionsInstitutions = [...this.state.optionsInstitutions]
            for (let i = 0; i < res.data.length; i++) {
                optionsInstitutions.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk,
                })
            }
            this.setState({
                optionsInstitutions: optionsInstitutions
            })

        }).catch(err => {
            toast.error(strings.failTryAgain);
            console.log(err);
        })
    }


    getAllInstitutionsWithoutCategoryNotEvaluated = () => {
        FullReportRepository.getAllInstitutionsWithoutCategoryNotEvaluated(this.state.idFullReport).then(res => {
            let optionsInstitutions = [...this.state.optionsInstitutions]
            for (let i = 0; i < res.data.length; i++) {
                optionsInstitutions.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk + ' (Не е евалуирано)',
                })
            }
            this.setState({
                optionsInstitutions: optionsInstitutions
            })

        }).catch(err => {
            toast.error(strings.failTryAgain);
            console.log(err);
        })
    }

    getAllInstitutionsWithoutCategoryEvaluated = () => {
        FullReportRepository.getInstitutionsWithoutCategoryEvaluated(this.state.idFullReport).then(res => {
            let optionsInstitutions = [...this.state.optionsInstitutions]
            for (let i = 0; i < res.data.length; i++) {
                optionsInstitutions.push({
                    'value': res.data[i].id,
                    'label': res.data[i].nameMk,
                })
            }
            this.setState({
                optionsInstitutions: optionsInstitutions
            })

        }).catch(err => {
            toast.error(strings.failTryAgain);
            console.log(err);
        })
    }


    onSelectedInstitutionHandler = (value, action) => {
        if (value !== null) {
            this.setState({
                institutionName: value.label,
                institutionId: value.value,
                institution: value,
                setListOfDocuments: false,
            })
            this.getAllCriterias();
        } else {
            this.setState({
                institutionName: null,
                institutionId: null,
            })
        }
    }

    onSelectedCategoryHandler = (value, action) => {
        const categoryId = value.value;
        if (value !== null) {
            this.setState({
                categoryName: value.label,
                categoryId: value.value,
                optionsInstitutions: [],
                institution: {},
                institutionName: null,
                institutionId: null,
                category: value,
                setListOfDocuments: false,
            })
        } else {
            this.setState({
                categoryName: null,
                categoryId: null,
                optionsInstitutions: [],
            })
        }
        this.getAllInstitutions(categoryId);
        this.getAllInstitutionsListForReportNotEvaluated(categoryId);
        this.getListOfInstitutions(categoryId);
    }

    onEdit = (entity) => {

        if (entity.parentCriteria === null) {
            entity["parentCriteria"] = null
        } else if (entity.parentCriteria.id > 0) {
            entity["parentCriteria"] = entity.parentCriteria.id
        } else {
            entity["parentCriteria"] = entity.parentCriteria
        }

        if (entity.criteriaType === null) {
            entity["criteriaType"] = null
        } else if (entity.criteriaType.id > 0) {
            entity["criteriaType"] = entity.criteriaType.id
        } else {
            entity["criteriaType"] = entity.criteriaType
        }

        // if (entity.criteriaType !== null && entity.criteriaType !== undefined) {
        //     if (entity.criteriaType > 0 && entity.criteriaType < 99999999) {
        //         entity["criteriaType"] = entity.criteriaType;
        //     } else {
        //         entity["criteriaType"] = entity.criteriaType.id;
        //     }
        // }

        return CriteriaRepository.updateCriteria(entity).then(async () => {
            toast.success(strings.succChanged);
            await this.sleep(4000);
            window.location.reload();
            this.getFullReport();
            this.getAllCriterias();
            this.countCriterias();
            this.getListOfInstitutions();
        }).catch(err => {
            toast.error(strings.failTryAgain);
            console.log(err);
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    onAdd = (entity) => {
        return CriteriaRepository.createCriteria(entity).then(res => {
            toast.success(strings.succAdded);
            this.getFullReport();
            this.getAllCriterias();
            this.countCriterias();
            this.getListOfInstitutions();
        }).catch(err => {
            toast.error(strings.failTryAgain);
            console.log(err);
        })
    }


    deleteCriteria = (criteriaId) => {
        return CriteriaRepository.deleteCriteria(criteriaId).then(res => {
            toast.success(strings.succDeletedReport)
            this.getFullReport();
            this.getAllCriterias();
            this.countCriterias();
            this.getListOfInstitutions();
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    unDeleteCriteria = (criteriaId) => {
        return CriteriaRepository.unDeleteCriteria(criteriaId).then(res => {
            toast.success(strings.succReturned);
            this.getFullReport();
            this.getAllCriterias();
            this.countCriterias();
            this.getListOfInstitutions();
        }).catch(err => {
            toast.error(strings.failTryAgain);
        })
    }

    openModal = async (e) => {
        const criteria = e.target.id;
        const criteriaName = e.target.name;

        await CriteriaRepository.findCriteria(criteria).then(async res => {

            const criteriaTypeId = res.data.criteriaType.id;
            await CriteriaTypeRepository.getOptionsForCriteriaType(criteriaTypeId).then((opt) => {
                this.setState({
                    options: opt.data
                })
            })
        })

        let listOptions = []
        for (let i = 0; i < this.state.options.length; i++) {
            listOptions.push({value: this.state.options[i].value, label: this.state.options[i].name})
        }

        this.setState({
            listOptions: listOptions
        })


        this.setState({
            modalBody: (
                <CreateReportElement criteria={criteria} criteriaName={criteriaName}
                                     institution={this.state.institution} institutionId={this.state.institutionId}
                                     institutionName={this.state.institutionName}
                                     idFullReport={this.state.idFullReport}
                                     category={this.state.category}
                                     listOfInstitutions={this.state.listOfInstitutions}
                                     optionsInstitutions={this.state.optionsInstitutions}
                                     listOptions={this.state.listOptions}
                />
            )
        })
        this.setState({showCreateModal: true});
    }

    closeModal = () => {
        this.setState({
            showCreateModal: false
        });
    }

    saveReport = () => {
        FullReportRepository.reviewAndSaveReport(this.state.idFullReport).then(res => {
            // toast.success(strings.sendEvaluation)
            window.location = '/ListOfReports'
        }).catch(err => {
            toast.error(strings.failTryAgain)
        })
    }

    startEvaluation = () => {
        return FullReportRepository.startReportEvaluation(this.state.idFullReport).then(res => {
            window.location = '/fullReport/' + this.state.idFullReport
        }).catch(err => {
            toast.error(strings.failTryAgain)
        })
    }

    // getScoresForInstitutions = () => {
    //     FullReportRepository.getInstitutionsForReport(this.state.idFullReport).then(res => {
    //         for (let i = 0; i < res.data.length; i++) {
    //             FullReportRepository.getScoresForInstitutions(this.state.idFullReport, res.data[i].id).then(snapshot => {
    //                 const finalSnap = snapshot.data.filter(snap=>snap.score!==0);
    //
    //                     this.setState({
    //                         allScores: [...this.state.allScores,finalSnap[0]]
    //                     })
    //
    //             })
    //         }
    //     })
    // }

    handleInstitutionsWithoutCategory = () => {
        if (this.state.categoryName === null) {
            this.getAllInstitutionsWithoutCategoryEvaluated();
            this.getAllInstitutionsWithoutCategoryNotEvaluated();
        }
    }

    getRefreshedInstitutions = (categoryId) => {
        if (categoryId !== null && categoryId !== undefined && categoryId !== 0) {
            this.getAllInstitutions(categoryId);
            this.getAllInstitutionsListForReportNotEvaluated(categoryId);
            this.getListOfInstitutions(categoryId);
        }
    }

    getHelp = (criteriaId) => {
        // const criteria = e.target.id;

        PublicDocumentsRegistryRepository.getPubDocsForEvaluation(criteriaId, this.state.institutionId).then(res => {
            if (res.data.length > 0) {
                this.setState({
                    setListOfDocuments: true,
                    publicDocuments: res.data
                })
            } else {
                this.setState({
                    setListOfDocuments: false,
                })
            }
        }).catch(err => {
            console.log(err);
        })
    }

    sortCriteria = () => {
        this.state.criterias.sort((a, b) => (a.name < b.name) ? 1 : -1).sort((a, b) => (a.status < b.status) ? 1 : -1)
    }


    render() {
        {
            this.sortCriteria();
        }
        return (
            <div>
                {this.state.data.startEvaluation ?
                    <div>
                        <Modal
                            open={this.state.showCreateModal}
                            onClose={this.closeModal}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            {this.state.modalBody}
                        </Modal>
                        <div className="row">
                            <h2 className="ml-3"
                                style={{color: "#1C4857", fontStyle: 'italic'}}>{this.state.data.name}</h2>
                        </div>

                        <div className="row">
                            <div className="col-4 mt-4">
                                <label
                                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.institutionCategory}</label>
                                <Select
                                    placeholder={this.state.categoryName ? this.state.categoryName : " "}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={this.state.allCategories}
                                    onChange={this.onSelectedCategoryHandler}
                                    name="categoryId"
                                    value={this.state.categoryId}
                                    defaultValue={{
                                        value: this.state.categoryId,
                                        label: this.state.categoryName,
                                    }}
                                />

                                <br/>

                                <label
                                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.institution}</label>
                                <Select
                                    placeholder={this.state.institutionName ? this.state.institutionName : " "}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={this.state.optionsInstitutions}
                                    onChange={this.onSelectedInstitutionHandler}
                                    name="institutionId"
                                    value={this.state.institutionId}
                                    defaultValue={{
                                        value: this.state.institutionId,
                                        label: this.state.institutionName,
                                    }}
                                />
                            </div>

                            {this.state.listOfInstitutions.length > 0 &&
                            <div className="col-4">
                                <h4 className="text-center"
                                    style={{color: "#1C4857"}}>{strings.institutionsInEvaluation}</h4>
                                <table className="table-hover newTable" style={{width: "100%"}}>
                                    <thead className="tableHead">
                                    <th className="tableHeading"
                                        style={{borderRadius: '15px 15px 0px 0px'}}>{strings.name}</th>
                                    </thead>
                                    <tbody>
                                    {this.state.listOfInstitutions.map(this.renderInstitutions)}
                                    </tbody>
                                </table>
                                <ReactPaginate
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakLabel={'...'}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                />
                            </div>
                            }

                            <div className="col-4">

                                {this.state.setListOfDocuments && (
                                    <div>
                                        <h4 className="text-center"
                                            style={{color: "#1C4857"}}>{strings.publicDocuments}
                                        </h4>
                                        <table className="table-hover newTable"
                                               style={{width: "100%"}}>
                                            <thead className="tableHead">
                                            <th className="tableHeading firstHeading"
                                                style={{width: '24%'}}>{strings.name}</th>
                                            <th className="tableHeading"
                                                style={{width: '24%'}}>{strings.YearForPublicDocument}</th>
                                            <th className="tableHeading"
                                                style={{width: '27%'}}>{strings.attachment}</th>
                                            <th className="tableHeading lastHeading"
                                                style={{width: '25%'}}>{strings.link}</th>
                                            </thead>
                                            <tbody>
                                            {this.state.publicDocuments.map(this.renderPublicDocuments)}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>


                        </div>


                        <div className="row mt-3">
                            <div className="col-12">
                                <h2 className="align-items-center justify-content-center position-relative">{strings.allCriterias}</h2>
                                <table className="table-hover newTable mt-2" style={{width: "100%"}}>
                                    <thead className="tableHead">
                                    <th className="tableHeading firstHeading" style={{width: '58%'}}>{strings.name}</th>
                                    <th className="tableHeading" style={{width: '12%'}}>{strings.parentToCriteria}</th>
                                    <th className="tableHeading" style={{width: '11%'}}>{strings.evaluate}</th>
                                    <th className="tableHeading" style={{width: '11%'}}>{strings.publicDocuments}</th>
                                    <th className="tableHeading lastHeading"
                                        style={{width: '8%'}}>{strings.evaluated}</th>
                                    </thead>
                                    <tbody>
                                    {this.state.criterias.map(this.renderCriterias)}
                                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                                        <td style={{borderRadius: "0 0 0 15px"}}/>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td style={{borderRadius: "0 0 15px 0"}}/>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-10"/>
                            <div className="col-2 mt-5 text-right">
                                <ShareEvaluatedReport btnClass={"importantButton"}
                                                      showText={true}
                                                      prompt={strings.shareEvaluatedReport}
                                                      doDelete={() => this.saveReport()}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-10"/>
                            <div className="col-2 mt-3 text-right">
                                {this.state.institutionId !== null &&
                                <a href={LOCALHOST + "rest/fullReport/export/" + this.state.data.id + "/" + this.state.institutionId}
                                   className="btn downloadButton">
                                    <FontAwesomeIcon icon={faDownload} size="lg"
                                                     style={{paddingRight: '4px'}}/>
                                    {strings.exportCSV}</a>
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-10"/>
                            <div className="col-2 mt-3 text-right">
                                {this.state.institutionId !== null &&
                                <a href={LOCALHOST + "rest/fullReport/singleReportExport/" + this.state.data.id + "/" + this.state.institutionId}
                                   className="btn downloadButtonSecond mb-4">
                                    <FontAwesomeIcon icon={faDownload} size="lg"
                                                     style={{paddingRight: '4px'}}/>
                                    {strings.exportSingle}</a>
                                }
                            </div>
                        </div>

                        {/*<div className="row">*/}
                        {/*    <div className="col-4"/>*/}
                        {/*    <div className="col-2 mt-3 ml-2">*/}
                        {/*        <button className="btn btn-danger" onClick={this.getScoresForInstitutions}>Click me</button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    :
                    <div className="">
                        <div className="row">
                            <h2 className="ml-3"
                                style={{color: "#1C4857", fontStyle: 'italic'}}>{this.state.data.name}</h2>
                        </div>
                        <br/>

                        <div className="row mt-2">
                            <div className="col-12">

                                <div className="row">
                                    <div className="col-6 text-left">
                                        <h2 className="align-items-center justify-content-center position-relative">{strings.allCriterias}</h2>
                                    </div>
                                    <div className="col-6 text-right justify-content-end">
                                        <StartEvaluation btnClass={"btn mr-2 evaluationButton"}
                                                         showText={true}
                                                         prompt={strings.startEvaluationProcess}
                                                         doDelete={this.startEvaluation}/>

                                        <CrudModal entity={{}}
                                                   icon="add"
                                                   btnClass="btn-primary btn defaultBtnAdd"
                                                   title={strings.addCriteria}
                                                   showText={true}
                                                   onSubmit={this.onAdd}
                                                   body={CriteriaAdd}/>
                                    </div>
                                </div>

                                <table className="table-hover newTable mt-2 mb-3" style={{width: "100%"}}>
                                    <thead className="tableHead">
                                    <th className="tableHeading firstHeading" style={{width: '63%'}}>{strings.name}</th>
                                    <th className="tableHeading" style={{width: '15%'}}>{strings.parentToCriteria}</th>
                                    <th className="tableHeading" style={{width: '11%'}}>{strings.actions}</th>
                                    <th className="tableHeading lastHeading"
                                        style={{width: '11%'}}>{strings.actions}</th>
                                    </thead>
                                    <tbody>
                                    {this.state.criterias.map(this.renderCriterias)}
                                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                                        <td style={{borderRadius: "0 0 0 15px"}}/>
                                        <td/>
                                        <td/>
                                        <td style={{borderRadius: "0 0 15px 0"}}/>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }


    renderCriterias = (criteria) => {
        return (
            <tr key={criteria.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{criteria.name}</td>
                <td className="tableData">{criteria.parentCriteria ? criteria.parentCriteria.name : "/"}</td>
                {!(this.state.data.startEvaluation) && //if the report is ready for evaluation
                <td className="tableData">
                    <CrudModal
                        entity={criteria}
                        icon="edit"
                        btnClass="btn-primary btn-sm defaultBtnEdit"
                        title={strings.edit}
                        showText={true}
                        onSubmit={this.onEdit}
                        body={CriteriaEdit}
                    />
                </td>
                }

                {!(this.state.data.startEvaluation) && //if the report is ready for evaluation
                <td className="tableData">
                    {criteria.status ?  //if the status of criteria is active there is button "disable"}
                        <InactivateModal btnClass={"btn-sm defaultBtn"}
                                         showText={true}
                                         prompt={strings.deleteCriteriaForCriteria}
                                         doDelete={() => this.deleteCriteria(criteria.id)}
                        />
                        : //if the status of criteria is inactive there is button "enable"
                        <UnDeleteModal btnClass={"btn-sm defaultBtn"}
                                       showText={true}
                                       prompt={strings.shareCriteria}
                                       doDelete={() => this.unDeleteCriteria(criteria.id)}
                        />
                    }
                </td>
                }

                {this.state.data.startEvaluation ? //if the report is ready for evaluation
                    criteria.status ?   //if the status of criteria is active ( can be evaluated)
                        <td className="tableData">
                            {criteria.statusChecked ? //if the status of criteria is active and report element is created(can not be evaluated) - button is disabled
                                <button id={criteria.id} name={criteria.name}
                                        className="btn-sm btn-secondary defaultBtn ml-1"
                                        disabled
                                        onClick={this.openModal}
                                        style={{opacity: '0.5', border: '0'}}>
                                    <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" style={{paddingRight: '4px'}}/>
                                    {strings.evaluate}
                                </button>
                                : //if the status of criteria is active and report element is not created(can be evaluated)
                                <button id={criteria.id} name={criteria.name}
                                        className="btn-sm btn-secondary defaultBtn ml-1"
                                        onClick={this.openModal}
                                        style={{border: '0'}}>
                                    <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" style={{paddingRight: '4px'}}/>
                                    {strings.evaluate}
                                </button>
                            }
                        </td>
                        :  //if the status of criteria is inactive ( can't be evaluated) - button is disabled
                        <td className="tableData">
                            <button id={criteria.id} name={criteria.name} className="btn-secondary btn-sm defaultBtn"
                                    style={{opacity: '0.5', border: '0'}} disabled>
                                <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" style={{paddingRight: '4px'}}/>
                                {strings.evaluate}
                            </button>
                        </td>
                    :
                    <div/>
                }

                {this.state.data.startEvaluation && //if the report is ready for evaluation
                <td className="tableData">
                    <button id={criteria.id}
                            className="btn btn-sm defaultBtn ml-1"
                            style={{background: '#7ED4E6'}}
                            onClick={() => this.getHelp(criteria.id)}>
                        <FontAwesomeIcon icon={faSearch} size="lg" style={{paddingRight: '4px'}}/>
                        {strings.preview}</button>
                </td>
                }

                {this.state.data.startEvaluation &&       //if the report is ready for evaluation
                <td className="tableData" style={{justifyContent: 'center', alignItems: 'center', borderBottom: '0'}}>
                    {criteria.statusChecked ?   // if report element for that report is created here is shown \/
                        <FontAwesomeIcon icon={faCheck} size="lg" style={{
                            marginLeft: '30px',
                            color: 'green',
                            border: '0.5px solid black',
                            borderRadius: '10px',
                            width: '25px',
                            height: '25px'
                        }}/>
                        :
                        <FontAwesomeIcon icon={faTimes} size="lg" style={{marginLeft: '30px'}}/> // if report element for that report is not created here is shown X
                    }
                </td>
                }

            </tr>
        )
    }

    renderInstitutions = (institution) => {
        return (
            <tr key={institution.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{institution.nameMk}</td>
            </tr>
        )
    }

    renderPublicDocuments = (publicDocument) => {
        return (
            <tr key={publicDocument.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData"
                    style={{wordWrap: "break-word"}}>{publicDocument.type ? publicDocument.type.name : ""}</td>
                <td className="tableData"
                    style={{wordWrap: "breakWord"}}>{publicDocument.year ? publicDocument.year : ""}</td>
                <td className="tableData">{publicDocument.attachment !== null ?
                    <a style={{color: "blue", marginBottom: "4px"}}
                       href={LOCALHOST + "rest/attachment/download/" + publicDocument.attachment.id}
                       target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf}
                                                                                  color="red"
                                                                                  size="lg"
                                                                                  style={{paddingRight: '4px'}}/>{strings.download}
                    </a> : strings.noPDF}</td>
                <td className="tableData"
                    style={{wordWrap: "break-word"}}>{publicDocument.url ? publicDocument.url : ""}</td>
            </tr>
        )
    }
}

export default (FullReport);