import React, {Component} from "react";
import InactiveUsersRepository from "../../repository/InactiveUsersRepository";
import {strings} from "../../Localization/Localization";
import Activation from "./Activation";
import {toast} from "react-toastify";
import './Css/InactiveUsers.css'
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InstitutionRepository from "../../repository/InstitutionRepository";
import {Link} from "react-router-dom";
import {LOCALHOST} from "../../shared/utility";

class InactiveUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listUsers: [],
            institution: {}
        }
    }

    getAllInactiveUsers = () => {
        let id = this.props.match.params.id;
        InstitutionRepository.getInstitutionById(id).then(res => {
            this.setState({
                institution: res.data
            })
        })

        InstitutionRepository.getListOfInactiveUsersForInstitution(id).then(res => {
            this.setState({
                listUsers: res.data
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    // handlePageClick = data => {
    //     this.getAllInactiveUsers(data.selected);
    // };


    componentDidMount() {
        this.getAllInactiveUsers();
    }

    render() {
        return (

            <div>
                <div className="row">
                    <div className="col-12">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-4 ml-3">
                            {localStorage.getItem("activeLanguage") === "mk"
                                ?
                                strings.inactiveUsers + ": " + this.state.institution.nameMk
                                :
                                strings.inactiveUsers + ": " + this.state.institution.nameAl
                            }
                        </h2>
                    </div>
                </div>

                {/*<div className="row">*/}
                {/*    <div className="col-12">*/}
                {/*           <div className="form-group has-search mb-5">*/}
                {/*                <span className="fa fa-search form-control-feedback"/>*/}
                {/*                <input type="text" className="form-control" placeholder={strings.searchByInst} style={{width:"38%"}} onChange={this.handleSearch}/>*/}
                {/*           </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <table className="table-hover newTable mt-2" style={{width: "100%"}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading" style={{width: '15%'}}>{strings.firstName}</th>
                    <th className="tableHeading" style={{width: '15%'}}>{strings.lastName}</th>
                    <th className="tableHeading" style={{width: '30%'}}>{strings.institution}</th>
                    <th className="tableHeading" style={{width: '15%'}}>{strings.documentSolution}</th>
                    <th className="tableHeading" style={{width: '15%'}}>{strings.dateCreated}</th>
                    <th className="tableHeading lastHeading text-center" style={{width: '10%'}}>{strings.actions}</th>
                    </thead>

                    <tbody>
                    {this.state.listUsers.map(this.renderUsers)}
                    </tbody>

                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>


                <div className="row">
                    <div className="col-11"/>
                    <div className="col-1">
                        <Link to={'/responsiblePersons/' + this.state.institution.id}
                              className="btn btn-dark mt-5 defaultBtn">
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-1"/>{strings.goBack}</Link>
                    </div>
                </div>

            </div>
        )
    }

    renderUsers = user => {
        let instNameMk = user.institution ? user.institution.nameMk : "";
        let instNameAl = user.institution ? user.institution.nameAl : "";
        return (

            <tr key={user.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData" style={{
                    color: 'black',
                    fontSize: '15px'
                }}>{localStorage.getItem("activeLanguage") === "mk" ? user.firstName : user.firstNameAl}</td>
                <td className="tableData" style={{
                    color: 'black',
                    fontSize: '15px'
                }}>{localStorage.getItem("activeLanguage") === "mk" ? user.lastName : user.lastNameAl}</td>
                <td className="tableData" style={{
                    color: 'black',
                    fontSize: '15px'
                }}>{localStorage.getItem("activeLanguage") === "mk" ? instNameMk : instNameAl}</td>

                <td className="tableData">
                    {user.attachment ?
                        <a href={LOCALHOST + "rest/attachment/download/" + user.attachment.id}
                           target="_blank"
                           rel="noopener noreferrer"
                           style={{color: "blue"}}>
                            {user.attachment.name}
                        </a>
                    : strings.noDocumentAttached}
                </td>

                <td className="tableData" style={{color: 'black', fontStyle: 'bold'}}>{user.dateCreated}</td>
                <td className="tableData" style={{color: 'black', fontWeight: 'bold'}}>
                    <Activation
                        handleActivationUser={() => this.handleActivationUser(user.id)}
                        prompt={strings.userActivate}
                    />
                </td>
            </tr>
        )
    }

    handleActivationUser = (userId) => {
        return InactiveUsersRepository.setActiveUser(userId).then(() => {
            toast.success();
            this.getAllInactiveUsers();
        }).catch(() => {
            toast.error();
        })
    }

    //
    // handleSearch = (e) => {
    //     this.setState({instName:e.target.value},()=>{
    //         InactiveUsersRepository.getAllInactiveUsersByInstName(this.state.instName).then(r => this.setState({
    //             listUsers: r.data.content,
    //             pageCount: r.data.totalPages}));
    //     })
    // }
}

export default InactiveUsers;
