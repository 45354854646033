import React, {Component} from 'react';
import CriteriaTypeRepository from "../../repository/CriteriaTypeRepository";
import {toast} from "react-toastify";
import {strings} from "../../Localization/Localization";
import DeleteModal from "../Crud/DeleteModal";
import CrudModal from "../Crud/CrudModal";
import CriteriaTypeEdit from "./CriteriaTypeEdit";
import CriteriaTypeAdd from "./CriteriaTypeAdd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

class CriteriaType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            criteriaTypes: []
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        CriteriaTypeRepository.findAllCriteriaTypes().then(res => {
            this.setState({
                criteriaTypes: res.data
            })
        })
    }

    addCriteriaType = (criteriaType) => {
        return CriteriaTypeRepository.createCriteriaType(criteriaType).then(res => {
            toast.success(strings.succAdded);
            this.props.history.push("/options/" + res.data.id);
            this.fetchData();
        }).catch(err => {
            toast.error(strings.failTryAgain)
        })
    }

    editCriteriaType = (criteriaType) => {
        return CriteriaTypeRepository.editCriteriaType(criteriaType).then(res => {
            toast.success(strings.succChanged);
            this.props.history.push("/options/" + criteriaType.id);
            this.fetchData();
        }).catch(err => {
            toast.error(strings.failTryAgain)
        })
    }

    deleteCriteriaType = (criteriaTypeId) => {
        return CriteriaTypeRepository.deleteCriteriaType(criteriaTypeId).then(res => {
            toast.success(strings.deleteModal);
            this.fetchData();
        }).catch(err => {
            toast.error(strings.failTryAgain)
        })
    }


    render() {
        return (
            <div>
                <div className="row mt-5">
                    <div className="col-12">

                        <div className="row">
                            <div className="col-6">
                                <h2 style={{color: "#1C4857"}}>{strings.typesAnswers}</h2>
                            </div>
                            <div className="col-6 text-right justify-content-end">
                                <CrudModal entity={{}}
                                           icon="add"
                                           showText={true}
                                           btnClass="btn-primary defaultBtnAdd"
                                           title={strings.add}
                                           onSubmit={this.addCriteriaType}
                                           body={CriteriaTypeAdd}/>
                            </div>
                        </div>

                        <table className="table-hover newTable mt-2" style={{width: "100%"}}>
                            <thead className="tableHead">
                            <th className="tableHeading firstHeading" style={{width: '70%'}}>{strings.name}</th>
                            <th className="tableHeading" style={{width: '10%'}}>{strings.optionsForType}</th>
                            <th className="tableHeading" style={{width: '10%'}}>{strings.options}</th>
                            <th className="tableHeading lastHeading" style={{width: '10%'}}>{strings.delete}</th>
                            </thead>
                            <tbody>{this.state.criteriaTypes.map(this.renderCriteriaTypes)}</tbody>
                            <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                                <td style={{borderRadius: "0 0 0 15px"}}/>
                                <td/>
                                <td/>
                                <td style={{borderRadius: "0 0 15px 0"}}/>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    renderCriteriaTypes = (criteriaType) => {
        return (
            <tr key={criteriaType.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{criteriaType.name}</td>
                <td className="tableData">
                    <Link to={"/options/" + criteriaType.id}
                          className="btn btn-sm defaultBtn" style={{background: '#BCDFEF'}}>
                        <FontAwesomeIcon
                            icon={faLink}
                            size="lg"
                            style={{paddingRight: '4px'}}
                        />
                        {strings.review}
                    </Link>
                </td>
                <td className="tableData">
                    <CrudModal entity={criteriaType}
                               icon="edit"
                               btnClass="btn-primary btn-sm defaultBtnEdit"
                               title={strings.edit}
                               showText={true}
                               onSubmit={this.editCriteriaType}
                               body={CriteriaTypeEdit}/>
                </td>
                <td className="tableData">
                    <DeleteModal btnClass={"ml-1 defaultBtn"}
                                 showText={true}
                                 prompt={strings.deleteCriteriaType}
                                 doDelete={() => this.deleteCriteriaType(criteriaType.id)}/>
                </td>
            </tr>
        )
    }
}

export default CriteriaType;