import axios from '../axios/app';

const FullReportRepository = {

    findAllPageable: (page = 0, size = 10) => {
        return axios.get(`/rest/fullReport/allPageable?page=${page}&size=${size}`)
    },
    findAllReports: () => {
        return axios.get("/rest/fullReport/all")
    },
    createReport: (fullReport) => {
        return axios.post("/rest/fullReport/createReport", fullReport)
    },
    findFullReport: (fullReportId) => {
        return axios.get(`/rest/fullReport/${fullReportId}`)
    },
    reviewAndSaveReport: (fullReportId) => {
        return axios.patch(`/rest/fullReport/${fullReportId}`)
    },
    deleteFullReport: (fullReportId) => {
        return axios.delete(`/rest/fullReport/${fullReportId}`)
    },
    findAllForEvaluation: (page = 0, size = 10) => {
        return axios.get(`/rest/fullReport/allForEvaluation?page=${page}&size=${size}`)
    },
    sendForEvaluation: (fullReportId) => {
        return axios.post(`/rest/fullReport/saveForEvaluation/${fullReportId}`)
    },
    getInstitutionsForReportPaged: (fullReportId, categoryId, page = 0, size = 3) => {
        return axios.get(`/rest/fullReport/allInstitutionsForReport/paged/${fullReportId}/${categoryId}?page=${page}&size=${size}`)
    },
    getInstitutionsForReport: (fullReportId, categoryId) => {
        return axios.get(`/rest/fullReport/allInstitutionsForReport/${fullReportId}/${categoryId}`)
    },
    getAllInstitutionsListForReport: (fullReportId, categoryId) => {
        return axios.get(`/rest/fullReport/getAllInstitutionsListForReportNotEvaluated/${fullReportId}/${categoryId}`)
    },
    getScoresForInstitutions: (fullReportId, institutionId) => {
        return axios.get(`/rest/fullReport/getScores/${fullReportId}/${institutionId}`)
    },
    getInstitutionsForExcel: (fullReportId) => {
        return axios.get(`/rest/fullReport/allInstitutionsForReport/${fullReportId}`)
    },
    getInstitutionsWithoutCategoryEvaluated: (fullReportId) => {
        return axios.get(`/rest/fullReport/allInstitutionsWithoutCategoryEvaluated/${fullReportId}`)
    },
    getAllInstitutionsWithoutCategoryNotEvaluated: (fullReportId) => {
        return axios.get(`/rest/fullReport/getAllInstitutionsWithoutCategoryNotEvaluated/${fullReportId}`)
    },
    startReportEvaluation: (fullReportId) => {
        return axios.post(`/rest/fullReport/startEvaluation/${fullReportId}`)
    }
}

export default (FullReportRepository);