import React, {useEffect, useState} from "react";
import {strings} from "../../Localization/Localization";
import ReportElementRepository from "../../repository/ReportElementRepository";
import {Card} from "react-bootstrap";
import InstitutionRepository from "../../repository/InstitutionRepository";
import {useHistory} from "react-router-dom";


const EditReportElement = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));

    const [formData, updateFormData] = useState({
        criteria: props.criteria,
        institution: {"value": props.institutionId, "label": props.institutionName},
        score: props.score,
        link: props.link,
        comment: props.comment,
        // score: "",
        // link: "",
        // comment: "",
        idFullReport: props.idFullReport,
        institutionName: props.institutionName,
        criteriaName: props.criteriaName,
        reportElementType: props.reportElementType,
    })

    const [institutions, setInstitutions] = useState([]);
    const [listInstitutions, setListInstitutions] = useState([]);

    const onChangeHandler = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const handleChangeSelect = (value, action) => {
        updateFormData({
            ...formData,
            [action.name]: value
        })
    }

    const getInstitutions = () => {
        InstitutionRepository.getAllEditedInstitutions().then(res => {
            setInstitutions(res.data);
            res.data.map(item => {
                listInstitutions.push({value: item.id, label: item.nameMk, name: "institution"})
            })
        })
    }

    useEffect(() => {
        getInstitutions();
    }, [])

    const history = useHistory();
    const onFormSubmit = (e) => {
        e.preventDefault();
        const idFullReport = parseInt(formData.idFullReport);
        const criteria = formData.criteria;

        const institution = formData.institution.value;
        const score = formData.score;
        const link = formData.link;
        const comment = formData.comment;
        const reportElementType = formData.reportElementType;
        const reportElement = {score: score, link: link, comment: comment, reportElementType: reportElementType}
        ReportElementRepository.updateReportElement(reportElement, institution, criteria, idFullReport).then(() => {
            history.push({
                pathname: window.location = '/evaluationOfFullReport/' + idFullReport,
                state: {
                    category: props.category,
                    institution: props.institution,
                    optionsInstitutions: props.optionsInstitutions,
                    listOfInstitutions: props.listOfInstitutions
                }
            })

        });
    }

    return (
        <div id="mainDiv" className="container-fluid mt-5" style={{width: 700}}>
            <Card style={{height: '700 !important'}} className="h-100">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.oneCriteria}</label>
                    <textarea
                        disabled
                        defaultValue={props.criteriaName}
                        name={"criteria"}
                        rows={2}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"
                    />
                </div>
                <br/>

                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.institution}</label>
                    <textarea
                        disabled
                        name={"institution"}
                        defaultValue={props.institutionName}
                        rows={2}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"
                    />

                    {/*<Select*/}
                    {/*    placeholder={""}*/}
                    {/*    className="basic-single"*/}
                    {/*    classNamePrefix="select"*/}
                    {/*    isDisabled={false}*/}
                    {/*    isLoading={false}*/}
                    {/*    isClearable={true}*/}
                    {/*    isRtl={false}*/}
                    {/*    isSearchable={true}*/}
                    {/*    options={listInstitutions}*/}
                    {/*    defaultValue={formData.institution}*/}
                    {/*    onChange={handleChangeSelect}*/}
                    {/*    name={"institution"}*/}
                    {/*/>*/}
                </div>
                <br/>

                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.answeredInDays}</label>
                    <select
                        name={"reportElementType"}
                        className="form-control custom-select"
                        onChange={(e) => onChangeHandler(e)}
                        defaultValue={props.reportElementType}
                    >
                        <option value="NotAnswered">{strings.notAnswered}</option>
                        <option value="AnsweredFive">{strings.answeredFive}</option>
                        <option value="AnsweredTen">{strings.answeredTen}</option>
                        <option value="AnsweredFifteen">{strings.answeredFifteen}</option>
                    </select>
                </div>
                <br/>

                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.score}</label>
                    <input
                        defaultValue={props.score}
                        name={"score"}
                        type={"text"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"
                    />
                </div>
                <br/>

                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.link}</label>
                    <input
                        defaultValue={props.link}
                        name={"link"}
                        type={"text"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"
                    />
                </div>
                <br/>

                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.comm}</label>
                    <textarea
                        defaultValue={props.comment}
                        name={"comment"}
                        rows="3"
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"
                    />
                </div>
                <br/>

                <div className="col-12 mb-2">
                    <button type="submit" className="btn btn-primary defaultBtn"
                            onClick={onFormSubmit}>{strings.save}</button>
                </div>
            </Card>
        </div>
    )
}

export default EditReportElement;