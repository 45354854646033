import {strings} from "../../Localization/Localization";
import React from "react";

const CriteriaTypeAdd = (props) => {

    const onChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value)
    }

    return (
        <div>
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.name}</label>
                <textarea
                    name={"name"}
                    rows={3}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                />
            </div>
        </div>
    )
}

export default CriteriaTypeAdd;