import React, {useEffect, useState} from "react";
import AnnouncementPublicationRepository from "../../repository/AnnouncementPublicationRepository";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './css/MyPublications.css'
import ReactPaginate from "react-paginate";
import {strings} from "../../Localization/Localization";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComments} from "@fortawesome/free-solid-svg-icons";
import InfoPopup from "../ErrorHandler/InfoPopup";

const MyPublications = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));

    const [announcements, setAnnouncements] = useState([]);
    const [pageCount, setPageCount] = useState(1);

    async function fetchData(selectedPage = 0) {

        let institutionId = localStorage.getItem('institutionId');
        institutionId = parseInt(institutionId);
        AnnouncementPublicationRepository.getAnnPubPaged(institutionId, selectedPage).then(res => {
            setAnnouncements(res.data.content)
            setPageCount(res.data.totalPages)
        }).catch(err => {
            toast.error(strings.failedToLoadAnnouncementPub);
            props.history.push("/logout");
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    function handlePageClick(data) {
        fetchData(data.selected);
    };

    if (announcements.length !== 0)
        return (

            <div className="container">
                <h1 style={{color: "#1C4857", fontWeight: 'bold'}}
                    className="mt-4 text-center">{strings.mypublications}</h1>
                <br/>
                <br/>

                <div className="row">
                    {announcements.map((item, key) => {
                        let dateRead = item.announcementPublication.readAt;

                        if (key % 2 === 0)
                            return (
                                <div className="container-fluid publicationDivContainerWhite">
                                    <div className="row announcementTitleRow">
                                        <div className="col-12">
                                            <a href={"/myannouncements/" + item.announcementPublication.id + "?ann=" + item.announcementPublication.announcement.id}
                                               className="announcementTitle">{item.announcementPublication.announcement.title}</a>
                                        </div>
                                    </div>
                                    <div className="row announcementBodyRow">
                                        <div className="col-12">
                                            {/*<p className="announcementBody">{item.announcementPublication.announcement.body}</p>*/}
                                            <a style={{color: 'black'}} className="announcementBody"
                                               href={"/myannouncements/" + item.announcementPublication.id + "?ann=" + item.announcementPublication.announcement.id}>
                                                {item.announcementPublication.announcement.body}
                                            </a>
                                            <br/>
                                            {/*<a style={{display: "inline-block"}}>*/}
                                            {/*    <Link*/}
                                            {/*        to={"/myannouncements/" + item.announcementPublication.id + "?ann=" + item.announcementPublication.announcement.id}*/}
                                            {/*        className="announcementLinkButton">{strings.more}</Link>*/}
                                            {/*</a>*/}
                                        </div>
                                    </div>
                                    <div className="row announcementFooterRow">
                                        <div className="col-12 announcementFooterCol">
                                            {item.announcementPublication.read &&
                                            <span className="badge badge-pill badge-info badgeText"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom" title={dateRead}>{strings.read}</span>}
                                            {!item.announcementPublication.read && <span
                                                className="badge badge-pill badge-danger badgeText">{strings.NotReaded}</span>}
                                            <h6 style={{float: 'right', color: '#343a40'}}>
                                                <FontAwesomeIcon icon={faComments} size="lg"
                                                                 style={{paddingRight: '4px'}}/>
                                                {item.numberOfComments}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            )
                        else
                            return (
                                <div className="container-fluid publicationDivContainerDark">
                                    <div className="row announcementTitleRow">
                                        <div className="col-12">
                                            <a href={"/myannouncements/" + item.announcementPublication.id + "?ann=" + item.announcementPublication.announcement.id}
                                               className="announcementTitle">{item.announcementPublication.announcement.title}</a>
                                        </div>
                                    </div>
                                    <div className="row announcementBodyRow">
                                        <div className="col-12">
                                            {/*<p className="announcementBody">{item.announcementPublication.announcement.body}</p>*/}
                                            <a style={{color: 'black'}} className="announcementBody"
                                               href={"/myannouncements/" + item.announcementPublication.id + "?ann=" + item.announcementPublication.announcement.id}>
                                                {item.announcementPublication.announcement.body}
                                            </a>
                                            <br/>
                                            {/*<a style={{display: "inline-block"}}>*/}
                                            {/*    <Link*/}
                                            {/*        to={"/myannouncements/" + item.announcementPublication.id + "?ann=" + item.announcementPublication.announcement.id}*/}
                                            {/*        className="announcementLinkButton">{strings.more}</Link>*/}
                                            {/*</a>*/}
                                        </div>
                                    </div>
                                    <div className="row announcementFooterRow">
                                        <div className="col-12 announcementFooterCol">
                                            {item.announcementPublication.read &&
                                            <span className="badge badge-pill badge-info badgeText"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom" title={dateRead}>{strings.read}</span>}
                                            {!item.announcementPublication.read && <span
                                                className="badge badge-pill badge-danger badgeText">{strings.NotReaded}</span>}
                                            <h6 style={{float: 'right', color: '#343a40'}}>
                                                <FontAwesomeIcon icon={faComments} size="lg"
                                                                 style={{paddingRight: '4px'}}/>
                                                {item.numberOfComments}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            )

                    })
                    }
                </div>

                <div className="row">
                    <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        )
    else return (
        <div style={{display:'flex',alignItems:'center', height:'100%'}}>
            <InfoPopup infoMessage={strings.publishedAnnouncements} />
        </div>
    )
};
export default MyPublications;