import React, {Component} from 'react';
import {strings} from "../../Localization/Localization";
import FeedbackPublicationRepository from "../../repository/FeedbackPublicationRepository";
import ReactPaginate from "react-paginate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faDownload, faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Datetime from "react-datetime";
import {LOCALHOST} from "../../shared/utility";
import {Button, FormControl, InputGroup} from "react-bootstrap";


class ProactiveTransparencyFinished extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allFeedbackPublications: [],
            feedbackId: this.props.match?.params?.id,
            pageCount: 1,
            selectedYear: null,
            keyword: "",
        }
    }

    componentDidMount() {
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
    }

    fetchData = (selectedPage = 0) => {
        if (this.state.selectedYear !== null) {
            FeedbackPublicationRepository.findAllPublicationsForFeedbackAnsweredWithYear(this.state.feedbackId, this.state.selectedYear, this.state.keyword, selectedPage).then(res => {
                this.setState({
                    allFeedbackPublications: res.data.content,
                    pageCount: res.data.totalPages
                })
            })
        }
    }

    handlePageClick = data => {
        this.fetchData(data.selected);
    };

    lang = localStorage.getItem('activeLanguage')
    localizedProp = (item, name) => {
        name = name || 'name';

        if (this.lang === "mk") {
            return item[name + 'Mk'];
        }
        if (this.lang === "en") {
            return item[name + 'En'];
        }
        if (this.lang === "al") {
            return item[name + 'Al'];
        }

    }

    handleDateChange = async (e) => {
        await this.setState({
            selectedYear: moment(e._d).format('YYYY'),
            keyword: "",
        })
        await this.fetchData();
    }

    handleSearch = (e) => {
        this.setState({keyword: e.target.value}
            , () => {
                this.fetchData();
            })
    }


    renderFeedbackPub = item => {
        return (<tr key={item.id} style={{border: "1px solid lightgray"}}>
            <td className="tableData">{item.feedback.name}</td>
            <td className="tableData">{this.localizedProp(item.institution)}</td>
            <td className="tableData">{item.finished === true ? <FontAwesomeIcon icon={faCheck} size="lg"
                                                                                 style={{
                                                                                     paddingLeft: '20p',
                                                                                     marginLeft: '25px',
                                                                                     color: "green"
                                                                                 }}
                                                                                 className="text-center"/> :
                <FontAwesomeIcon icon={faTimes} size="lg"
                                 style={{paddingLeft: '20p', marginLeft: '25px', color: "#dc3545"}}
                                 className="text-center"/>}</td>
            <td className="tableData">{item.readAt ? item.readAt.slice(0, 10) : `${strings.NotReaded}`}</td>
            <td className="tableData" align="center">
                {!item.finishedFromAdmin ?
                    <a className="btn defaultBtnPreview"
                       href={`/previewAndAnswer/${item.id}`}>
                        <FontAwesomeIcon icon={faSearch} size="lg"
                                         style={{paddingRight: '4px'}}/>
                        {strings.previewAnswer}
                    </a>
                    :
                    <a href={LOCALHOST + "rest/feedbackPublication/export/" + item.id}
                       className="btn btn-dark ml-1 defaultBtn"
                       target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faDownload} size="lg"
                                         style={{paddingRight: '4px'}}
                        />
                        {strings.analyse}
                    </a>
                    // <button className="btn defaultBtnPreview" disabled={true}>{strings.userNotified}</button>
                }
            </td>
        </tr>);
    }

    render() {
        return (
            <div className="col-12">

                <div className="col-6 mt-3">
                    <label style={{
                        fontSize: '17px',
                        fontWeight: 'bold'
                    }}>{strings.evaluationYear} </label>
                    <Datetime
                        dateFormat="YYYY"
                        timeFormat={false}
                        value={this.state.selectedYear}
                        onChange={(e) => this.handleDateChange(e)}
                    />
                </div>

                <div className="row mt-5">
                    <div className="col-12">
                        <h3 className="FeedbackToPublishBlueTextColor" style={{
                            fontSize: '27px',
                            fontWeight: 'bold'
                        }}>{strings.AllPublicationsAnsweredForThisProactivity}</h3>
                    </div>

                    {this.state.selectedYear &&
                        <div className="col-6">
                            <InputGroup className="mb-3">
                                <FormControl
                                    value={this.state.keyword}
                                    disabled={false}
                                    placeholder={strings.search}
                                    onChange={this.handleSearch}
                                    aria-label="Search"
                                    aria-describedby="basic-addon2"
                                />
                            </InputGroup>
                        </div>
                    }


                    <table className="table-hover newTable mt-3" style={{width: "100%"}}>
                        <thead className="tableHead">
                        <tr>
                            <th className="tableHeading firstHeading" style={{width: '27%'}}>{strings.activityName}</th>
                            <th className="tableHeading" style={{width: '28%'}}>{strings.allAnswered}</th>
                            <th className="tableHeading" style={{width: '10%'}}>{strings.answered}</th>
                            <th className="tableHeading" style={{width: '14%'}}>{strings.readAt}</th>
                            <th className="tableHeading lastHeading text-center"
                                style={{width: '21%'}}>{strings.actions}</th>
                        </tr>
                        </thead>
                        <tbody>{this.state.allFeedbackPublications.map(this.renderFeedbackPub)}</tbody>
                        <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                            <td style={{borderRadius: "0 0 0 15px"}}/>
                            <td/>
                            <td/>
                            <td/>
                            <td style={{borderRadius: "0 0 15px 0"}}/>
                        </tr>
                    </table>

                    <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        );
    }
}

export default ProactiveTransparencyFinished;