import React, {Component} from 'react';
import {strings} from "../../Localization/Localization";
import PublicDocumentsRegistryRepository from "../../repository/PublicDocumentsRegistryRepository";
import {toast} from "react-toastify";
import {faArrowLeft, faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class AddPublicDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.history.location.state.item,
            institutionId: localStorage.getItem('institutionId'),
            year: props.history.location.state.year,
            document: props.history.location.state.document,
            file: null,
            dataUrl: '',
            fileName: ''
        }
    }

    onChangeHandler = (e) => {
        const target = e.target || e;
        let dataUrl;
        dataUrl = target.value;
        this.setState({
            dataUrl: dataUrl
        })
    }

    componentDidMount() {
        if (this.state.document.attachment != null) {
            this.setState({
                fileName: this.state.document.attachment.name,
            })
        } else {
            this.setState({
                fileName: strings.uploadYourFile
            })
        }

        if (this.state.document.url !== null) {
            this.setState({
                dataUrl: this.state.document.url
            })
        } else {
            this.setState({
                dataUrl: ''
            })
        }

    }

    fileChangeNameAndDocument = (e) => {
        this.displaySelectedFileName(e);
        this.onFileChangeHandler(e);
    }

    displaySelectedFileName = (e) => {
        let files = e.target.files
        this.setState({
            fileName: files[0].name
        })
    }

    onFileChangeHandler = (event) => {
        this.setState({file: event.target.files[0]})
    };

    saveDocument = () => {
        let form = new FormData();
        form.append("year", this.state.year);
        form.append("institutionId", this.state.institutionId);
        form.append("url", this.state.dataUrl)
        form.append("type", this.state.type.id)
        if (this.state.file != null) {
            form.append("attachment", this.state.file)
        }
        PublicDocumentsRegistryRepository.savePublicDocForYear(form).then(res => {
            toast.success(strings.succAdded);
            setTimeout(() => {
                window.location = '/publicdocument';
            }, 4000)
        })
    }


    render() {
        console.log(this.state.fileName)
        return (
            <div className="container">

                <div className="row mt-4">
                    <div className="col-3"/>
                    <div className="col-6">
                        <h4 style={{color: 'black'}}>{strings.publicDocType}: <span
                            style={{fontWeight: 'bold'}}>{this.state.type.name}</span></h4>
                        <h4 style={{color: 'black'}}>{strings.YearForPublicDocument}: <span
                            style={{fontWeight: 'bold'}}>{this.state.year}</span></h4>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-3"/>

                    <div className="col-6" style={{border: '1px solid black', borderRadius: '20px', padding: '20px'}}>

                        <div className="form-group">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.Url}</label>

                            <input
                                name={"url"}
                                type={"text"}
                                defaultValue={this.state.document.url ? this.state.document.url : ""}
                                className="form-control"
                                onChange={this.onChangeHandler}
                            />
                        </div>

                        <div className="form-group">
                            <label className="weight400 text-upper control-label control-label-xl margin-top-10"
                                   style={{fontWeight: 'bold'}}>{this.state.fileName}</label>

                            <input
                                id="attachment"
                                name={"attachment"}
                                type={"file"}
                                className="form-control"
                                onChange={this.fileChangeNameAndDocument}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3"/>
                    <div className="col-6 mt-5">
                        <button className="btn btn-info btn-lg defaultBtn"
                                onClick={this.saveDocument}>
                            <FontAwesomeIcon icon={faEdit} size="lg" style={{paddingRight: '4px'}}/>
                            {strings.save}
                        </button>
                        <a className="btn btn-lg ml-3 defaultBtn" href={"/publicdocument"}
                           style={{backgroundColor: 'gray', color: 'white'}}>
                            <FontAwesomeIcon icon={faArrowLeft} size="lg"
                                             style={{paddingRight: '4px'}}/>
                            {strings.goBack}
                        </a>
                    </div>
                </div>

            </div>
        );
    }
}

export default AddPublicDocument;