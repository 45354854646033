import React from "react";
import {strings} from "../../Localization/Localization";
import Select from 'react-select';
import MapEntity from "./MapEntity";
import './css/InstitutionEdited.css';
import {LOCALHOST} from "../../shared/utility";


const InstitutionEditedFields = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));

    return (
        <div className="col-12">

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.nameMk}</label>

                <input disabled

                       defaultValue={props.entity.nameMk}
                       name={"nameMk"}
                       type={"text"}
                       className="form-control"
                />
            </div>
            <br/>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.nameAl}</label>

                <input disabled

                       defaultValue={props.entity.nameAl}
                       name={"nameAl"}
                       type={"text"}
                       className="form-control"
                />
            </div>
            <br/>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.nameEn}</label>

                <input disabled

                       defaultValue={props.entity.nameEn}
                       name={"nameEn"}
                       type={"text"}
                       className="form-control"
                />
            </div>
            <br/>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.addressMk}</label>

                <input disabled

                       defaultValue={props.entity.addressMk}
                       name={"addressMk"}
                       type={"text"}
                       className="form-control"
                />
            </div>
            <br/>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.addressAl}</label>

                <input disabled

                       defaultValue={props.entity.addressAl}
                       name={"addressAl"}
                       type={"text"}
                       className="form-control"
                />
            </div>
            <br/>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.addressEn}</label>

                <input disabled

                       defaultValue={props.entity.addressEn}
                       name={"addressEn"}
                       type={"text"}
                       className="form-control"
                />
            </div>
            <br/>


            MK
            <div className="col-12" style={{border: '1px solid lightgray', borderRadius: '25px', paddingTop: '10px'}}>
                <div className="row">
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.typeStrMk}</label>

                        <input

                            disabled
                            defaultValue={props.entity.typeOfStreetMk}
                            placeholder={""}
                            name={"typeOfStreetMk"}
                            type={"text"}

                            className="form-control mt-3"
                        />
                    </div>
                    <div className="col-9 mt-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.strMk}</label>

                        <input

                            disabled
                            defaultValue={props.entity.streetMk}
                            placeholder={""}
                            name={"streetMk"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.streetNumMk}</label>
                        <input

                            disabled
                            defaultValue={props.entity.streetNumberMk ? (props.entity.streetNumberMk === "б.б." || props.entity.streetNumberMk === "б.б" || props.entity.streetNumberMk === "бб" || props.entity.streetNumberMk === "bb" || props.entity.streetNumberMk === "b.b" || props.entity.streetNumberMk === "b.b." || props.entity.streetNumberMk === "p.n." || props.entity.streetNumberMk === "p.n" || props.entity.streetNumberMk === "pn" ? "б.б." : props.entity.streetNumberMk) : ""}
                            placeholder={""}
                            name={"streetNumberMk"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                    <div className="col-9">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.detailsMk}</label>

                        <input

                            disabled
                            defaultValue={props.entity.addressDetailsMk}
                            placeholder={""}
                            name={"addressDetailsMk"}
                            type={"text"}
                            className="form-control mb-3"
                        />
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-4">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.postCode}</label>

                        <input

                            disabled
                            defaultValue={props.entity.postalCode}
                            placeholder={""}
                            name={"postalCode"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                    <div className="col-8">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.cityTownMk}</label>

                        <input

                            disabled
                            defaultValue={props.entity.cityMk}
                            placeholder={""}
                            name={"cityMk"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                </div>

            </div>

            <br/>
            AL
            <div className="col-12" style={{border: '1px solid lightgray', borderRadius: '25px', paddingTop: '10px'}}>
                <div className="row">
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.typeStrAl}</label>

                        <input

                            disabled
                            defaultValue={props.entity.typeOfStreetAl}
                            placeholder={""}
                            name={"typeOfStreetAl"}
                            type={"text"}

                            className="form-control mt-3"
                        />
                    </div>
                    <div className="col-9 mt-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.strAl}</label>

                        <input

                            disabled
                            defaultValue={props.entity.streetAl}
                            placeholder={""}
                            name={"streetAl"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.streetNumMk}</label>
                        <input

                            disabled
                            defaultValue={props.entity.streetNumberMk ? (props.entity.streetNumberMk === "б.б." || props.entity.streetNumberMk === "б.б" || props.entity.streetNumberMk === "бб" || props.entity.streetNumberMk === "bb" || props.entity.streetNumberMk === "b.b" || props.entity.streetNumberMk === "b.b." || props.entity.streetNumberMk === "p.n." || props.entity.streetNumberMk === "p.n" || props.entity.streetNumberMk === "pn" ? "p.n." : props.entity.streetNumberMk) : ""}
                            placeholder={""}
                            name={"streetNumberMk"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                    <div className="col-9">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.detailsAl}</label>

                        <input

                            disabled
                            defaultValue={props.entity.addressDetailsAl}
                            placeholder={""}
                            name={"addressDetailsAl"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-4">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.postCode}</label>

                        <input
                            disabled

                            defaultValue={props.entity.postalCode}
                            placeholder={""}
                            name={"postalCode"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                    <div className="col-8">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.cityTownAl}</label>

                        <input
                            disabled

                            defaultValue={props.entity.cityAl}
                            placeholder={""}
                            name={"cityAl"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                </div>
            </div>

            EN
            <div className="col-12" style={{border: '1px solid lightgray', borderRadius: '25px', paddingTop: '10px'}}>
                <div className="row">
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.typeStrEn}</label>

                        <input

                            disabled
                            defaultValue={props.entity.typeOfStreetEn}
                            placeholder={""}
                            name={"typeOfStreetEn"}
                            type={"text"}

                            className="form-control mt-3"
                        />
                    </div>
                    <div className="col-9 mt-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.strEn}</label>

                        <input

                            disabled
                            defaultValue={props.entity.streetEn}
                            placeholder={""}
                            name={"streetEn"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.streetNumMk}</label>
                        <input

                            disabled
                            defaultValue={props.entity.streetNumberMk ? (props.entity.streetNumberMk === "б.б." || props.entity.streetNumberMk === "б.б" || props.entity.streetNumberMk === "бб" || props.entity.streetNumberMk === "bb" || props.entity.streetNumberMk === "b.b" || props.entity.streetNumberMk === "b.b." || props.entity.streetNumberMk === "p.n." || props.entity.streetNumberMk === "p.n" || props.entity.streetNumberMk === "pn" ? "b.b." : props.entity.streetNumberMk) : ""}
                            placeholder={""}
                            name={"streetNumberMk"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                    <div className="col-9">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.detailsEn}</label>

                        <input
                            disabled
                            defaultValue={props.entity.addressDetailsEn}
                            placeholder={""}
                            name={"addressDetailsEn"}
                            type={"text"}
                            className="form-control mb-3"
                        />
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-4">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.postCode}</label>

                        <input
                            disabled
                            defaultValue={props.entity.postalCode}
                            placeholder={""}
                            name={"postalCode"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                    <div className="col-8">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.cityTownEn}</label>

                        <input
                            disabled
                            defaultValue={props.entity.cityEn}
                            placeholder={""}
                            name={"cityEn"}
                            type={"text"}

                            className="form-control mb-3"
                        />
                    </div>
                </div>

            </div>

            <br/>

            <div className="col-12">
                <div className="row">
                    <div className="col-9">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.phone}</label>

                        <input disabled
                               defaultValue={props.entity.institutionPhone}
                               name={"institutionPhone"}
                               type={"text"}
                               className="form-control mb-3"
                        />
                    </div>
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                        <input
                            disabled
                            defaultValue={props.entity.institutionLocales}
                            name={"institutionLocales"}
                            type={"text"}
                            className="form-control mb-3"
                        />
                    </div>
                </div>
            </div>

            <div className="col-12 mt-3">
                <div className="row">
                    <div className="col-9">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativePhone}</label>

                        <input
                            disabled
                            defaultValue={props.entity.institutionAlternativePhone}
                            name={"institutionAlternativePhone"}
                            type={"text"}
                            className="form-control mb-3"
                        />
                    </div>
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                        <input
                            disabled
                            defaultValue={props.entity.institutionAlternativeLocales}
                            name={"institutionAlternativeLocales"}
                            type={"text"}
                            className="form-control mb-3"
                        />
                    </div>
                </div>
            </div>

            <div className="col-12 mt-3">
                <div className="row">
                    <div className="col-9">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeSecondPhone}</label>

                        <input
                            disabled
                            defaultValue={props.entity.institutionAlternativeSecondPhone}
                            name={"institutionAlternativeSecondPhone"}
                            type={"text"}
                            className="form-control mb-3"
                        />
                    </div>
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                        <input
                            disabled
                            defaultValue={props.entity.institutionAlternativeSecondLocales}
                            name={"institutionAlternativeSecondLocales"}
                            type={"text"}
                            className="form-control mb-3"
                        />
                    </div>
                </div>
            </div>

            <div className="col-12 mt-3">
                <div className="row">
                    <div className="col-9">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeThirdPhone}</label>

                        <input
                            disabled
                            defaultValue={props.entity.institutionAlternativeThirdPhone}
                            name={"institutionAlternativeThirdPhone"}
                            type={"text"}
                            className="form-control mb-3"
                        />
                    </div>
                    <div className="col-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                        <input
                            disabled
                            defaultValue={props.entity.institutionAlternativeThirdLocales}
                            name={"institutionAlternativeThirdLocales"}
                            type={"text"}
                            className="form-control mb-3"
                        />
                    </div>
                </div>
            </div>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.textNote}</label>

                <textarea disabled
                          defaultValue={props.entity.textNotes}
                          name={"textNotes"}
                          rows={4}
                          className="form-control mb-3"
                />
            </div>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.textNoteAlbanian}</label>

                <textarea disabled
                          defaultValue={props.entity.textNotesAl}
                          name={"textNotesAl"}
                          rows={4}
                          className="form-control mb-3"
                />
            </div>

            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.webSite}</label>

                <input disabled

                       defaultValue={props.entity.webSite}
                       name={"webSite"}
                       type={"text"}
                       className="form-control mb-3"
                />
            </div>

            <div className="col-12 mt-3">
                <div className="row">
                    <div className="col-10 mt-3">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.infoC}</label>

                        <input
                            disabled
                            defaultValue={props.entity.infoCenter}
                            name={"infoCenter"}
                            type={"text"}
                            className="form-control mb-3"
                        />
                    </div>
                    <div className="col-2">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10 ml-4">{strings.infoNoticeBoard}</label>

                        <input
                            disabled
                            defaultValue={props.entity.noticeBoard}
                            name={"noticeBoard"}
                            type={"checkbox"}
                            checked={props.entity.noticeBoard}
                            className="form-control mb-3"
                        />
                    </div>
                </div>

            </div>

            {strings.fullDirector}
            <div className="col-12" style={{border: '1px solid lightgray', borderRadius: '25px', paddingTop: '10px'}}>
                <div className="row">
                    <div className="col-12">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.direktorFirstName}</label>

                        <input disabled

                               defaultValue={props.entity.direktorFirstName}
                               name={"direktorFirstName"}
                               type={"text"}
                               className="form-control mb-3"
                        />
                    </div>
                    <div className="col-12">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.direktorLastName}</label>

                        <input disabled

                               defaultValue={props.entity.direktorLastName}
                               name={"direktorLastName"}
                               type={"text"}
                               className="form-control mb-3"
                        />
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-9">
                                <label
                                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.direktorPhone}</label>

                                <input
                                    disabled
                                    defaultValue={props.entity.direktorPhone}
                                    name={"direktorPhone"}
                                    type={"text"}
                                    className="form-control mb-3"
                                />
                            </div>
                            <div className="col-3">
                                <label
                                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                                <input
                                    disabled
                                    defaultValue={props.entity.directorLocales}
                                    name={"directorLocales"}
                                    type={"text"}
                                    className="form-control mb-3"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.direktorEmail}</label>

                        <input disabled

                               defaultValue={props.entity.direktorEmail}
                               name={"direktorEmail"}
                               type={"text"}
                               className="form-control mb-3"
                        />
                    </div>

                </div>
            </div>


            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.tags}</label>
            </div>
            <div className="col-12">
                <MapEntity
                    className="disabled"
                    groupsUrl="/rest/tag/all" //za da gi zemem tagovite ili kategorija
                    entityUrl={"/rest/institution/selectedTags/{id}"} // za edit gi zema site selektirani za za taa institucija
                    entityId={props.entity.id}
                />

            </div>
            <br/>

            {props.entity.category !== undefined && props.entity.category !== null ?
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.institutionCat}</label>

                    <Select
                        placeholder={strings.searchForCategories}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={true}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name={"categoryId"}
                        defaultValue={props.entity.category !== null && props.entity.category !== undefined ? {
                            value: props.entity.category.id,
                            label: props.entity.category.nameMk,
                            name: "category"
                        } : ""}
                    />
                </div>
                :
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.parentInstitution}</label>

                    <Select
                        placeholder={strings.searchForInstitution}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={true}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name={"parentInstitutionId"}
                        defaultValue={props.entity.parentInstitution !== null && props.entity.parentInstitution !== undefined ? {
                            value: props.entity.parentInstitution.id,
                            label: props.entity.parentInstitution.nameMk,
                            name: "parentInstitution"
                        } : ""}

                    />
                </div>
            }

            {props.entity.attachment ?
                <div className="col-12 mt-3">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.documentSolution}</label>
                    <a href={LOCALHOST + "rest/attachment/download/" + props.entity.attachment.id}
                       target="_blank" rel="noopener noreferrer" className="form-control"
                       style={{color: "blue"}}>{props.entity.attachment.name}</a>
                </div>
            : ""}
        </div>

    );
};
export default InstitutionEditedFields;
