import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
    mk: {
        deleteQuestinForModal: "Дали сте сигурни дека сакате да го избришете прашањето ?",
        login: "Најава",
        email: "Е-пошта",
        alternativeEmail: "Алтернативна е-пошта",
        password: "Лозинка",
        rememberme: "Зачувај",
        forgotpassword: "Заборавена лозинка ?",
        loginButton: "Најави се",
        createAnnouncement: "Креирај соопштение",
        title: "Наслов",
        creator: "Автор",
        creatorFirstName: "Име на автор",
        creatorLastName: "Презиме на автор",
        body: "Содржина:",
        add: "Додади",
        uploadYourFile: "Прикачете го вашиот фајл ",
        upload: "Прикачи",
        name: "Име",
        nameMk: "Име на македонски",
        nameAl: "Име на албански",
        nameEn: "Име на англиски",
        mimeType: "Mime тип",
        size: "Големина",
        actions: "Опции",
        remove: "Избриши",
        dateCreated: "Датум на креирање",
        publishToInstitution: "Објави на институција",
        searchForInstitution: "Најди институција",
        publishToInstitutions: "Објави на институции",
        publishToTag: "Објави нa ознака",
        searchForTag: "Најди ознака",
        publishToTags: "Објави на ознаки",
        open: "Отвори",
        prev: "пред",
        next: "след",
        announcements: "Соопштенија",
        administration: "Администрација",
        announcement: "Соопштение",
        receiver: "Примач",
        read: "Прочитано",
        readAt: "Прочитано на",
        remind: "Потсети",
        escalete: "До директор",
        delete: "Избриши",
        history: "Историја",
        edit: "Измени",
        user: "Корисник",
        moderator: "Модератор",
        admin: "Администратор",
        publish: "Објави",
        publication: "Објавено соопштение",
        roles: "Тип на корисник",
        goBack: "Назад",
        addUser: "Додади корисник",
        addModerator: "Додади модератор",
        addAdmin: "Додади администратор",
        addTag: "Додади ознака",
        addInstitution: "Додади институција",
        attachment: "Документи",
        submit: "Поднеси",
        close: "Затвори",
        editUser: "Измени корисник",
        editModerator: "Измени модератор",
        editAdmin: "Измени администратор",
        editTag: "Измени ознака",
        editInstitution: "Измени институција",
        cancel: "Откажи",
        description: "Опис",
        dueDate: "Рок до",
        type: "Тип на прашање",
        dateUpdated: "Датум на ажурирање",
        SINGLE_CHOICE: "Избор на само една опција - radiobutton",
        MULTIPLE_CHOICE: "Избор на повеќе опции - checkbox",
        NUMERIC_FIELD: "Внесување на број",
        TEXT_FIELD: "Внес на текст - опис",
        options: "Опции",
        design: "Дизајн",
        analyse: "Анализирај",
        escalate: "До директор",
        searchForTags: "Најди ознака",
        youHavePublishedThisFeedbackToInstitutions: "Анкетата ја објавивте на следните институции:",
        youHavePublishedThisFeedbackToTags: "Анкетата ја објавивте на следните ознаки:",
        AllPublicationsForThisFeedback: "Сите објави за оваа анкета",
        AllPublicationsForThisProactivity: "Сите објави за оваа проактивност",
        AllPublicationsAnsweredForThisProactivity: "Сите одговори за оваа проактивност",
        users: "Корисници",
        institutions: "Институции",
        tags: "Ознаки",
        mypublications: "Мои соопштенија",
        allannouncements: "Сите соопштенија",
        feedbacks: "Анкети",
        proactiveTransparency: "Проактивна транспарентност",
        myfeedbackpublications: "Мои анкети",
        logout: "Одјава",
        forgottenPassword: "Заборавена лозинка?",
        enterEmail: "Внесете ја адресата на вашата Е-Пошта за да ја ресетирате лозинката: ",
        request: "Испрати",
        username: "Корисничко име",
        passHint: "Вашата лозинка треба да содржи од 6-12 карактери.",
        firstName: "Име",
        lastName: "Презиме",
        institution: "Институција",
        institutionPlaceholder: "Изберете институција",
        role: "Тип на корисник:",
        rolePlaceholder: "Изберете тип на корисник",
        responsiblePerson: "Службено лице",
        active: "Активен",
        newPassword: "Нова лозинка",
        repeatPassword: "Повтори лозинка",
        change: "Измени",
        removeAttachmentPrompt: "Дали сте сигурни дека сакате да го избришете овој прикачен документ ?",
        removePublicationPrompt: "Дали сте сигурни дека сакате да ја избришете оваа публикација за ова соопштение ?",
        answer: "Одговори",
        previewAnswer: "Прегледај одговори",
        title_dksk: "Државна комисија за спречување на корупција",
        subtitle_dksk: "Портал за комуникација со институции",
        title_komspi: "Агенција за заштита на правото на слободен пристап до информациите од јавен карактер",
        subtitle_komspi: "",
        NotReaded: "Сеуште не е прочитано",
        error: "Грешка",
        errorOpeningAnnouncementPub: "Грешка при отворање на соопштението на публикацијата",
        failedToLoadAnnouncementPub: "Не успеа да се вчита соопштението на публикацијата",
        successDeletedAnnounPub: "Успешно избришана соопштението на публикацијата",
        failedToDeleteAnnounPub: "Не успеа да ce избрише соопштението на публикацијата",
        successfullySentMail: "Успешно испратена e-пошта",
        failedToSendMail: "Не успеа да испрати е-поштатa",
        successfullyAddedAnnouncement: "Успешно додадено соопштение",
        failedToAddAnnouncement: "Не успеа да се додаде соопштението",
        failedToLoadAttachments: "Не успеа да се вчитаат документите",
        successfullyUploadedFile: "Успешно прикачен фајл",
        failedToUploadFile: "Не успеа да се прикачи фајлот",
        successfullDownloadedFile: "Успешно преземен фајл",
        failedToDownloadAttachment: "Не успеа да се преземе фајлот",
        errorWhileLoadingAnnouncement: "Грешка при вчитување на соопштението",
        errorWhileLoadingInstitution: "Грешка при вчитување на институција",
        errorWhileLoadingTags: "Грешка при вчитување на ознаките",
        successfullyPublishedAnnouncement: "Успешно објавено соопштение",
        failedToPublishAnnouncement: "Не успеа да објави соопштението",
        successfullyChangedPassword: "Успешно сменета лозинка",
        failedToChangePassword: "Не успеа да се промени лозинката",
        deleteModal: "Успешно избришано",
        deleteModalfailed: "Бришењето е неуспешно",
        editModal: "Успешно изменето",
        addModal: "Успешно додадено",
        failedToLoadData: "Не успеа да се вчитаат податоците",
        successfullyAddedFeedbackItem: "Успешно додадено прашање",
        failedToAddFeedbackItem: "Не успеа да se додаде прашањето",
        successfullyEditedFeedbackItem: "Прашањето е успешно изменето",
        failedToEditFeedbackItem: "Не успеа да се измени прашањето",
        successfullyDeletedFeedbackItem: "Прашањето е успешно избришано",
        failedToDeleteFeedbackItem: "Не успеа да се избрише прашањето",
        successfullyAddedFeedback: "Анкетата е успешно додадена",
        successfullyAddedProactivity: "Активноста е успешно додадена",
        feedbackSuccessfullyEdited: "Анкетата е успешно изменета",
        successfullyDeletedFeedback: "Анкетата е успешно избришана",
        successfullyDeletedProactivity: "Активноста е успешно избришана",
        failedToDeleteFeedback: "Не успеа да се избрише анкетата",
        failedToAddFeedback: "Не успеа да се додаде анкетата",
        successfullyDeletedFeedbackPublication: "Успешно избришана анкета",
        failedToDeleteFeedbackPublication: "Не успеа да ce избрише анкетата",
        errorWhileLoadingFeedback: "Грешка при вчитување анкетата",
        errorWhileLoadingInstitutions: "Грешка при вчитување на институциите",
        successfullyPublishedFeedback: "Успешно објавенa анкета",
        failedToPublishFeedback: "Не успеа да се објави анкетата",
        failedToLoadAnswers: "Hе успеа да ce вчитат одговорите",
        successfullySubmittedAnswers: "Успешно доставени одговори",
        failedTSubmitAnswers: "Не успеа да доставaт одговорите! Имате задолжителни но неодговорени прашања.",
        failedToGetFeedbackId: "Не успеа да се добие идентификациониот број на анкетата",
        AnalyseFeedbackModalTitle: "Одговориле:",
        allAnswered: "Одговориле",
        comments: "Коментари",
        madePost: "коментираше",
        comment: "Коментирај",
        commentPlaceholder: "Напишете го вашиот коментар",
        successfullComent: "Успешно коментиравте",
        failedToComment: "Коментирањето е неуспешно",
        publicDocuments: "Јавни документи",
        PublicDocumentsRegistry: "Регистрирање на јавни документи",
        YearForPublicDocument: "Година на документот",
        PublicDocumentType: "Тип на документот",
        PublicDocumentTypes: "Тип на јавни документи",
        Url: "Линк",
        save: "Зачувај",
        successAddPubDocType: "Успешно додаден тип на јавен документ",
        failAddPubDocType: "Неуспешно додавање тип на јавен документ",
        successEditPubDocType: "Успешно изменет тип на јавен документ",
        editPubDocType: "Неуспешно изменет тип на јавен документ",
        selectYear: "Одберете година",
        showUrls: "Претходно внесени линкови на вашата институција",
        show: "Прикажи",
        nameMK: "Име на Македонски",
        nameAL: "Име на Албански",
        nameEN: "Име на Англиски",
        institutionCategory: "Категории на институции",
        institutionCat: "Категориja на институциja",
        heading: "Насока на категорија",
        address: "Адреса",
        phone: "Тел. број",
        alternativePhone: "Втор тел. број",
        alternativeSecondPhone: "Трет тел. број",
        alternativeThirdPhone: "Четврти тел. број",
        webSite: "Веб сајт",
        invalidEmailOrPassword: "Погрешна е-пошта или лозинка",
        faq: "Најчесто поставувани прашања",
        deleteAnnouncement: "Дали сте сигурни дека сакате да го избришете соопштението ?",
        delAnn: "Избриши соопштение",
        direktorFirstName: "Име на директор",
        direktorLastName: "Презиме на директор",
        direktorEmail: "Е-пошта на директор",
        direktorPhone: "Тел бр. на директор",
        moderatorFirstName: "Име на службено лице",
        moderatorLastName: "Презиме на службено лице",
        moderatorPhone: "Тел бр. на службено лице",
        moderatorAlternativePhone: "Прв алтернативен тел бр. на службено лице",
        moderatorAlternativeSecondPhone: "Втор алтернативен тел. бр. на службено лице",
        moderatorAlternativeThirdPhone: "Трет алтернативен тел. бр. на службено лице",
        moderatorEmail: "Примарна е-пошта на службено лице",
        moderatorSecond: "Прва алтернативна е-пошта на службено лице",
        moderatorThird: "Втора алтернативна е-пошта на службено лице",
        moderatorForth: "Трета алтернативна е-пошта на службено лице",
        searchby: "Изберете опција за пребарување",
        firstlastname: "Име и презиме",
        titleofann: "Наслов на соопштение",
        all: "Погледни ги сите",
        egflname: "пр.Петко Петковски",
        egann: "пр. Соопштение",
        report: "Извештај",
        selectedTags: "Избрани ознаки",
        selectedCategories: "Избрани категории",
        selectedInstitutions: "Избрани институции",
        questionn: "Прашање",
        reply: "Одговор",
        addNewEntry: "Додади ново прашање",
        changePassword: "Промени лозинка",
        profile: "Профил",
        logged: "Најавен",
        more: "Повеќе",
        publishedAnnouncements: "Нема објавени соопштенија",
        editAnnouncement: "Измени соопштение",
        editAnnouncementSuccessful: "Соопштението е успешно ажурирано",
        editAnnouncementFailed: "Ажурирањето е неуспешно",
        publishAnnouncement: "Објави соопштение",
        designFeedbackItem: "Додади прашања за анкетата",
        designProactiveItem: "Додади прашања",
        nameQuestion: "Име на прашање",
        descQuestion: "Опис на прашање",
        nameFeedback: "Име на анкета",
        descShortFeedback: "Краток опис на анкета",
        noPublishedFeedbacks: "Нема објавени анкети",
        noInstitution: "Нема институција",
        required: "Задолжително",
        numberOnly: "Ве молиме внесете само валиден број",
        preview: "Преглед",
        NoneAnsweredFeedback: "Сеуште нема одговори на анкетата",
        export: "Превземи резултати",
        monitoringOnPublicBodies: "Мониторинг на иматели",
        questionMonitoring: "Колку институции имаат поставено некаков документ или линк",
        yes: "Да",
        no: "Не",
        deleteFeedbackPub: "Дали сте сигурни дака сакате да ја избришете објавата за анкетата ?",
        deletePubDocType: "Дали сте сигурни дака сакате да го избришете типот за јавни документи ?",
        failedToAddDocType: "Неуспешно додавање на тип на јавен документ",
        failedToEditDocType: "Неуспешно ажурирање на тип на јавен документ",
        successToAddDocType: "Успешно додавање на тип на јавен документ",
        successToEditDocType: "Успешно ажурирање на тип на јавен документ",
        publicDocType: "Тип на јавен документ",
        successfullyAddedTag: "Успешно додавање ознака",
        failedToAddTag: "Неуспешно дадавање на ознака",
        successfullyEditedTag: "Успешно е ажурирана ознаката",
        failedToEditTag: "Неуспешно ажурирање на ознаката",
        tag: "Ознака",
        tagInstitutions: "Институции кои припаѓаат во оваа ознака",
        pleaseChooseInstitution: "Ве молиме одберете институција",
        noUrl: "Нема линк",
        noPDF: "Нема PDF",
        uploadPDF: "Прикачи PDF",
        successfullyAddedLink: "Успешно додавање линк",
        successfullyAddedPdf: "Успешно прикачување документ",
        failedToAddLink: "Неуспешно додавање линк",
        failedToAddPdf: "Неуспешно прикачување документ",
        pdfDoc: "Pdf документ",
        annualReport: "Годишен извештај",
        chooseHeading: "Изберете насока на категорија",
        institutionName: "Име на институција",
        submitter: "Подносител",
        status: "Статус",
        form: "Форма",
        file: "Фајл",
        submitted: "Поднесен",
        inProgress: "Во изработка",
        review: "Прегледај",
        print: "Печати",
        podatociSluzbLice: "Податоци за службените лица определени за посредување со информации кај имателите на информации:",
        brPrimeniBaranja: "Број на примени барања:",
        brPozitivnoOdgBaranja: "Број на позитивно одговорени барања:",
        odbieniIOtfrleniZalbi: "Број на одбиени и отфрлени барања со наведување на причините за секое одбиено или отфрлено барање:",
        brNeodogovoreniBaranja: "Број на неодговорени барања:",
        vlozeniZalbi: "Број на вложени жалби против првостепени одлуки (решенија на имателите на информации, со опис на одлуката, како и со наведување на причините за донесената одлука во случај на повторно одбивање на бараната информација):",
        brUsvoeniZalbi: "Број на усвоени жалби со одлука на Агенцијата:",
        brPreinaceniOdluki: "Број на преиначени првостепени одлуки по постапување на Агенцијата:",
        odbieniZalbi: "Број на одбиени жалби од Агенцијата и причини за нивно одбивање:",
        otfrelniZalbi: "Број на отфрлени жалби од Агенцијата и причини за нивно отфрлање: ",
        message: "Порака",
        parentInstitution: "Родител на институција",
        typeParent: "Тип на родител",
        searchForCategories: "Најди категорија",
        inactiveUsers: "Неактивни корисници",
        activate: "Активирај",
        activateUser: "Активирај корисник",
        successActivation: "Успешно активарње на корисник",
        failedActivation: "Неуспешно активирање на корисник",
        searchByInst: "Најди корисници по институција",
        successfullyAdInstitution: "Успешно додавање на институција",
        successfullyEditInstitution: "Успешно ажурирање на институција",
        failEditInstitution: "Неуспешно ажурирање на институција",
        failAddInstitution: "Неуспешно додавање на институција",
        download: "Превземи",
        signedDocument: "Потпишан документ",
        noAnnualReportsFoundForThisYear: "Не се пронајдени извештаи за оваа година",
        addressMk: "Адреса на македонски јазик",
        addressAl: "Адреса на албански јазик",
        addressEn: "Адреса на англиски јазик",
        notEntered: "Не е внесено",
        successfullyAddedUser: "Успешно додавање на корисник",
        successfullyEditedUser: "Успешно ажурирање на корисник",
        failedToAddUser: "Неуспешно додавање на корисник",
        failedToEditUser: "Неуспешно ажурирање на корисник",
        search: "Пребарувај",
        parentCategory: "Родител на категорија",
        noParentCategory: "Нема родител категорија",
        failedToSubmitReport: "Неуспешно поднесување на извештај",
        deleteTag: "Избриши ознака",
        noPublishedFAQ: "Нема поставени прашања",
        userSuccessfullyDeleted: "Корисникот е успешно избришан",
        userNotDeleted: "Корисникот не е избришан",
        deleteQuestionForUser: "Дали сте сигурни дека сакате да го избришете овој корисник ?",
        userActivate: "Дали сте сигурни дека сакате да го активирате овој корисник ?",
        justDelete: "Избриши",
        deleteUser: "Избриши корисник",
        successfullyDeletedTag: "Ознаката е успешно избришана",
        tagNotDeleted: "Ознаката не е избришана",
        questionDeleteTag: "Дали сакате да ja избришете ознаката?",
        agencyName: "Агенција за заштита на правото на слободен пристап до информациите од јавен карактер",
        categoryInstDelete: "Категоријата на институција е успешно избришана",
        categoryInstNotDelete: "Категоријата не е избришана бидејќи има поврзани институции и под категории",
        deleteCatQuestion: "Дали сакате да ја избришете категоријата ?",
        institutionDeleted: "Институцијата е успешно избришана",
        institutionNotDeleted: "Институцијата не е избришана",
        instDeleteQuestion: "Дали сакате да ја избришете институцијата?",
        deleteFeedbackQuestion: "Дали сте сигурни дека сакате да ја избришете оваа повратна информација?",
        reportExists: "Годишен извештај за оваа година веќе постои",
        failTryAgain: "Не успешно, обидете се повторно",
        reportHere: "Извештај постои",
        succAdded: "Успешно додадено!",
        noFileAdded: "Немате додадено фајл",
        succChanged: "Успешно изменето!",
        categoryInstAdded: "Категоријата на институција е успешно додадена",
        categoryInstEdit: "Категоријата на институција е успешно изменета",
        invalidInput: "Невалиден внес во полето",
        activatedUser: "Корисникот е активиран",
        deactivatedUser: "Корисникот не е активиран",
        deleteAttachedDocument: "Успешно избришан фајл",
        mision: "МИСИЈА",
        misionText: "Континуирано спроведување, промоција и заштита на правото на слободен пристап до информации од јавен карактер во насока на зголемување на транспарентноста и отчетноста на институциите и олеснување на пристапот до информациите од јавен карактер.",
        vision: "ВИЗИЈА",
        visionText: "Институционална препознатливост на Агенцијата како кредибилен афирматор и заштитник на правото на слободен пристап до информации од јавен карактер.",
        resetPassSuccessful: "Вашето барање е процесирано, ве молиме проверете ја вашата електронска пошта",
        resetPassNotSuccessful: "Вашето барање е неуспешно",
        pleaseWait: "Ве молиме почекајте...",
        successfullyDeletedQuestion: "Успешно избришано прашање",
        UnsuccessfullyDeletedQuestion: "Прашањето не е избришано",
        deleteQuestionForQuestion: "Дали сте сигурни дека сакате да го избришете прашањето?",
        noPublishedQuestions: "Нема објавени прашања",
        questions: "Прашања",
        Answers: "Одговори на прашањето",
        noInactiveQuestions: "Нема неактивни прашања",
        shareAgain: "Објави повторно",
        shared: "Прашањето е повторно објавено",
        notShared: "Прашањето не е објавено",
        allInactive: "Неактивни прашања",
        allActive: "Прашања",
        noInst: "Нема институција",
        succAddQuestion: "Успешно додадено прашање",
        unSucAddQuestion: "Неуспешно додадено прашање",
        succEditQuestion: "Успешно изменето прашање",
        unSucEditQuestion: "Неуспешно изменето прашање",
        succEditMessage: "Пораката е успешно изменета",
        unSuccEditMessage: "Пораката не е изменета",
        messageBody: "Порака: ",
        authorCreator: "Автор: ",
        datePublish: "Датум на објава: ",
        messageSuccDeleted: "Пораката е успешно избришана",
        messageUnSuccDeleted: "Пораката не е избришана",
        messageSuccSharedAgain: "Пораката е повторно објавена",
        messageUnSuccSharedAgain: "Пораката не е објавена",
        deleteMessageForMessage: "Дали сте сигурни дека сакате да ја избришете пораката?",
        shareMessageForMessage: "Дали сакате да ја објавите пораката повторно?",
        editedInstitutions: "Изменети институции",
        deleteChanges: "Дали сте сигурни дека сакате да ги отфлите промените?",
        seeChanges: "Види промени",
        institutionAlreadyChanged: "Поднесени се промени за институцијата, Ве молиме почекајте.",
        strMk: "Улица(село) македонски",
        strAl: "Улица(село) албански",
        typeStrMk: "Бул. / Ул. македонски",
        typeStrAl: "Бул. / Ул. албански",
        streetNumMk: "Број",
        detailsMk: "Детали македонски",
        detailsAl: "Детали албански",
        cityTownMk: "Место - македонски",
        cityTownAl: "Место - албански",
        postCode: "Поштенски број",
        fullDirector: "Директор",
        enterValidNumber: "Ве молиме внесете валиден број.",
        myInst: "Моја институција",
        infoNoticeBoard: "Огласна табла",
        listOfInformations: "Листата на информации од јавен карактер се наоѓа на",
        evaluator: "Евалуатор",
        moderatorEvaluator: "Службено лице и евалуатор",
        allReports: "Извештаи",
        strEn: "Улица(село) англиски",
        typeStrEn: "Бул. / Ул. англиски",
        detailsEn: "Детали англиски",
        cityTownEn: "Место - англиски",
        streetMac: "Ул.",
        boulevardMac: "Бул.",
        chooseStr: "",
        chooseRole: "Изберете дополнителна улога",
        localFirst: "Локал",
        other: "Друго",
        infoC: "Останато",
        additionalRole: "Дополнителна улога",
        simpleRole: "Едноставна улога",
        questionsRole: "Специјална улога (поставување и одговарање прашања)",
        createReport: "Креирај извештај",
        noReports: "Сеуште нема извештаи",
        detailsForReport: "Детали за извештајот",
        succAddReport: "Успешно креиран извештај",
        unSucAddReport: "Извештајот не е креиран",
        yearCreate: "Година на креирање",
        deleteReportForReport: "Дали сте сигурни дека сакате да го избришете овој извештај ?",
        succDeleteReport: "Извештајот е избришан",
        unsuccDeleteReport: "Извештајот не е избришан",
        addCriteria: "Додади критериум",
        parentToCriteria: "Родител на критериум",
        evaluate: "Евалуирај",
        allCriterias: "Листа на критериуми",
        succDeletedReport: "Критериумот е деактивиран",
        deleteCriteriaForCriteria: "Дали сте сигурни дека сакате да го отстраните овој критериум од листата на критериуми за евалуација ?",
        succReturned: "Критериумот е вратен",
        shareCriteria: "Дали сте сигурни дека сакате да го вратите овој критериум за евалуирање ?",
        oneCriteria: "Критериум",
        score: "Оцена",
        link: "Линк",
        comm: "Коментар",
        evaluated: "Евалуирано",
        saveEvaluateReport: "Испрати извештај на евалуација",
        sendEvaluation: "Извештајот е испратен на евалуација",
        myReports: "Мои извештаи",
        reportsForEvalation: "Извештаи за евалуација",
        alreadySent: "Поднесен за евалуација",
        userNotified: "Завршено",
        institutionsInEvaluation: "Институции во евалуација",
        notEvaluated: "Неевалуирано",
        shareReport: "Дали сте сигурни дека сакате да го испратите овој извештај на евалуација ?",
        share: "Испрати",
        saveEvaluation: "Извештајот е зачуван",
        saveReport: "Затвори извештај",
        shareEvaluatedReport: "Дали сте сигурни дека сакате да го затворите овој извештај ?",
        takeLook: "Прегледај",
        evaluatedFinished: "Евалуиран и завршен",
        takeResults: "Превземи резултати",
        generateResults: "Генерирај резулати",
        exportCSV: "Превземи детален ексел",
        exportSingle: "Превземи ексел за селектирана институција",
        textNote: "Забелешки",
        responsiblePersons: "Одговорни лица",
        versonForPerson: "Реден број",
        removeUserInstitution: "Отстрани",
        confirmRemoveUserInstitution: "Дали сте сигурни дека сакате да го отстраните ова службено лице од оваа институција ?",
        addResponsiblePerson: "Додади службено лице за оваа институција",
        responsiblePersonInstitution: "Службени лица за институција: ",
        canCreateReport: "Може да креира извештај",
        canNotCreateReport: "Не може да креира извештај",
        removeFeedback: "Дали сте сигурни дека сакате да ја избришете оваа анкета ?",
        removeProactivity: "Дали сте сигурни дека сакате да ја избришете оваа проактивна транспарентност ?",
        usersPersons: "Корисници - службени лица за Институцијата",
        enterNewEntry: "За да внесете нова опција, притиснете ентер - потребен е нов ред",
        mustAnswer: "Прашањето мора да биде одговорено",
        reviewQuestions: "Прегледај анкета",
        inactiveUsersInstitution: "Неактивни корисници за оваа институција",
        noData: "Нема податоци",
        editedInstitutionsFromUsers: "Изменети институции од службени лица кои ги смениле информациите",
        pleaseEnterEmail: "Ве молиме внесете валидна е-маил адреса.",
        institutionsInCategory: "Институции во категоријата",
        childCategories: "Поткатегории на категоријата",
        reportsForYear: "Извештаи за година",
        shareQuestionAgain: "Дали сакате повторно да го објавите прашањето ?",
        evaluation: "Евалуација",
        sendNotificationForDocuments: "Испраќање автоматизирани барања за евалуација",
        successRequest: "Вашето барање е успешно, испратена е порака до избраните институции",
        allRequests: "Сите барања",
        answeredInDays: "Одговориле на барањата: ",
        notAnswered: "Не одговориле",
        answeredFive: "Одговориле за 5 дена",
        answeredTen: "Одговориле за 10 дена",
        answeredFifteen: "Одговориле за 15 дена",
        dateSent: "Датум на испраќање",
        startEvaluationProcess: "Дали сакате да започнете со евалуација на извештајот ?",
        startEvaluation: "Започни евалуација",
        pleaseChooseTags: "Ве молиме одберете ознаки",
        pleaseChooseCategories: "Ве молиме одберете категории",
        publishToCategories: "Објави на категории",
        deactivateCriteria: "Деактивирај критериум",
        deactivate: "Деактивирај",
        optionsForType: "Опции за тип",
        addOption: "Додади опции",
        details: "Детали",
        valueOption: "Вредност",
        typesAnswers: "Типови на одговори",
        deleteCriteriaType: "Дали сте сигурни дека сакате да го избришете овој тип на одговор ?",
        deleteOption: "Дали сте сигурни дека сакате да ја избришете оваа опција ?",
        subQuest: "Подпрашање",
        importantInfo: "Важна информација за корисниците!",
        ePortalForInfoHolders: "Е-Портал за Имателите",
        info: "Почитувани, тековната верзија на Порталот за иматели на информации од јавен карактер е во фаза на изработка и истата ќе биде достапна во својата конечна верзија до крајот на 2021 година.",
        lawExample: "Член 10: Посредување на информации",
        predefinedTitle: "Предефиниран наслов дефиниран од страна на ...",
        bodyOfMessage: "Тело на пораката кое може да биде променето бидејки...",
        author: "Автор",
        aspi: "А.С.П.И.",
        uploadYourPDFFile: "Прикачете го вашиот фајл(.pdf) ",
        textNoteAlbanian: "Забелешки - албански",
        firstNameAlbanian: "Име - албански",
        lastNameAlbanian: "Презиме - албански",
        documentSolution: "Документ/решение",
        userResetOrTokenExpired: "Лозинката за корисникот е веќе ресетирана или токенот е невалиден.",
        attachDocInstitution: "Задолжително прикачето го потпишаното и архивираното решение за измена на податоците",
        answered: "Одговорено",
        activityName: "Име на активност",
        evaluationYear: "Година на евалуација",
        compareData: "Спореди",
        addUserFile: "Задолжително прикачето го потпишаното и архивираното решение за именување на службено лице",
        noPublishedTransparencies: "Нема објавена проактивна транспарентност",
        noDocumentAttached: "Нема прикачено документ",
        changesDeclined: "Промените за институцијата се отфрлени.",
        throwOutChanges: "Отфрли промени",
        link1: "1. Податоците од неговите надлежности кои ги извршува, односно му се утврдени со закон",
        link2: "2. Основните податоци за контакт со имателот на информацијата и тоа: назив, адреса, " +
            "телефонски број, број на факс, е-маил адреса и адресата на интернет страницата",
        link3: "3. Податоците за функционерот или одговорното лице кај имателот на информацијата " +
            "(биографија, податоци за контакт и друго)",
        link4: "4. Основните податоци за контакт со службеното лице за посредување со " +
            "информации и тоа: име и презиме, е-маил адреса и телефонски број",
        link5: "5. Основните податоци за контакт со лице овластено за заштитено внатрешно " +
            "пријавување и тоа: име и презиме, е-маил адреса и телефонски број",
        link6: "6. Список на лица вработени кај имателот на информацијата со позиција, службен емаил и службен телефон",
        link7: "7. Законите кои се однесуваат на надлежноста на имателот на информации, поврзани со регистарот на прописи објавени во службеното гласило",
        link8: "8. Прописите што во рамките на својата надлежност ги донесува имателот на " +
            "информацијата во вид на подзаконски акт: правилници (правилник за внатрешна " +
            "организација, правилник за систематизација на работните места, правилник за заштитено " +
            "внатрешно пријавување и друго), уредби, наредби, упатства, планови, програми, решенија " +
            "и други видови акти за извршување на законите и други прописи, кога за тоа се овластени " +
            "со закон",
        link9: "9. Органограм за внатрешна организација",
        link10: "10. Стратешки планови и стратегии за работа на имателите на информации",
        link11: "11. Годишни планови и програми за работа",
        link12: "12. Годишен Буџет и завршна сметка",
        link13: "13. Годишни финансиски планови по квартали и програми за реализација на буџетот",
        link14: "14. Ревизорски извештај",
        link15: "15. Видови услуги кои ги даваат имателите на информации (информации за физичката " +
            "достапност за остварување на услугите и информации за е-услугите, податоците за " +
            "законските основи, називот на услугите, документите и податоците потребни за " +
            "остварување на секоја од услугите, механизмите за правна заштита и други релевантни " +
            "податоци)",
        link16: "16. Тарифници за надоместоци за издавање на реални акти",
        link17: "17. Начинот на поднесување на барање за пристап до информации (начин на " +
            "поднесување усно и писмено барање за пристап до информации, како и барање поднесено " +
            "по електронски пат)",
        link18: "18. Целокупната документација за јавните набавки, за концесиите и за договорите за " +
            "јавно-приватно партнерство",
        link19: "19. Предлог на програми, програми, ставови, мислења, студии и други слични " +
            "документи кои се однесуваат на актите од надлежноста на имателот на информации " +
            "соопштенија до јавноста за работи преземени од нивната интернет страница во согласност " +
            "со законските надлежности, информативни билтени, службени гласила ако се обврска " +
            "согласно закон и друго",
        link20: "20. Извештаи за работа кои ги поднесуваат до органите надлежни за спроведување " +
            "контрола и надзор",
        link21: "21. Статистички податоци за работата, како и други информации, акти и мерки со кои " +
            "се влијае на животот и работата на граѓаните и кои произлегуваат од надлежноста и " +
            "работата на имателот на информации",
        link22: "22. Други информации кои произлегуваат од надлежноста и работата на имателот на " +
            "информацијата",
        tutorial: "Упатство",
    },
    al: {
        throwOutChanges: "Отфрли промени",
        changesDeclined: "Промените за институцијата се отфрлени.",
        noDocumentAttached: "Нема прикачено документ",
        noPublishedTransparencies: "Нема објавена проактивна транспарентност",
        addUserFile: "Задолжително прикачето го потпишаното и архивираното решение за именување на службено лице",
        compareData: "Спореди",
        evaluationYear: "Година на евалуација",
        userNotified: "Завршено",
        AllPublicationsAnsweredForThisProactivity: "Сите одговори за оваа проактивност",
        activityName: "Име на активност",
        answered: "Одговорено",
        allAnswered: "Одговориле",
        successfullyAddedProactivity: "Активноста е успешно додадена",
        successfullyDeletedProactivity: "Активноста е успешно избришана",
        removeProactivity: "Дали сте сигурни дека сакате да ја избришете оваа проактивна транспарентност ?",
        AllPublicationsForThisProactivity: "Сите објави за оваа проактивност",
        designProactiveItem: "Додади прашања",
        proactiveTransparency: "Проактивна транспарентност",
        attachDocInstitution: "Është e detyrueshme të bashkëngjitet vendimi i nënshkruar dhe i arkivuar për ndryshimin e të dhënave",
        userResetOrTokenExpired: "Fjalëkalimi i përdoruesit është rivendosur tashmë ose token është i pavlefshëm.",
        documentSolution: "Dokument/vendim",
        firstNameAlbanian: "Име - албански",
        lastNameAlbanian: "Презиме - албански",
        textNoteAlbanian: "Забелешки - албански",
        uploadYourPDFFile: "Ngarkoni fajllin tuaj(.pdf) ",
        alternativeEmail: "E-adresa alternative",
        aspi: "A.S.P.I.",
        author: "Autor",
        bodyOfMessage: "Trupi i mesazhit që mund të ndryshohet sepse ...",
        predefinedTitle: "Titull i paracaktuar i definuar nga ...",
        lawExample: "Neni 10: Ndërmjetësim të informacioneve",
        info: "Të nderuar, versioni aktual i Portalit për poseduesit e informacioneve me karakter publik është në fazën e përpunimit dhe i njejti do të jetë në dispozicion me versionin përfundimtar deri në fund të vitit 2021.",
        ePortalForInfoHolders: "E-Portal për Poseduesit",
        importantInfo: "Informacion i rëndësishëm për përdoruesit!",
        subQuest: "Nënpyetje",
        deleteOption: "Jeni të sigurt që dëshironi ta fshini këtë opsion?",
        deleteCriteriaType: "Jeni të sigurt që dëshironi ta fshini këtë lloj të përgjigjes ?",
        typesAnswers: "Llojet e përgjigjeve",
        valueOption: "Vlerë",
        details: "Detaje",
        optionsForType: "Opsionet për llojin",
        addOption: "Shto opsione",
        deactivateCriteria: "Deaktivizo kriter",
        deactivate: "Deaktivizo",
        publishToCategories: "Publiko në kategori",
        pleaseChooseTags: "Ju lutemi zgjedhni etiketa (tagje)",
        pleaseChooseCategories: "Ju lutemi zgjedhni kategori",
        startEvaluation: "Fillo evaluim",
        startEvaluationProcess: "Dëshironi të filloni me evaluimin e raportit ?",
        dateSent: "Data e dërgimit",
        answeredInDays: "I janë përgjigjur kërkesave: ",
        notAnswered: "Nuk janë përgjigjur",
        answeredFive: "Janë përgjigjur për 5 ditë",
        answeredTen: "Janë përgjigjur për 10 ditë",
        answeredFifteen: "Janë përgjigjur për 15 ditë",
        allRequests: "Të gjitha kërkesat",
        successRequest: "Kërkesa juaj ka qenë e suksesshme, është dërguar mesazh deri te institucionet e zgjedhura",
        misionText: "Zbatim, promovim dhe mbrojtje e vazhdueshme e të drejtës për qasje të lirë të informacioneve me karakter publik në  drejtim të rritjes së transparencës dhe llogaridhënies së institucioneve dhe lehtësimit të qasjes së informacioneve me karakter publik.",
        visionText: "Njohje institucionale e Agjencisë si një pohues i besueshëm dhe mbrojtës i të drejtës për qasje të lirë në informacione me karakter publik. ",
        sendNotificationForDocuments: "Dërgim i kërkesave të automatizuara për evaluim (vlerësim)",
        evaluation: "Evaluimi (Vlerësimi)",
        shareQuestionAgain: "Jeni i sigurt që dëshironi ta ndani përsëri pyetjen?",
        reportsForYear: "Raportet për vitin",
        institutionsInCategory: "Institucionet në këtë kategori",
        childCategories: "Nënkategoritë e kategorisë",
        pleaseEnterEmail: "Ju lutemi shkruani një e-meil adresë të vlefshme.",
        editedInstitutionsFromUsers: "Institucione të ndryshuara nga persona zyrtarë të cilët i kanë ndryshuar informacionet",
        noData: "Nuk ka të dhëna",
        inactiveUsersInstitution: "Përdorues joaktivë për këtë institucion",
        all: "Shikoji të gjitha",
        searchby: "Zgjedhni një opsion kërkimi",
        reviewQuestions: "Rishiko anketën",
        SINGLE_CHOICE: "Zgjedhje e vetëm një opsioni - radiobutton",
        MULTIPLE_CHOICE: "Zgjedhje e disa opsioneve - checkbox",
        NUMERIC_FIELD: "Vendosje e numrit",
        TEXT_FIELD: "Vendosje e tekstit - përshkrimit",
        mustAnswer: "Pyetja duhet patjetër të jetë e përgjigjur",
        enterNewEntry: "Për të futur një opsion të ri, shtypni enter - kërkohet një rresht i ri",
        usersPersons: "Përdoruesit - personat zyrtarë për Institucionin",
        removeFeedback: "Jeni të sigurt që dëshironi ta fshini këtë anketë?",
        canCreateReport: "Mund të krijojë raport",
        canNotCreateReport: "Nuk mund të krijojë raport",
        responsiblePersonInstitution: "Personat zyrtarë për institucionin: ",
        addResponsiblePerson: "Shtoni person zyrtarë për këtë institucion",
        confirmRemoveUserInstitution: "Jeni të sigurt që dëshironi ta largoni këtë person zyrtar nga ky institucion?",
        removeUserInstitution: "Largo",
        versonForPerson: "Numri rendor",
        responsiblePersons: "Personat përgjegjës",
        textNote: "Vërejtje",
        exportSingle: "Shkarkoni eksell (excel) për institucionin e zgjedhur",
        exportCSV: "Shkarkoni eksell (excel) të detajuar",
        generateResults: "Gjeneroni rezultate",
        takeResults: "Shkarkoni rezultatet",
        evaluatedFinished: "Vlerësuar dhe përfunduar",
        takeLook: "Rishiko",
        saveReport: "Mbylle raport",
        shareEvaluatedReport: "Jeni të sigurt që dëshironi ta ruani këtë raport ?",
        saveEvaluation: "Raporti është ruajtur",
        share: "Dërgo",
        shareReport: "Jeni të sigurt që dëshironi ta dërgoni këtë raport vlerësimi?",
        notEvaluated: "E pavlerësuar",
        institutionsInEvaluation: "Institucionet nën vlerësim",
        alreadySent: "Dorëzuar për vlerësim",
        myReports: "Raportet e mia",
        reportsForEvalation: "Raportet për vlerësim",
        sendEvaluation: "Raporti është dërguar për vlerësim",
        saveEvaluateReport: "Dërgoni një raport vlerësimi",
        evaluated: "Vlerësuar",
        score: "Vlerësimi",
        link: "Link",
        comm: "Koment",
        oneCriteria: "Kriteri",
        shareCriteria: "Jeni të sigurt që dëshironi ta ktheni këtë kriter për vlerësim ?",
        succReturned: "Kriteri është kthyer",
        succDeletedReport: "Kriteri është çaktivizuar",
        deleteCriteriaForCriteria: "Jeni të sigurt që dëshironi ta largoni këtë kriter nga lista e kritereve për vlerësim ?",
        allCriterias: "Lista e kritereve",
        evaluate: "Vlerëso",
        parentToCriteria: "Prindi i kriterit",
        addCriteria: "Shto një kriter",
        succDeleteReport: "Raporti është fshirë",
        unsuccDeleteReport: "Raporti nuk është fshirë",
        deleteReportForReport: "Jeni të sigurt që dëshironi ta fshini këtë raport ?",
        yearCreate: "Viti i krijimit",
        succAddReport: "Raporti është krijuar me sukses",
        unSucAddReport: "Raporti nuk është krijuar",
        detailsForReport: "Detaje për raportin",
        noReports: "Ende nuk ka raporte",
        createReport: "Krijo raport",
        additionalRole: "Rol shtesë",
        simpleRole: "Rol i thjeshtë",
        questionsRole: "Rol special (parashtrim dhe përgjigje të pyetjeve)",
        moderatorAlternativeThirdPhone: "Numri i tretë alternativ i telefonit i personit zyrtar",
        cityTownEn: "Vendi - në gjuhën angleze",
        cityTownMk: "Vendi - në gjuhën maqedonase",
        cityTownAl: "Vendi - në gjuhën shqipe",
        infoC: "Të mbetura",
        other: "Të tjera",
        localFirst: "Lokal",
        chooseStr: "",
        chooseRole: "Zgjedhni rol shtesë",
        moderatorSecond: "E-posta e parë alterantive e personit zyrtar",
        moderatorThird: "E-posta e dytë alternative e personit zyrtar",
        moderatorForth: "E-posta e tretë alternative e personit zyrtar",
        addressEn: "Adresa në gjuhën angleze",
        strEn: "Rruga(fshati) në gjuhën angleze",
        typeStrEn: "Bul. / Rr. në gjuhën angleze",
        detailsEn: "Detaje në gjuhën angleze",
        streetMac: "Rr.",
        boulevardMac: "Bou.",
        allReports: "Raporte",
        moderatorEvaluator: "Person zyrtar dhe evaluator (vlerësues)",
        evaluator: "Evaluator (Vlerësues)",
        listOfInformations: "Lista e informacioneve të karakterit publik gjendet në",
        infoNoticeBoard: "Tabela e shpalljeve",
        myInst: "Institucioni im",
        enterValidNumber: "Ju lutemi shënoni numër valid.",
        fullDirector: "Drejtor",
        strMk: "Rruga(fshati) në gjuhën maqedonase",
        strAl: "Rruga(fshati) në gjuhën shqipe",
        typeStrMk: "Bul. ose Rr. në gjuhën maqedonase",
        typeStrAl: "Bul. ose Rr. në gjuhën shqipe",
        streetNumMk: "Numri i rrugës",
        detailsMk: "Detaje në gjuhën maqedonase",
        detailsAl: "Detaje në gjuhën shqipe",
        postCode: "Numri postal",
        institutionAlreadyChanged: "Janë dorëzuar ndryshimet për institucionin, ju lutemi prisni.",
        seeChanges: "Shiko ndryshimet",
        deleteChanges: "Jeni të sigurt që dëshironi ti anuloni ndryshimet?",
        editedInstitutions: "Institucionet e ndryshuara",
        successfullyDeletedQuestion: "Pyetja u fshi me sukses",
        UnsuccessfullyDeletedQuestion: "Pyetja nuk është fshirë",
        deleteQuestionForQuestion: "Jeni të sigurt që doni ta fshini pyetjen?",
        noPublishedQuestions: "Nuk ka pyetje të publikuara",
        questions: "Pyetje",
        Answers: "Përgjigjet e pyetjes",
        noInactiveQuestions: "Nuk ka pyetje joaktive",
        shareAgain: "Publiko përsëri",
        shared: "Pyetja është publikuar përsëri",
        notShared: "Pyetja nuk është publikuar",
        allInactive: "Pyetje joaktive",
        allActive: "Pyetjet",
        noInst: "Nuk ka institucion",
        succAddQuestion: "Pyetja u shtua me sukses",
        unSucAddQuestion: "Shtimi i pyetjes ishte i pasuksesshëm",
        succEditQuestion: "Pyetja u ndryshua me sukses",
        unSucEditQuestion: "Ndryshimi i pyetjes ishte i pasuksesshëm",
        succEditMessage: "Pyetja u ndryshua me sukses",
        unSuccEditMessage: "Mesazhi nuk është ndryshuar",
        messageBody: "Mesazhi: ",
        authorCreator: "Autori: ",
        datePublish: "Data e publikimit: ",
        messageSuccDeleted: "Mesazhi është fshirë me sukses",
        messageUnSuccDeleted: "Mesazhi nuk është fshirë",
        messageSuccSharedAgain: "Mesazhi është publikuar përsëri",
        messageUnSuccSharedAgain: "Mesazhi nuk është publikuar",
        deleteMessageForMessage: "Jeni të sigurt që doni ta fshini mesazhin?",
        shareMessageForMessage: "Dëshironi që ta publikoni mesazhin përsëri?",
        pleaseWait: "Ju lutemi prisni...",
        resetPassNotSuccessful: "Kërkesa juaj ishte e pasuksesshme",
        resetPassSuccessful: "Kërkesa juaj u procesua, ju lutemi shikoni postën tuaj elektronike",
        mision: "MISIONI",
        vision: "VIZIONI",
        deleteAttachedDocument: "Fajlli u fshi me sukses",
        activatedUser: "Përdoruesi u aktivizua",
        deactivatedUser: "Përdoruesi nuk është i aktivizuar",
        invalidInput: "Keni shënuar të dhëna jovalide në këtë fushë",
        categoryInstEdit: "Kategoria e institucionit është ndryshuar me sukses",
        categoryInstAdded: "Kategoria e institucionit u shtua me sukses",
        succChanged: "Ndryshuar me sukses!",
        noFileAdded: "Nuk keni shtuar fajll",
        succAdded: "U shtua me sukses!",
        reportHere: "Ekziston raport",
        failTryAgain: "E pasuksesshme, provoni përsëri",
        reportExists: "Ekziston raport vjetor për këtë vit",
        deleteFeedbackQuestion: "Jeni të sigurt që doni ta fshini këtë vlerësim (informatë kthyese)?",
        instDeleteQuestion: "Dëshironi ta fshini institucionin?",
        institutionNotDeleted: "Institucioni nuk është fshirë",
        institutionDeleted: "Institucioni është fshirë me sukses",
        categoryInstNotDelete: "Kategoria nuk është fshirë për shkak se ka të lidhura institucione dhe ka nën-kategori",
        deleteCatQuestion: "Dëshironi ta fshini kategorinë?",
        categoryInstDelete: "Kategoria e institucionit është fshirë me sukses",
        agencyName: "Agjencioni për mbrojtjen e të drejtës për qasje të lirë në informata me karakter publik",
        questionDeleteTag: "Dëshironi ta fshini etiketën?",
        tagNotDeleted: "Etiketa nuk është fshirë",
        successfullyDeletedTag: "Etiketa është fshirë me sukses",
        deleteUser: "Fshije përdoruesin",
        justDelete: "Fshij",
        userActivate: "Jeni të sigurt që dëshironi ta aktivizoni këtë përdorues?",
        deleteQuestionForUser: "Jeni të sigurt që dëshironi ta fshini këtë përdorues?",
        noAnnualReportsFoundForThisYear: "Nuk u gjetën raporte për këtë vit",
        userNotDeleted: "Përdoruesi nuk është fshirë",
        userSuccessfullyDeleted: "Përdoruesi është fshirë me sukses",
        addNewEntry: "Shto pyetje të re",
        noPublishedFAQ: "Nuk ka pyetje të parashtruara",
        deleteTag: "Fshij etiketë",
        failedToSubmitReport: "Dorëzimi i raportit ishte i pasuksesshëm",
        noParentCategory: "Nuk ka kategori prind",
        parentCategory: "Prindi i kategorisë",
        search: "Kërko",
        successfullyAddedUser: "Përdoruesi u shtua me sukses",
        successfullyEditedUser: "Përdoruesi i azhurnua me sukses",
        failedToAddUser: "Dështoi shtimi i përdoruesit",
        failedToEditUser: "Dështoi azhurnimi i përdoruesit",
        failAddInstitution: "Dështoi shtimi i institucionit",
        successfullyAdInstitution: "Institucioni u shtua me sukses",
        notEntered: "Nuk është vendosur",
        addressMk: "Adresa në gjuhën maqedonase",
        addressAl: "Adresa në gjuhën shqipe",
        successfullyEditInstitution: "Institucioni u azhurnua me sukses",
        failEditInstitution: "Azhurnimi i institucionit ishte i pasuksesshëm",
        searchByInst: "Gjeni përdoruesit sipas institucionit",
        successActivation: "Aktivizim i suksesshëm i përdoruesit",
        failedActivation: "Aktivizim i pasuksesshëm i përdoruesit",
        activateUser: "Aktivizo përdorues",
        activate: "Aktivizo",
        inactiveUsers: "Përdorues joaktiv",
        searchForCategories: "Gjej kategorinë",
        typeParent: "Lloji i prindit",
        parentInstitution: "Prindi i institucionit",
        message: "Mesazh",
        chooseHeading: "Zgjedhni drejtimin e kategorisë",
        pdfDoc: "Pdf dokument",
        successfullyAddedLink: "Linku u shtua me sukses",
        successfullyAddedPdf: "Dokumenti u ngarkua me sukses",
        failedToAddLink: "Dështoi shtimi i link-ut",
        failedToAddPdf: "Dështoi ngarkimi i dokumentit",
        uploadPDF: "Ngarko PDF",
        noPDF: "Nuk ka PDF",
        noUrl: "Nuk ka link",
        pleaseChooseInstitution: "Ju lutemi zgjedhni institucionin",
        successfullyAddedTag: "Etiketa u shtua me sukses",
        failedToAddTag: "Shtimi i etiketës ishte i pasuksesshëm",
        successfullyEditedTag: "Etiketa u azhurnua me sukses",
        failedToEditTag: "Azhurnimi i etiketës ishte i pasuksesshëm",
        tag: "Etiketë",
        tagInstitutions: "Institucione të cilat i përkasin kësaj etikete",
        publicDocType: "Lloji i dokumentit publik",
        deleteFeedbackPub: "Jeni të sigurtë që doni të fshini publikimin për anketën?",
        deletePubDocType: "Jeni të sigurtë që dëshironi të fshini llojin për dokumente publike?",
        failedToAddDocType: "Dështoi shtimi i llojit të dokumentit publik",
        failedToEditDocType: "Dështoi azhurnimi i llojit të dokumentit publik",
        successToAddDocType: "Lloji i dokumentit publik u shtua me sukses",
        successToEditDocType: "Lloji i dokumentit publik u azhurnua me sukses",
        yes: "Po",
        no: "Jo",
        questionMonitoring: "Sa institucione kanë  vendosur ndonjë dokument ose link",
        monitoringOnPublicBodies: "Monitorim i poseduesve",
        NoneAnsweredFeedback: "Ende nuk ka përgjigje për anketën",
        preview: "Pamje paraprake",
        designFeedbackItem: "Shto pyetje për anketën",
        nameQuestion: "Emri i pyetjes",
        descQuestion: "Përshkrimi i pyetjes",
        nameFeedback: "Emri i anketës",
        descShortFeedback: "Përshkrim i shkurtër i anketës",
        noInstitution: "Nuk ka institucion",
        deleteQuestinForModal: "Jeni të sigurt që dëshironi ta fshini ?",
        login: "Kyçu",
        email: "E-Adresa",
        password: "Fjalëkalimi",
        rememberme: "Më ruaj",
        forgotpassword: "Keni harruar fjalëkalimin ?",
        loginButton: "Kyçu",
        createAnnouncement: "Krijo njoftim",
        title: "Titulli",
        creator: "Autori",
        body: "Përmbajtja",
        add: "Shto",
        uploadYourFile: "Ngarkoni fajllin tuaj",
        upload: "Ngarko",
        name: "Emri",
        nameMk: "Emri në maqedonisht",
        nameAl: "Emri në shqip",
        nameEn: "Emri në anglisht",
        mimeType: "Mime lloji",
        size: "Madhësia",
        actions: "Opsione",
        remove: "Fshij",
        dateCreated: "Data e krijimit",
        publishToInstitution: "Publiko në institucion",
        searchForInstitution: "Kërko institucion",
        publishToInstitutions: "Publiko tek institucionet",
        publishToTag: "Publiko në etiketë",
        searchForTag: "Kërko për etiketë",
        publishToTags: "Publiko tek etiketat",
        open: "Hape",
        prev: "I mëparshëm",
        next: "I ardhshëm",
        announcement: "Njoftime",
        announcements: "Njoftimet",
        administration: "Administrata",
        receiver: "Pranues",
        read: "Lexuar",
        readAt: "Lexuar më",
        remind: "Rikujto",
        escalete: "Te drejtori",
        delete: "Fshij",
        history: "Historia",
        edit: "Ndrysho",
        user: "Përdorues",
        moderator: "Moderator",
        admin: "Administrator",
        publish: "Publiko",
        publication: "Njoftim i publikuar",
        roles: "Lloji i përdoruesit",
        goBack: "Shko pas",
        addUser: "Shto përdorues",
        addModerator: "Shto moderator",
        addAdmin: "Shto administrator",
        addTag: "Shto etiketë",
        addInstitution: "Shto institucion",
        attachment: "Shto dokumente",
        submit: "Dorëzo",
        close: "Mbylle",
        editUser: "Ndrysho perdoruesin",
        editModerator: "Ndrysho moderatorin",
        editAdmin: "Ndrysho administratorin",
        editTag: "Ndrysho etiketën",
        editInstitution: "Ndrysho institucionin",
        cancel: "Largo",
        dueDate: "Afati deri",
        description: "Përshkrimi",
        type: "Lloji",
        dateUpdated: "Data e ndryshimit",
        options: "Opsione",
        design: "Dizajn",
        analyse: "Analizo",
        escalate: "Te drejtori",
        searchForTags: "Kërko për etiketë",
        youHavePublishedThisFeedbackToInstitutions: "Anketën e keni shpallur në institucionet në vijim:",
        youHavePublishedThisFeedbackToTags: "Anketën e keni shpallur në etiketat në vijim:",
        AllPublicationsForThisFeedback: "Gjithë shpalljet për këtë anketë",
        users: "Perdoruesit",
        institutions: "Institucionet",
        tags: "Etiketat",
        mypublications: "Njoftimet e mia",
        allannouncements: "Gjitha njoftimet",
        feedbacks: "Anketat",
        myfeedbackpublications: "Anketat e mia",
        logout: "Ç'kyçu",
        forgottenPassword: "Fjalëkalim i harruar?",
        enterEmail: "Vendosni E-adresen tuaj në mënyrë që ta resetoni fjalëkalimin ",
        request: "Dërgo",
        username: "Emri i përdoruesit",
        passHint: "Fjalëkalimi juaj duhet të përmbajë prej 6 deri 12 karaktere.",
        firstName: "Emri",
        lastName: "Mbiemri",
        institution: "Institucioni",
        institutionPlaceholder: "Zgjedhni institucionin",
        role: "Lloji i përdoruesit:",
        rolePlaceholder: "Zgjedhni llojin e përdoruesit",
        responsiblePerson: "Person zyrtar",
        active: "Aktiv",
        newPassword: "Fjalëkalim i ri",
        repeatPassword: "Përsërit fjalëkalimin",
        change: "Ndrysho",
        removeAttachmentPrompt: "Jeni të sigurt që doni të fshini këtë dokument të bashkangjitur ?",
        removePublicationPrompt: "Jeni të sigurt që doni të fshini publikimin për këtë njoftim ?",
        answer: "Përgjigju",
        previewAnswer: "Rishikoni përgjigjet",
        title_dksk: "Agjension për qasje të informacioneve të karakterit publik",
        subtitle_dksk: "Portal për komunikim me institucionet",
        title_komspi: "Agjension për mbrojtjen e së drejtës për qasje të lirë të informacionit publik",
        subtitle_komspi: "",
        NotReaded: "Ende nuk është lexuar",
        error: "Gabim",
        errorOpeningAnnouncementPub: "Gabim gjatë hapjes së publikacionit të njoftimeve",
        failedToLoadAnnouncementPub: "Nuk arriti të ngarkojë publikacionin e njoftimeve",
        successDeletedAnnounPub: "Publikacioni i njoftimit është fshirë me sukses",
        failedToDeleteAnnounPub: "Nuk arriti të fshihet publikacioni i njoftimeve",
        successfullySentMail: "Është dërguar me sukses e-posta",
        failedToSendMail: "Dështoi dërgimi i  e-postës",
        successfullyAddedAnnouncement: "Njoftimi ështê shtuar me sukses",
        failedToAddAnnouncement: "Dështoi shtimi i njoftimit",
        failedToLoadAttachments: "Dështoi leximi i dokumenteve",
        successfullyUploadedFile: "Fajll i ngarkuar me sukses",
        failedToUploadFile: "Dështoi ngarkimi i këtij fajlli",
        successfullDownloadedFile: "Fajll i shkarkuar me sukses",
        failedToDownloadAttachment: "Dështoi shkarkimi i fajllit",
        errorWhileLoadingAnnouncement: "Gabim gjatë leximit të njoftimit",
        errorWhileLoadingInstitution: 'Gabim gjatë leximit të institucionit',
        errorWhileLoadingTags: "Gabim gjatë leximit të etiketave",
        successfullyPublishedAnnouncement: "Njoftimi u publikua me sukses",
        failedToPublishAnnouncement: "Dështoi publikimi i njoftimit",
        successfullyChangedPassword: "Fjalëkalimi u ndryshua me sukses",
        failedToChangePassword: "Dështoi ndryshimi i fjalëkalimit",
        deleteModal: "Fshirë me sukses",
        deleteModalfailed: "Fshierja ishte e pasuksesshme!",
        editModal: "Ndryshuar me sukses",
        addModal: "Shtuar me sukses",
        failedToLoadData: "Nuk u arrit të lexohen të dhënat",
        successfullyAddedFeedbackItem: "Me sukses u shtua pyetja",
        failedToAddFeedbackItem: "Dështoi shtimi i pyetjes",
        successfullyEditedFeedbackItem: "Pyetja u ndryshua me sukses",
        failedToEditFeedbackItem: "Dështoi ndryshimi i pyetjes",
        successfullyDeletedFeedbackItem: "Pyetja u fshi me sukses",
        failedToDeleteFeedbackItem: "Dështoi të fshihet pyetja",
        successfullyAddedFeedback: "Anketa u shtua me sukses",
        feedbackSuccessfullyEdited: "Anketa u ndryshua me sukses",
        successfullyDeletedFeedback: "Anketa u fshi me sukses",
        failedToDeleteFeedback: "Dështoi te fshihet anketa",
        failedToAddFeedback: "Dështoi shtimi i anketës",
        successfullyDeletedFeedbackPublication: "Anketa u fshi me sukses",
        failedToDeleteFeedbackPublication: "Dështoi të fshihet anketa",
        errorWhileLoadingFeedback: "Gabim gjatë leximit te anketës",
        errorWhileLoadingInstitutions: "Gabim gjatë leximit të institucioneve",
        successfullyPublishedFeedback: "Аnketa është publikuar me sukses",
        failedToPublishFeedback: "Dështoi publikimi i anketës",
        failedToLoadAnswers: "Dështoi leximi i përgjigjeve",
        successfullySubmittedAnswers: "Pëgjigjet u dorëzuan me sukses",
        failedTSubmitAnswers: "Dështoi dorëzimi i përgjigjeve! Ju keni pyetje të detyrueshme, por pa përgjigje. ",
        failedToGetFeedbackId: "Dështoi marrja e numrit identifikues të anketës",
        AnalyseFeedbackModalTitle: "Janë përgjigju:",
        comments: "Komentet",
        madePost: "Komentoi",
        comment: "Komento",
        commentPlaceholder: "Shënoni komentin tuaj",
        successfullComent: "Komentuat me sukses",
        failedToComment: "Dështoi komentimi",
        PublicDocumentTypes: "Lloji i dokumenteve publike",
        publicDocuments: "Dokumente publike",
        PublicDocumentsRegistry: "Regjistrimi i dokumenteve publike",
        YearForPublicDocument: "Viti i dokumentit",
        PublicDocumentType: "Lloji i dokumentit",
        Url: "Linku",
        save: "Ruaj",
        successAddPubDocType: "Me sukses u shtua lloji i dokumentit publik",
        failAddPubDocType: "Shtim i pasuksesshëm i llojit të dokumentit publik",
        successEditPubDocType: "Me sukses u ndryshua lloji i dokumentit publik",
        editPubDocType: "Ndryshimi i llojit të dokumentit publik ishte i pasuksesshëm",
        selectYear: "Zgjedhni vitin",
        showUrls: "Linqet e vendosura më parë në institucionin tuaj",
        show: "Shfaq",
        nameMK: "Emri në maqedonisht",
        nameAL: "Emri në shqip",
        nameEN: "Emri në anglisht",
        institutionCategory: "Kategoritë e institucioneve",
        institutionCat: "Kategoria e institucionit",
        heading: "Drejtimi i kategorisë",
        address: "Adresa",
        phone: "Numri i telefonit",
        alternativePhone: "Numri i telefonit 2",
        alternativeSecondPhone: "Numri i telefonit 3",
        alternativeThirdPhone: "Numri i telefonit 4",
        webSite: "Ueb sajt",
        invalidEmailOrPassword: "E-Adresë ose fjalëkalim i gabuar",
        faq: "Pyetjet e bëra më shpesh",
        deleteAnnouncement: "Jeni të sigurt që dëshironi të fshini njoftimin ?",
        delAnn: "Fshije njoftimin",
        direktorFirstName: "Emri i drejtorit",
        direktorLastName: "Mbiemri i drejtorit",
        direktorEmail: "E-adresa e drejtorit",
        direktorPhone: "Numri i telefonit i drejtorit",
        moderatorFirstName: "Emri i personit zyrtar",
        moderatorLastName: "Mbiemri i personit zyrtar",
        moderatorPhone: "Numri i telefonit i personit zyrtar",
        moderatorAlternativePhone: "Numri i telefonit i personit zyrtar 2",
        moderatorAlternativeSecondPhone: "Numri i telefonit i personit zyrtar 3",
        moderatorEmail: "Fillore e-adresa e personit zyrtar",
        creatorFirstName: "Emri i autorit",
        creatorLastName: "Mbiemri i autorit",
        firstlastname: "Emër dhe mbiemër",
        titleofann: "Titull të njoftimit",
        egflname: "p.sh. Petko Petkovski",
        egann: "p.sh. Njoftim",
        report: "Raport",
        selectedTags: "Etiketat e zgjedhura",
        selectedCategories: "Kategoritë e zgjedhura",
        selectedInstitutions: "Institucionet e zgjedhura",
        questionn: "Pyetje",
        reply: "Përgjigje",
        changePassword: "Ndrysho fjalëkalimin",
        profile: "Profili",
        logged: "I kyçur",
        more: "Më shumë",
        publishedAnnouncements: "Nuk ka njoftime të publikuara",
        editAnnouncement: "Ndrysho njoftimin",
        editAnnouncementSuccessful: "Njoftimi u azhurnua me sukses",
        editAnnouncementFailed: "Azhurnimi ishte i pasuksesshëm",
        publishAnnouncement: "Publiko njoftimin",
        noPublishedFeedbacks: "Nuk ka anketa të publikuara",
        required: "E detyrueshme",
        numberOnly: "Ju lutemi shkruani vetëm numër të vlefshëm",
        annualReport: "Raport vjetor",
        institutionName: "Emri i institucionit",
        submitter: "Parashtruesi",
        status: "Statusi",
        form: "Formulari",
        file: "Fajlli",
        submitted: "Dorëzuar",
        inProgress: "Në përpunim",
        review: "Rishiko",
        print: "Printo",
        podatociSluzbLice: "Të dhëna për personat zyrtar të caktuar për të ndërmjetësuar me informacione tek poseduesit e informacioneve:",
        brPrimeniBaranja: "Numri i kërkesave të pranuara: ",
        brPozitivnoOdgBaranja: "Numri i kërkesave me përgjigje pozitive:",
        odbieniIOtfrleniZalbi: "Numri i kërkesave të refuzuara dhe të hedhura poshtë duke cekur arsyet për secilën kërkesë të refuzuar apo të hedhur poshtë:",
        brNeodogovoreniBaranja: "Numri i kërkesave pa përgjigje:",
        vlozeniZalbi: "Numri i ankesave kundër vendimeve të shkallës së parë (vendimet e poseduesve të informacioneve, me përshkrim të vendimit, si dhe duke i cekur arsyet për vendimin e marë në rast kur përsëri bëhet refuzim për informacionin e kërkuar):",
        brUsvoeniZalbi: "Numri i ankesave të miratuara me vendim të Agjencisë:",
        brPreinaceniOdluki: "Numri i vendimeve të modifikuara të shkallës së parë pas veprimit të Agjencisë:",
        odbieniZalbi: "Numri i ankesave të refuzuara nga Agjencia dhe arsyet e refuzimit të tyre:",
        otfrelniZalbi: "Numri i ankesave të hedhura poshtë nga Agjencia dhe arsyet e hedhjes poshtë të tyre:",
        download: "Shkarko",
        signedDocument: "Dokument i nënshkruar",
    },
    en: {
        documentSolution: "Document/decision",
        firstNameAlbanian: "First name - albanian",
        lastNameAlbanian: "Last name - albanian",
        textNoteAlbanian: "Notes - albanian",
        uploadYourPDFFile: "Upload your file(.pdf) ",
        aspi: "A.S.P.I.",
        importantInfo: "Important information for the users!",
        ePortalForInfoHolders: "E-portal for holders of information",
        info: "Dear guests, the current version of the Portal for holders of public information is under construction and will be available in its latest version by the end of 2021. ",
        subQuest: "Subquestion",
        deleteOption: "Are you sure that you want to delete this option ?",
        deleteCriteriaType: "Are you sure that you want to delete this type of answer ?",
        typesAnswers: "Types answers",
        valueOption: "Value",
        details: "Details",
        optionsForType: "Options for type",
        addOption: "Add options",
        deactivateCriteria: "Deactivate criteria",
        deactivate: "Deactivate",
        publishToCategories: "Publish to categories",
        pleaseChooseTags: "Please, choose tags",
        pleaseChooseCategories: "Please, choose categories",
        startEvaluation: "Start evaluation",
        startEvaluationProcess: "Are you sure that you want to start the evaluation process ?",
        dateSent: "Date of sending",
        answeredInDays: "Answered on requests: ",
        notAnswered: "Not answered",
        answeredFive: "Answered in 5 days",
        answeredTen: "Answered in 10 days",
        answeredFifteen: "Answered in 15 days",
        allRequests: "All requests",
        successRequest: "Your request is successful, e-mail is sent to selected institutions",
        sendNotificationForDocuments: "Sending automatic requests for evaluation",
        evaluation: "Evaluation",
        shareQuestionAgain: "Are you sure that you want to share the question again ?",
        reportsForYear: "Reports for year",
        institutionsInCategory: "Institutions in category",
        childCategories: "Subcategories of category",
        pleaseEnterEmail: "Please enter valid email address.",
        editedInstitutionsFromUsers: "Edited institutions from responsible people who changed the information",
        noData: "No data available",
        inactiveUsersInstitution: "Inactive users for this institution",
        reviewQuestions: "Preview feedback",
        mustAnswer: "Question must be answered",
        enterNewEntry: "For adding new option, press enter - new line is necessary",
        usersPersons: "Users - responsible people for the Institution",
        removeFeedback: "Are you sure that you want to delete this feedback ?",
        canCreateReport: "Can create report",
        canNotCreateReport: "Can not create report",
        responsiblePersonInstitution: "Responsible people for Institution: ",
        addResponsiblePerson: "Add responsible person for this institution",
        versonForPerson: "Serial number",
        removeUserInstitution: "Remove",
        confirmRemoveUserInstitution: "Are you sure that you want to remove this person from this institution ?",
        responsiblePersons: "Responsible persons",
        textNote: "Notes",
        exportSingle: "Download excel for selected Institution",
        exportCSV: "Download detailed excel",
        generateResults: "Generate results",
        takeResults: "Download results",
        evaluatedFinished: "Evaluated and finished",
        takeLook: "Preview",
        saveReport: "Close report",
        shareEvaluatedReport: "Are you sure that you want to save this report ?",
        saveEvaluation: "The report is saved",
        share: "Share",
        shareReport: "Are you sure that you want to send this report for evaluation ?",
        notEvaluated: "Not evaluated",
        institutionsInEvaluation: "Institutions in evaluation",
        alreadySent: "Sent for evaluation",
        myReports: "My reports",
        reportsForEvalation: "Reports for evaluation",
        sendEvaluation: "The report is sent for evaluation",
        saveEvaluateReport: "Send report for evaluation",
        evaluated: "Evaluated",
        score: "Score",
        link: "Link",
        comm: "Comment",
        oneCriteria: "Criteria",
        shareCriteria: "Are you sure that you want to share this criteria for evaluation again ?",
        succReturned: "The criteria is returned",
        succDeletedReport: "The criteria is inactivated",
        deleteCriteriaForCriteria: "Are you sure that you want to remove this criteria from the list of criteria for evaluation ?",
        allCriterias: "List of criterias",
        evaluate: "Evaluate",
        parentToCriteria: "Parent criteria",
        addCriteria: "Add criteria",
        succDeleteReport: "The report is deleted",
        unsuccDeleteReport: "The report is not deleted",
        deleteReportForReport: "Are you sure that you want to delete this report?",
        yearCreate: "Year of creation",
        succAddReport: "Успешно креиран извештај",
        unSucAddReport: "Извештајот не е креиран",
        detailsForReport: "Details for report",
        noReports: "There aren't any reports yet",
        createReport: "Create report",
        additionalRole: "Additional role",
        simpleRole: "Simple role",
        questionsRole: "Special role (asking and answering questions)",
        infoC: "Rest",
        other: "Other",
        localFirst: "Local",
        streetMac: "Str.",
        boulevardMac: "Bou.",
        chooseStr: "",
        chooseRole: "Choose additional role",
        deleteQuestinForModal: "Are you sure that you want to delete the question ?",
        login: "Log in",
        email: "E-mail",
        password: "Password",
        rememberme: "Remember me",
        forgotpassword: "Forgot password ?",
        loginButton: "Log in",
        createAnnouncement: "Create announcement",
        title: "Title",
        creator: "Creator",
        creatorFirstName: "Creator first name",
        creatorLastName: "Creator last name",
        body: "Body:",
        add: "Add",
        uploadYourFile: "Upload your file ",
        upload: "Upload",
        name: "Name",
        nameMk: "Name - macedonian",
        nameAl: "Name - albanian",
        nameEn: "Name - english",
        mimeType: "Mime type",
        size: "Size",
        actions: "Actions",
        remove: "Remove",
        dateCreated: "Date of creation",
        publishToInstitution: "Publish to institution",
        searchForInstitution: "Search for institution",
        publishToInstitutions: "Publish to institutions",
        publishToTag: "Publish to tag",
        searchForTag: "Search for tag",
        publishToTags: "Publish to tags",
        open: "Open",
        prev: "Previous",
        next: "Next",
        announcements: "Announcements",
        administration: "Administration",
        announcement: "Announcement",
        receiver: "Receiver",
        read: "Read",
        readAt: "Read at",
        remind: "Remind",
        escalete: "To director",
        delete: "Delete",
        history: "History",
        edit: "Edit",
        user: "User",
        moderator: "Moderator",
        admin: "Administrator",
        publish: "Publish",
        publication: "Published announcement",
        roles: "Type of user",
        goBack: "Go back",
        addUser: "Add user",
        addModerator: "Add moderator",
        addAdmin: "Add administrator",
        addTag: "Add tag",
        addInstitution: "Add institution",
        attachment: "Attachments",
        submit: "Submit",
        close: "Close",
        editUser: "Edit user",
        editModerator: "Edit moderator",
        editAdmin: "Edit administrator",
        editTag: "Edit tag",
        editInstitution: "Edit institution",
        cancel: "Cancel",
        description: "Description",
        dueDate: "Due date",
        type: "Type of question",
        dateUpdated: "Date of update",
        SINGLE_CHOICE: "One option - radiobutton",
        MULTIPLE_CHOICE: "Multiple choice - checkbox",
        NUMERIC_FIELD: "Numeric field",
        TEXT_FIELD: "Text field - description",
        options: "Options",
        design: "Design",
        analyse: "Analyse",
        escalate: "To director",
        searchForTags: "Search for tags",
        youHavePublishedThisFeedbackToInstitutions: "You have published the announcement to these institutions:",
        youHavePublishedThisFeedbackToTags: "You have published the announcement to these tags:",
        AllPublicationsForThisFeedback: "All publications for this feedback",
        users: "Users",
        institutions: "Institutions",
        tags: "Tags",
        mypublications: "My announcements",
        allannouncements: "All announcements",
        feedbacks: "Feedbacks",
        myfeedbackpublications: "My feedback publications",
        logout: "Log out",
        forgottenPassword: "Forgot password?",
        enterEmail: "Enter your email, to reset your password: ",
        request: "Send request",
        username: "Username",
        passHint: "Your password should be between 6-12 characters.",
        firstName: "First name",
        lastName: "Last name",
        institution: "Institution",
        institutionPlaceholder: "Choose institution",
        role: "Type of user:",
        rolePlaceholder: "Choose type of user",
        responsiblePerson: "Responsible person",
        active: "Active",
        newPassword: "New password",
        repeatPassword: "Repeat password",
        change: "Edit",
        removeAttachmentPrompt: "Are you sure that you want to delete this attached document ?",
        removePublicationPrompt: "Are you sure that you want to delete this publication for this announcement ?",
        answer: "Answer",
        previewAnswer: "Preview answers",
        title_dksk: "State Commission for Prevention of Corruption",
        subtitle_dksk: "Portal for communication with institutions",
        title_komspi: "Agency for Protection of the Right to Free Access to Public Information",
        subtitle_komspi: "",
        NotReaded: "Not read",
        error: "Error",
        errorOpeningAnnouncementPub: "Error during opening the announcement publication",
        failedToLoadAnnouncementPub: "Couldn't load the announcement publication",
        successDeletedAnnounPub: "Successfully deleted the announcement publication",
        failedToDeleteAnnounPub: "Failed to delete the announcement publication",
        successfullySentMail: "Successfully send e-mail",
        failedToSendMail: "Failed to send e-mail",
        successfullyAddedAnnouncement: "Announcement successfully added",
        failedToAddAnnouncement: "Failed to add announcement",
        failedToLoadAttachments: "Failed to load documents",
        successfullyUploadedFile: "Succesfully attached file",
        failedToUploadFile: "Failed to attach file",
        successfullDownloadedFile: "Succesfully downloaded file",
        failedToDownloadAttachment: "Failed to download file",
        errorWhileLoadingAnnouncement: "Error while loading announcement",
        errorWhileLoadingInstitution: "Error while loading institution",
        errorWhileLoadingTags: "Error while loading tags",
        successfullyPublishedAnnouncement: "Succesfully published announcement",
        failedToPublishAnnouncement: "Failed to publish announcement",
        successfullyChangedPassword: "Succesfully changed password",
        failedToChangePassword: "Failed to change password",
        deleteModal: "Successfully deleted",
        deleteModalfailed: "Failed to delete",
        editModal: "Successfully changed",
        addModal: "Successfully added",
        failedToLoadData: "Failed to load data",
        successfullyAddedFeedbackItem: "Successfully added question",
        failedToAddFeedbackItem: "Failed to add question",
        successfullyEditedFeedbackItem: "Successfully changed question",
        failedToEditFeedbackItem: "Failed to change question",
        successfullyDeletedFeedbackItem: "Successfully deleted question",
        failedToDeleteFeedbackItem: "Failed to delete question",
        successfullyAddedFeedback: "Successfully added feedback",
        feedbackSuccessfullyEdited: "Successfully edited feedback",
        successfullyDeletedFeedback: "Succesfully deleted feedback",
        failedToDeleteFeedback: "Failed to delete feedback",
        failedToAddFeedback: "Failed to add feedback",
        successfullyDeletedFeedbackPublication: "Succesfully deleted feedback",
        failedToDeleteFeedbackPublication: "Failed to delete feedback",
        errorWhileLoadingFeedback: "Error while loading feedback",
        errorWhileLoadingInstitutions: "Error while loading institutions",
        successfullyPublishedFeedback: "Succesfully published feedback",
        failedToPublishFeedback: "Failed to publish feedback",
        failedToLoadAnswers: "Failed to load answers",
        successfullySubmittedAnswers: "Succesfully submitted answers",
        failedTSubmitAnswers: "Failed to submit answers! You have mandatory but unanswered questions.",
        failedToGetFeedbackId: "Failed to load feedback id",
        AnalyseFeedbackModalTitle: "Answered:",
        comments: "Comments",
        madePost: "Has commented",
        comment: "Add comment",
        commentPlaceholder: "Write your comment",
        successfullComent: "Succesfully commented",
        failedToComment: "Failed to comment",
        publicDocuments: "Public documents",
        PublicDocumentsRegistry: "Registration of public documents",
        YearForPublicDocument: "Year of the document",
        PublicDocumentType: "Type of the document",
        PublicDocumentTypes: "Type of public documents",
        Url: "Link",
        save: "Save",
        successAddPubDocType: "Succesfully added public document type",
        failAddPubDocType: "Failed to add public document type",
        successEditPubDocType: "Succesfully changed public document type",
        editPubDocType: "Failed to change public document type",
        selectYear: "Select year",
        showUrls: "Previously added links to your institution:",
        show: "Show",
        nameMK: "Name - macedonian",
        nameAL: "Name - albanian",
        nameEN: "Name - english",
        institutionCategory: "Categories of institutions",
        institutionCat: "Category for institution",
        heading: "Category direction",
        address: "Address",
        phone: "Telephone number",
        webSite: "Web site",
        invalidEmailOrPassword: "Invalid email or password",
        faq: "Frequently asked questions",
        deleteAnnouncement: "Are you sure that you want to delete the announcement ?",
        delAnn: "Delete announcement",
        direktorFirstName: "Director's first name",
        direktorLastName: "Director's last name",
        direktorEmail: "Director's email",
        direktorPhone: "Director's phone number",
        moderatorFirstName: "Moderator's first name",
        moderatorLastName: "Moderator's last name",
        moderatorPhone: "Moderator's phone number",
        moderatorAlternativePhone: "Moderator's alternative phone number",
        moderatorAlternativeSecondPhone: "Moderator's second alternative phone number",
        moderatorAlternativeThirdPhone: "Moderator's third alternative phone number",
        alternativePhone: "Alternative phone number",
        alternativeSecondPhone: "Alternative second phone number",
        alternativeThirdPhone: "Alternative third phone number",
        moderatorEmail: "Primary moderator email",
        moderatorSecond: "First alternative moderator email",
        moderatorThird: "Second alternative moderator email",
        moderatorForth: "Third alternative moderator email",
        searchby: "Choose option to search by",
        firstlastname: "First and last name",
        titleofann: "Title of announcement",
        all: "Take a look at all",
        egflname: "ex. Petko Petkovski",
        egann: "ex. Announcement",
        report: "Report",
        selectedTags: "Selected tags",
        selectedCategories: "Selected categories",
        selectedInstitutions: "Selected institutions",
        questionn: "Question",
        reply: "Answer",
        addNewEntry: "Add new question",
        changePassword: "Change password",
        profile: "Profile",
        logged: "Logged in",
        more: "More",
        publishedAnnouncements: "No published announcements",
        editAnnouncement: "Change announcement",
        editAnnouncementSuccessful: "Announcement successfully edited",
        editAnnouncementFailed: "Failed to edit announcement",
        publishAnnouncement: "Publish announcement",
        designFeedbackItem: "Add questions for feedback",
        nameQuestion: "Name of question",
        descQuestion: "Description of question",
        nameFeedback: "Name of feedback",
        descShortFeedback: "Short description of feedback",
        noPublishedFeedbacks: "No published feedbacks",
        noInstitution: "No institution",
        required: "Required",
        numberOnly: "Please enter valid number",
        preview: "Preview",
        NoneAnsweredFeedback: "No answers yet",
        export: "Export( download results)",
        monitoringOnPublicBodies: "Public bodies monitoring",
        questionMonitoring: "Number of institutions which set document or link",
        yes: "Yes",
        no: "No",
        deleteFeedbackPub: "Are you sure that you want to delete the feedback publication ?",
        deletePubDocType: "Are you sure that you want to delete the public document type ?",
        failedToAddDocType: "Failed to add public document type",
        failedToEditDocType: "Failed to edit public document type",
        successToAddDocType: "Successfully added public document type",
        successToEditDocType: "Successfully edited public document type",
        publicDocType: "Public document type",
        successfullyAddedTag: "Tag successfully added",
        failedToAddTag: "Failed to add tag",
        successfullyEditedTag: "Successfully edited tag",
        failedToEditTag: "Failed to edit tag",
        tag: "Tag",
        tagInstitutions: "Institutions that belong to this tag",
        pleaseChooseInstitution: "Please, choose institution",
        noUrl: "NO link",
        noPDF: "NO PDF",
        uploadPDF: "Upload pdf",
        successfullyAddedLink: "Successfully added link",
        successfullyAddedPdf: "Successfully added document",
        failedToAddLink: "Failed to add link",
        failedToAddPdf: "Failed to add document",
        pdfDoc: "PDF document",
        annualReport: "Annual year report",
        chooseHeading: "Choose category heading",
        institutionName: "Institution name",
        submitter: "Submitter",
        status: "Status",
        form: "Form",
        file: "File",
        submitted: "Submitted",
        inProgress: "In progress",
        review: "Review",
        print: "Print",
        podatociSluzbLice: "Data on officials assigned to mediate information with information holders:",
        brPrimeniBaranja: "Number of requests:",
        brPozitivnoOdgBaranja: "Number of positive answered requests",
        odbieniIOtfrleniZalbi: "Number of rejected and rejected requests stating the reasons for each rejected or rejected request:",
        brNeodogovoreniBaranja: "Number of unanswered requests: ",
        vlozeniZalbi: "Number of appeals against first instance decisions (decisions of the information holders, with a description of the decision, as well as stating the reasons for the decision in case of re-denial of the requested information):",
        brUsvoeniZalbi: "Number of adopted appeals by decision of the Agency:",
        brPreinaceniOdluki: "Number of amended first instance decisions after the action of the Agency:",
        odbieniZalbi: "Number of rejected complaints from the Agency and reasons for their rejection:",
        otfrelniZalbi: "Number of rejected complaints from the Agency and reasons for their rejection: ",
        message: "Message",
        parentInstitution: "Parent of instituton",
        typeParent: "Type of parent",
        searchForCategories: "Find category",
        inactiveUsers: "Inactive users",
        activate: "Activate",
        activateUser: "Activate user",
        successActivation: "User successfully activated",
        failedActivation: "Failed to activate user",
        searchByInst: "Find users by institution",
        successfullyAdInstitution: "Institution successfully added",
        successfullyEditInstitution: "Institution successfully edited",
        failEditInstitution: "Failed to edit institution",
        failAddInstitution: "Failed to add institution",
        download: "Download",
        signedDocument: "Signed document",
        noAnnualReportsFoundForThisYear: "No reports for this year",
        addressMk: "Address - macedonian",
        addressAl: "Address - albanian",
        addressEn: "Address - english",
        notEntered: "Not entered",
        successfullyAddedUser: "Successfully added user",
        successfullyEditedUser: "Successfully edited user",
        failedToAddUser: "Failed to add user",
        failedToEditUser: "Failed to edit user",
        search: "Search",
        parentCategory: "Parent category",
        noParentCategory: "No parent category",
        failedToSubmitReport: "Failed to submit report",
        deleteTag: "Delete tag",
        noPublishedFAQ: "No frequently asked questions",
        userSuccessfullyDeleted: "User successfully deleted",
        userNotDeleted: "Failed to delete user",
        deleteQuestionForUser: "Are you sure that you want to delete this user  ?",
        userActivate: "Are you sure that you want to activate this user ?",
        justDelete: "Delete",
        deleteUser: "Delete user",
        successfullyDeletedTag: "Tag successfully deleted",
        tagNotDeleted: "Failed to delete tag",
        questionDeleteTag: "Are you sure that you want to delete the tag?",
        agencyName: "Agency for Protection of the Right to Free Access to Public Information",
        categoryInstDelete: "Category succesfully deleted",
        categoryInstNotDelete: "Category not deleted, because there are connected instituions and sub-categories with it",
        deleteCatQuestion: "Are you sure that you want to delete the category ?",
        institutionDeleted: "Institution successfully deleted",
        institutionNotDeleted: "Failed to delete institution",
        instDeleteQuestion: "Are you sure that you want to delete the institution?",
        deleteFeedbackQuestion: "Are you sure that you want to delete this feedback information?",
        reportExists: "Year report for this year already exists",
        failTryAgain: "Failed, try again",
        reportHere: "Report already exists",
        succAdded: "Succesfully added!",
        noFileAdded: "No file added",
        succChanged: "Successfully changed!",
        categoryInstAdded: "Category successfully added",
        categoryInstEdit: "Category successfully chaneged",
        invalidInput: "Invalid input",
        activatedUser: "User activated",
        deactivatedUser: "Failed to activate user",
        deleteAttachedDocument: "Successfully deleted file",
        mision: "MISION",
        misionText: "Continuous implementation, promotion and protection of the right to free access to public information in order to increase the transparency and accountability of institutions and facilitate access to public information",
        vision: "VISION",
        visionText: "Institutional recognition of the Agency as a credible afirmator and protector of the right to free access to public information.",
        resetPassSuccessful: "Your request is finished, please check your email",
        resetPassNotSuccessful: "Your request in not successfull",
        pleaseWait: "Please wait...",
        successfullyDeletedQuestion: "Successfully deleted question",
        UnsuccessfullyDeletedQuestion: "Question not deleted",
        deleteQuestionForQuestion: "Are you sure that you want to delete the question?",
        noPublishedQuestions: "No published questions",
        questions: "Questions",
        Answers: "Answer the question",
        noInactiveQuestions: "No inactive questions",
        shareAgain: "Share again",
        shared: "The question is shared again",
        notShared: "The question is not shared again",
        allInactive: "Inactive questions",
        allActive: "Questions",
        noInst: "No institution",
        succAddQuestion: "Successfully added question",
        unSucAddQuestion: "Failed to add question",
        succEditQuestion: "Successfully edited question",
        unSucEditQuestion: "Failed to edit question",
        succEditMessage: "Successfully changed message",
        unSuccEditMessage: "Message not changed",
        messageBody: "Message: ",
        authorCreator: "Author: ",
        datePublish: "Publish date: ",
        messageSuccDeleted: "Message successfully deleted",
        messageUnSuccDeleted: "Message not deleted",
        messageSuccSharedAgain: "The message is shared again",
        messageUnSuccSharedAgain: "The message is not shared",
        deleteMessageForMessage: "Are you sure that you want to delete the message?",
        shareMessageForMessage: "Are you sure that you want to share the message again?",
        editedInstitutions: "Edited institutions",
        deleteChanges: "Are you sure that you want to discard the changes?",
        seeChanges: "See changes",
        institutionAlreadyChanged: "Changes for instituion are submitted, please wait.",
        strMk: "Street(village) macedonian",
        strAl: "Street(village) albanian",
        typeStrMk: "Bou./Str. macedonian",
        typeStrAl: "Bou./Str. albanian",
        streetNumMk: "Number",
        detailsMk: "Details macedonian",
        detailsAl: "Details albanian",
        cityTownMk: "Place - macedonian",
        cityTownAl: "Place - albanian",
        postCode: "Postal code",
        fullDirector: "Director",
        enterValidNumber: "Please enter valid number.",
        myInst: "My institution",
        infoNoticeBoard: "Notice board",
        listOfInformations: "The list of public information can be found at",
        evaluator: "Evaluator",
        moderatorEvaluator: "Moderator and evaluator",
        allReports: "Reports",
        strEn: "Street(village) english",
        typeStrEn: "Bou./Str. English",
        detailsEn: "Details english",
        cityTownEn: "Place - english",
    }
});

