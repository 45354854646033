import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {strings} from "../../Localization/Localization";

const EditProactiveTransparency = (props) => {

    strings.setLanguage(localStorage.getItem("activeLanguage"));

    return (
        <Row>
            <Col>
                <Form>
                    <Form.Group controlId="name">
                        <Form.Label>{strings.name}</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            required
                            defaultValue={props.entity.name}
                            onChange={props.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>{strings.description}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            type="text"
                            name="description"
                            required
                            defaultValue={props.entity.description}
                            onChange={props.handleChange}/>
                    </Form.Group>
                </Form>
            </Col>
        </Row>
    )
}

export default EditProactiveTransparency;
