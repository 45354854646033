import React, {Component} from "react";
import {strings} from "../../Localization/Localization";
import CrudModal from "../Crud/CrudModal";
import {toast} from "react-toastify";
import {faUniversity} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TagRepository from "../../repository/TagRepository";
import TagAdd from "./TagAdd";
import TagEdit from "./TagEdit";
import CrudService from "../../repository/service/CrudService";
import './css/tag.css';
import DeleteModal from "../Crud/DeleteModal";

class Tags extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            tag: null,
            active: ""
        }
    }

    componentDidMount() {
        this.getTags();
    }

    getTags = () => {
        TagRepository.getTags().then(res => {
            this.setState({data: res.data})
        }).catch(err => console.log(err))
    }

    setPubDoc = (tag) => {
        if (this.state.tag === null){
            this.setState({
                tag: tag,
                active: tag.nameMk
            })
        }else {
            if (this.state.tag.id === tag.id){
                this.setState({
                    tag: null,
                    active: ""
                })
            }else{
                this.setState({
                    tag: tag,
                    active: tag.nameMk
                })
            }
        }
    }

    AddTag = (entity) => {
        if (entity.tagNameMk !== undefined && entity.tagNameMk !== "") {
            return CrudService.save("/rest/tag", entity).then(() => {
                toast.success(strings.successfullyAddedTag)
                this.getTags();
            }).catch(() => {
                toast.error(strings.failedToAddTag)
            })
        }
    }
    EditTag = (entity) => {
        return CrudService.edit("/rest/tag", entity).then(async () => {
            toast.success(strings.successfullyEditedTag)
            await this.sleep(4000);
            window.location.reload();
            this.getTags();
        }).catch(() => {
            toast.error(strings.failedToEditTag)
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    deleteTag = (id) => {
        return TagRepository.deleteTag(id).then(() => {
            toast.success(strings.successfullyDeletedTag)
            this.getTags();
            this.setState({tag: null})
        }).catch(() => {
            toast.error(strings.tagNotDeleted)
        });
    };

    render() {
        return (
            <div className="col-12">
                <div className="row mt-3">
                    <div className="col-3 mb-1">
                        <h2 style={{textAlign: "left", color: "#1C4857"}} className="mt-4">{strings.tags}</h2>
                    </div>
                    <div className="col-3 justify-content-end text-right">
                        <CrudModal entity={{}}
                                   icon="add"
                                   showText={true}
                                   btnClass="btn-primary defaultBtnAdd mt-5 mb-2"
                                   title={strings.add}
                                   onSubmit={this.AddTag}
                                   body={TagAdd}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">

                        <div style={{height: "100%", overflowY: "auto"}}>
                            <ul className="list-group" style={{textAlign: 'center'}}>
                                {this.state.data &&
                                this.state.data.map((tag, index) => {
                                    let nameMk = tag.nameMk;
                                    let nameAl = tag.nameAl;
                                    return (
                                        <li
                                            className={
                                                "list-group-item "
                                                + ((nameMk || nameAl) === this.state.active ? "active" : "")
                                            }
                                            style={{fontSize: '20px', display: 'inline-block'}}
                                            key={index}
                                        >
                                            {nameMk.length > 40 || nameAl.length > 40 ? localStorage.getItem('activeLanguage') === 'mk' ? nameMk.slice(0, 40) + "..." : nameAl.slice(0, 40) + "..." : localStorage.getItem('activeLanguage') === 'mk' ? nameMk : nameAl}
                                            <div className="row mt-4">
                                                <div className="col-12 justify-content-center">
                                                    <CrudModal entity={tag}
                                                               icon="edit"
                                                               btnClass="btn-success btn-sm defaultBtnEdit ml-4"
                                                               title={strings.edit}
                                                               showText={true}
                                                               onSubmit={this.EditTag}
                                                               body={TagEdit}/>
                                                    <button className="btn btn-primary btn-sm ml-2 defaultBtn"
                                                            onClick={() => this.setPubDoc(tag)}><FontAwesomeIcon
                                                        icon={faUniversity}
                                                        style={{paddingRight: '2px'}}/>{strings.institutions}
                                                    </button>
                                                    <DeleteModal btnClass={"ml-2 defaultBtn"}
                                                                 prompt={strings.questionDeleteTag}
                                                                 showText={true}
                                                                 doDelete={() => this.deleteTag(tag.id)}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        {this.state.tag && (
                            <div className="list-group">
                                <div className="list-group-item">
                                    <div style={{wordWrap: "break-word", marginBottom: "3px", fontSize: '20px'}}>
                                        {strings.tag + ": "}
                                        {localStorage.getItem('activeLanguage') === 'mk' ? this.state.tag.nameMk : this.state.tag.nameAl}
                                    </div>
                                    <div>
                                        <h3 className={"mb-2"}> {strings.tagInstitutions + ": "}</h3>
                                        <div>
                                            <ul>{this.state.tag.institutions.map(institution => {
                                                return (
                                                    <li className="ml-4" style={{fontSize: '17px', fontWeight: 'bold'}}
                                                        key={institution.id}>{localStorage.getItem('activeLanguage') === 'mk' ? institution.nameMk : institution.nameAl}</li>)
                                            })}</ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        )
    }
}

export default Tags;
