import React, {Component} from 'react';
import AnnouncementRepository from "../../repository/AnnouncementRepository";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {strings} from "../../Localization/Localization";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class EditAnnouncement extends Component {

    constructor(props) {

        super(props);
        this.state = {
            id: this.props.match.params.id,
            title: '',
            body: ''
        }
    }

    componentDidMount() {
        this.fetchData()
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
    }

    fetchData = () => {
        AnnouncementRepository.getAnnouncementById(this.state.id).then(res => {
            this.setState({
                title: res.data.title,
                body: res.data.body
            })
        }).catch(err => toast.error(strings.failedToLoadData));
    }

    handleTitleChange = (event) => {
        this.setState({title: event.target.value})
    };


    handleBodyChange = (event) => {
        this.setState({body: event.target.value})
    };

    updateAnnouncement = (e) => {
        e.preventDefault();
        let announcement = {
            id: this.state.id,
            title : this.state.title,
            body : this.state.body
        }
        AnnouncementRepository.updateAnnouncement(announcement).then(res => {
            toast.success(strings.editAnnouncementSuccessful);
            this.props.history.push("/allannouncements")
        }).catch(err => {
            toast.error(strings.editAnnouncementFailed);
        });
    }


    render() {
        return (
            <div>
                <h1 className="text-center mt-5" style={{color: "#1C4857"}}>{strings.editAnnouncement}</h1>
                <div className="container mt-5">
                    <form onSubmit={this.updateAnnouncement}>
                        <div className="form-group">
                            <label htmlFor="title">{strings.title}</label><input type="text" id="title"
                                                                                 className="form-control"
                                                                                 onChange={this.handleTitleChange}
                                                                                 defaultValue={this.state.title}/>

                        </div>
                        <div className="form-group">
                            <label htmlFor="body">{strings.body}</label><textarea className="form-control" type="text"
                                                                                  id="body"
                                                                                  onChange={this.handleBodyChange}
                                                                                  defaultValue={this.state.body}/>
                        </div>
                        <button type="submit" className="btn defaultBtnAdd">
                            <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                style={{paddingRight: '4px'}}/>{strings.rememberme}</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default EditAnnouncement;