import React, {useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {strings} from "../../Localization/Localization";

const UserDisabledInstitutionAdd = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));
    const [email, setEmail] = useState([])

    const onChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value);
    };

    const onEmailChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value);
        setEmail(e.target.value)
        if (e.target.value.length > 0 && e.target.value.includes('@')) {
            document.getElementById('enterEmail').innerText = '';
        }
    };

    const onInfoChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value);
    };

    const onReportChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value)
        if (e.target.value === "1") {
            checkEmail();
        } else {
            document.getElementById('enterEmail').innerText = '';
        }
    }

    let checkEmail = () => {
        if (email.length === 0 || !email.includes('@')) {
            document.getElementById('email').required = true;
            document.getElementById('enterEmail').innerText = strings.pleaseEnterEmail;
        }
    }

    const role = localStorage.getItem('role');
    return (
        <div>
            <div className="row">
                <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.email}</label>

                    <input
                        id={"email"}
                        name={"email"}
                        type={"email"}
                        placeholder={"name@example.com"}
                        className="form-control"
                        onChange={(e) => onEmailChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                        {/*<div*/}
                        {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                    </div>
                </div>
                <br/>

                <div className="row">
                    <div
                        className="col-12">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                        <input
                            name={"alternativeEmail"}
                            type={"email"}
                            placeholder={"name@example.com"}
                            className="form-control"
                            onChange={(e) => onChangeHandler(e)}
                        />
                        {/*{props.error &&*/}
                        {/*<div*/}
                        {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                    </div>
                </div>
                <br/>

                <div className="row">
                    <div
                        className="col-12">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                        <input
                            name={"alternativeSecondEmail"}
                            type={"email"}
                            placeholder={"name@example.com"}
                            className="form-control"
                            onChange={(e) => onChangeHandler(e)}
                        />
                        {/*{props.error &&*/}
                        {/*<div*/}
                        {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                    </div>
                </div>
                <br/>

                <div className="row">
                    <div
                        className="col-12">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                        <input
                            name={"alternativeThirdEmail"}
                            type={"email"}
                            placeholder={"name@example.com"}
                            className="form-control"
                            onChange={(e) => onChangeHandler(e)}
                        />
                        {/*{props.error &&*/}
                        {/*<div*/}
                        {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                    </div>
                </div>
                <br/>

                <div className="row">
                    <div
                        className="col-12">
                        <label htmlFor={"password"}
                               className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.password}</label>

                        <input
                            id={"password"}
                            autoComplete="disabled"
                            defaultValue={""}
                            name={"password"}
                            type={"password"}
                            placeholder={strings.password}
                            title={"six or more characters"}
                            className="form-control"
                            onChange={(e) => onChangeHandler(e)}
                        />
                        {/*{props.error &&*/}
                        {/*<div*/}
                        {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                        <small id="passwordHelpBlock" className="form-text text-muted">
                            {strings.passHint}
                        </small>
                    </div>
                </div>
                <br/>

                <div className="row">
                    <div className="col-12">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">
                            <span style={{color: "red", fontSize: '18px'}}
                                  className="mr-1">
                                *
                            </span>
                            {strings.firstName}
                        </label>

                        <input
                            name={'firstName'}
                            type={"text"}
                            placeholder={strings.firstName}
                            className="form-control"
                            onChange={(e) => onChangeHandler(e)}
                        />
                        {/*{props.error &&*/}
                        {/*<div*/}
                        {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                    </div>
                </div>
            <br/>

            <div className="row">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.firstNameAlbanian}</label>

                    <input
                        name={'firstNameAl'}
                        type={"text"}
                        placeholder={strings.firstNameAlbanian}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
            </div>
            <br/>

            <div className="row">
                <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                            <span style={{color: "red", fontSize: '18px'}}
                                  className="mr-1">
                                *
                            </span>
                        {strings.lastName}
                    </label>

                        <input
                            name={"lastName"}
                            type={"text"}
                            placeholder={strings.lastName}
                            className="form-control"
                            onChange={(e) => onChangeHandler(e)}
                        />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
            </div>
            <br/>

            <div className="row">
                <div
                    className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.lastNameAlbanian}</label>

                    <input
                        name={"lastNameAl"}
                        type={"text"}
                        placeholder={strings.lastNameAlbanian}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                    {/*{props.error &&*/}
                    {/*<div*/}
                    {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}
                </div>
            </div>
            <br/>

            <div className="row">
                <div className="col-9">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.phone}</label>

                    <input
                        name={"phone"}
                        type={"text"}
                        placeholder={strings.phone}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
                <div className="col-3">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                    <input
                        name={"locales"}
                        type={"text"}
                        placeholder={""}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
            </div>
            <br/>

            <div className="row">
                <div className="col-9">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativePhone}</label>

                    <input
                        name={"alternativePhone"}
                        type={"text"}
                        placeholder={strings.alternativePhone}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
                <div className="col-3">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                    <input
                        name={"alternativeLocales"}
                        type={"text"}
                        placeholder={""}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
            </div>
            <br/>

            <div className="row">
                <div className="col-9">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeSecondPhone}</label>

                    <input
                        name={"alternativeSecondPhone"}
                        type={"text"}
                        placeholder={strings.alternativeSecondPhone}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
                <div className="col-3">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                    <input
                        name={"alternativeSecondLocales"}
                        type={"text"}
                        placeholder={""}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col-9">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeThirdPhone}</label>

                    <input
                        name={"alternativeThirdPhone"}
                        type={"text"}
                        placeholder={strings.alternativeThirdPhone}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
                <div className="col-3">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                    <input
                        name={"alternativeThirdLocales"}
                        type={"text"}
                        placeholder={""}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
            </div>

                <br/>
                {role === "ROLE_ADMIN" ?
                    <div className="row mt-1">
                        <div className="col-12">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">
                                <span style={{color: "red", fontSize: '18px'}} className="mr-1">*</span>{strings.role}
                            </label>

                            <select
                                name={"role"}
                                required
                                className="form-control custom-select"
                                onChange={(e) => onChangeHandler(e)}
                            >
                                <option value="">{strings.rolePlaceholder}</option>
                                <option value="ROLE_ADMIN">{strings.admin}</option>
                                <option value="ROLE_INSTITUTIONAL_MODERATOR">{strings.responsiblePerson}</option>

                            </select>
                            {/*{props.error &&*/}
                            {/*<div*/}
                            {/*    className="alert alert-danger error-msg">{strings.invalidInput}</div>}*/}

                        </div>
                    </div>
                    : null}
                <br/>

                {role === "ROLE_ADMIN" ?
                    <div className="row">
                        <div className="col-12">
                            <label
                                className="weight400 text-upper control-label control-label-xl margin-top-10">
                                <span style={{color: "red", fontSize: '18px'}}
                                      className="mr-1">*</span>{strings.additionalRole}</label>
                            <select
                                name={"additionalRoleInfo"}
                                required
                                className="form-control custom-select"
                                onChange={(e) => onInfoChangeHandler(e)}
                                style={{marginTop: '-2px'}}
                            >
                                <option value="">{strings.chooseRole}</option>
                                <option value="SIMPLE_ROLE">{strings.simpleRole}</option>
                                <option value="QUESTIONS_ROLE">{strings.questionsRole}</option>
                            </select>
                        </div>
                    </div>
                    : <div></div>
                }

                <div className="row mt-4">
                    <div className="col-4">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">
                            <span style={{color: "red", fontSize: '18px'}}
                                  className="mr-1">*</span>{strings.versonForPerson}</label>

                        <input
                            name={"responsiblePersonVersion"}
                            required
                            type={"number"}
                            placeholder={""}
                            className="form-control"
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>

                    <div className="col-8">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">
                             <span style={{color: "red", fontSize: '18px'}}
                                   className="mr-1">*</span>{strings.report}</label>

                        <select
                            name={"reportMaker"}
                            required
                            className="form-control custom-select"
                            onChange={(e) => onReportChangeHandler(e)}
                        >
                            <option value="">{strings.chooseRole}</option>
                            <option value="0">{strings.canNotCreateReport}</option>
                            <option value="1">{strings.canCreateReport}</option>
                        </select>

                        <span id="enterEmail" style={{color: 'red'}} className="ml-2 mt-1"/>
                    </div>
                </div>

            </div>
        );

    }
;

export default UserDisabledInstitutionAdd;
