import axios from "../axios/app";

const CriteriaTypeRepository = {

    findAllCriteriaTypes: () => {
        return axios.get(`/rest/criteriaType/all`)
    },

    createCriteriaType: (criteriaType) => {
        return axios.post(`/rest/criteriaType/create`, criteriaType)
    },

    editCriteriaType: (criteriaType) => {
        return axios.put(`/rest/criteriaType/edit`, criteriaType)
    },

    deleteCriteriaType: (criteriaTypeId) => {
        return axios.delete(`/rest/criteriaType/delete/${criteriaTypeId}`)
    },

    getOptionsForCriteriaType: (criteriaTypeId) => {
        return axios.get(`/rest/criteriaType/options/${criteriaTypeId}`)
    },

    findCriteriaTypeById: (criteriaTypeId) => {
        return axios.get(`/rest/criteriaType/${criteriaTypeId}`)
    }

}

export default CriteriaTypeRepository;