import React from "react";
import {strings} from "../../Localization/Localization";

const AddFullReport = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));


    const onChangeHandler = (e) => {

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        props.onChange(name, value);
    };

    return (
        <div className="col-12">
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <span style={{color: "red", fontSize: '18px'}} className="mr-1">*</span>{strings.name}</label>

                <input
                    required
                    placeholder={""}
                    name={"name"}
                    type={"text"}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control mb-3"
                />
            </div>
        </div>
    )
}
export default AddFullReport;