import React from "react";
import {strings} from "../../Localization/Localization";

const InstitutionalUserEdit = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));

    const onChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value);
    };

    const onRoleChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value);
    };

    const onInfoChangeHandler = (e) => {
        props.onChange(e.target.name, e.target.value);
    };

    let rolefront;
    let role = localStorage.getItem('role');
    role === "ROLE_ADMIN" ?
        rolefront =
            <div className="row">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.role}</label>

                    <select
                        name={"role"}
                        className="form-control custom-select"
                        onChange={(e) => onRoleChangeHandler(e)}
                        value={props.entity.role !== undefined ? props.entity.role : ""}
                    >
                        <option value="ROLE_ADMIN">{strings.admin}</option>
                        <option value="ROLE_INSTITUTIONAL_MODERATOR">{strings.responsiblePerson}</option>
                    </select>
                </div>
            </div>
        :
        rolefront = <div/>;


    let additionalRoleInfo;
    role === "ROLE_ADMIN" ?
        additionalRoleInfo =
            <div className="row">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.additionalRole}</label>

                    <select
                        name={"additionalRoleInfo"}
                        className="form-control custom-select"
                        onChange={(e) => onInfoChangeHandler(e)}
                        value={props.entity.additionalRoleInfo !== undefined ? props.entity.additionalRoleInfo : ""}
                    >
                        <option value="SIMPLE_ROLE">{strings.simpleRole}</option>
                        <option value="QUESTIONS_ROLE">{strings.questionsRole}</option>
                    </select>
                </div>
            </div>
        :
        additionalRoleInfo = <div/>;

    return (
        <div className="col-12">

            <div className="row">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.firstName}</label>
                    <input
                        name={'firstName'}
                        type={"text"}
                        defaultValue={props.entity.firstName}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.firstNameAlbanian}</label>
                    <input
                        name={'firstNameAl'}
                        type={"text"}
                        defaultValue={props.entity.firstNameAl}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.lastName}</label>
                    <input
                        name={"lastName"}
                        type={"text"}
                        defaultValue={props.entity.lastName}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.lastNameAlbanian}</label>
                    <input
                        name={"lastNameAl"}
                        type={"text"}
                        defaultValue={props.entity.lastNameAl}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-9">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.phone}</label>

                    <input
                        name={"phone"}
                        defaultValue={props.entity.phone}
                        type={"text"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
                <div className="col-3">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                    <input
                        name={"locales"}
                        defaultValue={props.entity.locales}
                        type={"text"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-9">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativePhone}</label>

                    <input
                        name={"alternativePhone"}
                        defaultValue={props.entity.alternativePhone}
                        type={"text"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
                <div className="col-3">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                    <input
                        name={"alternativeLocales"}
                        defaultValue={props.entity.alternativeLocales}
                        type={"text"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-9">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeSecondPhone}</label>

                    <input
                        name={"alternativeSecondPhone"}
                        defaultValue={props.entity.alternativeSecondPhone}
                        type={"text"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
                <div className="col-3">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                    <input
                        name={"alternativeSecondLocales"}
                        defaultValue={props.entity.alternativeSecondLocales}
                        type={"text"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-9">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeThirdPhone}</label>

                    <input
                        name={"alternativeThirdPhone"}
                        defaultValue={props.entity.alternativeThirdPhone}
                        type={"text"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
                <div className="col-3">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.localFirst}</label>

                    <input
                        name={"alternativeThirdLocales"}
                        defaultValue={props.entity.alternativeThirdLocales}
                        type={"text"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.email}</label>

                    <input
                        name={"email"}
                        defaultValue={props.entity.email}
                        type={"email"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
            </div>
            <br/>

            <div className="row">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                    <input
                        name={"alternativeEmail"}
                        defaultValue={props.entity.alternativeEmail}
                        type={"email"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
            </div>
            <br/>

            <div className="row">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                    <input
                        name={"alternativeSecondEmail"}
                        defaultValue={props.entity.alternativeSecondEmail}
                        type={"email"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
            </div>
            <br/>

            <div className="row">
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.alternativeEmail}</label>

                    <input
                        name={"alternativeThirdEmail"}
                        defaultValue={props.entity.alternativeThirdEmail}
                        type={"email"}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control"

                    />
                </div>
            </div>
            <br/>

            {rolefront}
            <br/>

            {additionalRoleInfo}
            <br/>

            <div className="row">
                <div className="col-4">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                        {strings.versonForPerson}</label>
                    <input
                        name={"responsiblePersonVersion"}
                        type={"number"}
                        required
                        defaultValue={props.entity.responsiblePersonVersion}
                        className="form-control"
                        onChange={(e) => onChangeHandler(e)}
                    />
                </div>

                <div className="col-8">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.report}</label>

                    <select
                        name={"reportMaker"}
                        className="form-control custom-select"
                        defaultValue={props.entity.reportMaker}
                        onChange={(e) => onChangeHandler(e)}
                    >
                        <option value="0">{strings.canNotCreateReport}</option>
                        <option value="1">{strings.canCreateReport}</option>
                    </select>
                </div>
            </div>


        </div>
    )
}

export default InstitutionalUserEdit;