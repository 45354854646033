import axios from '../axios/app';

const ReportElementRepository = {

    getAllReportElements: () => {
        return axios.get("/rest/reportElement/allReportElements")
    },
    reportElementsForReport: (reportId) => {
        return axios.get(`/rest/reportElement/allElementsByReportId/${reportId}`)
    },
    createReportElement: (reportElement, institution, criteria, idFullReport) => {
        return axios.post(`/rest/reportElement/createReportElement/${institution}/${criteria}/${idFullReport}`, reportElement)
    },
    createElement: (reportElement) => {
        return axios.post(`/rest/reportElement/createReportElement/create/`, reportElement)
    },
    updateReportElement: (reportElement, institutionId, criteriaId, fullReportId) => {
        return axios.patch(`/rest/reportElement/updateReportElement/${institutionId}/${criteriaId}/${fullReportId}`, reportElement)
    },
    getReportElement: (reportElementId) => {
        return axios.get(`/rest/reportElement/${reportElementId}`)
    },
    deleteReportElement: (reportElementId) => {
        return axios.delete(`/rest/reportElement/${reportElementId}`)
    },
    checkEvaluated: (institutionId, criteriaId, fullReportId) => {
        return axios.get(`/rest/reportElement/checkEvaluated/${institutionId}/${criteriaId}/${fullReportId}`)
    },
    findByInstitutionAndCriteriaAndReport: (institutionId, criteriaId, fullReportId) => {
        return axios.get(`/rest/reportElement/findByInstitutionAndCriteriaAndReport/${institutionId}/${criteriaId}/${fullReportId}`)
    }

};

export default ReportElementRepository;