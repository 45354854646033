import React, {Component} from "react";
import QuestionRepository from "../../repository/QuestionRepository.js"
import "./css/QuestionDetails.css"
import "./css/Question.css"
import ReactPaginate from "react-paginate";
import {strings} from "../../Localization/Localization";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DeleteModal from "../Crud/DeleteModal";
import {toast} from "react-toastify";
import {NavLink, Link} from "react-router-dom";
import InfoPopup from "../ErrorHandler/InfoPopup";
import {faComments, faEdit, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import UnDeleteModal from "../Crud/UnDeleteModal";


class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            activeLanguage: localStorage.getItem("activeLanguage"),
            pageCount: 1,
            numberOfElements: 0,
            showDeleteModal: false,
            isAdmin: localStorage.getItem('role') === "ROLE_ADMIN",
            isUser: localStorage.getItem('role') === "ROLE_INSTITUTIONAL_MODERATOR",
            username: localStorage.getItem('email'),
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = (selectedPage = 0) => {
        if (this.state.isAdmin) {
            QuestionRepository.getAllQuestionsAdmin(selectedPage).then(async res => {
                let contentData = res.data.content;

                for (let i = 0; i < contentData.length; i++) {

                    let numberOfElements = 0;
                    await QuestionRepository.numberOfMessagesForQuestion(contentData[i].id).then(snapshot => {
                        numberOfElements = snapshot.data
                    });
                    contentData[i]["numberOfElements"] = numberOfElements;
                }

                this.setState({
                    data: contentData,
                    pageCount: res.data.totalPages
                })
            }).catch(err => {
                console.log(err)
            })
        } else {
            QuestionRepository.getAllQuestions(selectedPage).then(async res => {
                let contentData = res.data.content;

                for (let i = 0; i < contentData.length; i++) {

                    let numberOfElements = 0;
                    await QuestionRepository.numberOfMessagesForQuestion(contentData[i].id).then(snapshot => {
                        numberOfElements = snapshot.data
                    });
                    contentData[i]["numberOfElements"] = numberOfElements;
                }

                this.setState({
                    data: contentData,
                    pageCount: res.data.totalPages
                })
            }).catch(err => {
                console.log(err)
            })
        }
    }

    handlePageClick = data => {
        this.fetchData(data.selected);
    };

    deleteQuestion = (id) => {
        return QuestionRepository.deleteQuestion(id).then(() => {
            toast.success(strings.successfullyDeletedQuestion)
            this.fetchData()
        }).catch(() => {
            toast.error(strings.UnsuccessfullyDeletedQuestion)
        })
    }

    unDeleteQuestion = (id) => {
        return QuestionRepository.unDeleteQuestion(id).then(() => {
            toast.success(strings.shared)
            this.fetchData()
        }).catch(() => {
            toast.error(strings.notShared)
        })
    }


    render() {
        return (
            <div className="container">
                <h1 className="align-items-center justify-content-center"
                    style={{textAlign: 'center', color: "#1C4857"}}>{strings.allActive}</h1>
                <div className="row mt-5" style={{paddingTop: '15px'}}>
                    {this.state.isAdmin &&
                    <NavLink to="/AddQuestion">
                        <button type="button" className="btn btn-primary QuestionAdminActionButton defaultBtnAdd">
                            <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{paddingRight: '4px'}}/>
                            {strings.add}
                        </button>
                    </NavLink>
                    }

                    {this.state.isUser &&
                    <NavLink to="/AddQuestion">
                        <button type="button" className="btn btn-info QuestionAdminActionButton defaultBtn">
                            <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{paddingRight: '4px'}}/>
                            {strings.add}
                        </button>
                    </NavLink>
                    }

                    <br/>
                    {this.state.isUser && (
                        <Link to="/questions/inactive" className="btn btn-dark ml-3 defaultBtn"><i
                            className="fa fa-history"/> {strings.allInactive}</Link>
                    )}
                </div>

                {this.state.data.length !== 0 ?
                    this.state.data.map((item, index) => {
                        return (
                            <div key={index} className="row QuestionMainDiv">
                                {this.state.data[index].active ?
                                    <div className="col-12">

                                        <div className="row QuestionRow">
                                            <div className="col-11">
                                                {this.state.activeLanguage === 'mk' &&
                                                <a href={"/previewQuestion/" + item.id} className="mb-0" style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600',
                                                    color: 'black'
                                                }}>{item.title?.slice(0, 200)}</a>}
                                                {this.state.activeLanguage === 'al' &&
                                                <a href={"/previewQuestion/" + item.id} className="mb-0" style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600',
                                                    color: 'black'
                                                }}>{item.title?.slice(0, 200)}</a>}
                                            </div>
                                            <div className="col-1">
                                                <h6 style={{float: 'right', color: '#343a40'}}>
                                                    <FontAwesomeIcon icon={faComments} size="lg"
                                                                     style={{paddingRight: '4px'}}/>
                                                    {this.state.data[index].numberOfElements}
                                                </h6>
                                            </div>
                                        </div>

                                        <div className="container-fluid publicationDivContainer">
                                            {/*<div className="row announcementTitleRow">*/}
                                            {/*    <div className="col-12">*/}
                                            {/*        <h2 className="announcementTitle">{item.title}</h2>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="row announcementBodyRow">
                                                <div className="col-12" style={{textAlign: 'center'}}>
                                                    <a style={{
                                                        display: "inline-block",
                                                        textAlign: 'center',
                                                        fontSize: '20px'
                                                    }}>
                                                        {/*<Link*/}
                                                        {/*    to={"/previewQuestion/" + item.id}*/}
                                                        {/*    className="announcementLinkButton">{strings.Answers}</Link>*/}
                                                    </a>
                                                </div>
                                            </div>


                                            <div className="col-12 announcementFooterCol">
                                                <h6 style={{float: 'left', color: '#343a40'}}>
                                                    <h6>{strings.authorCreator} {this.state.data[index].author.email}</h6>
                                                    <h6>{strings.institution}: {this.state.data[index].author.institution !== null ? this.state.data[index].author.institution.nameMk : "/"}</h6>
                                                    <span><small>{strings.datePublish} {this.state.data[index].dateCreated.replaceAll("-", ".")}</small></span>
                                                </h6>

                                            </div>

                                        </div>

                                        {(this.state.data[index].author.email === this.state.username || this.state.isAdmin) &&
                                        <div className="row">
                                            <div className="col-12 justify-content-end text-right">
                                                {(this.state.data[index].active) &&
                                                <DeleteModal btnClass={"ml-1 btn defaultBtnDelete"}
                                                             showText={true}
                                                             prompt={strings.deleteQuestionForQuestion}
                                                             doDelete={() => this.deleteQuestion(item.id)}/>}
                                                <br/>


                                                <Link to={{
                                                    pathname: `/EditQuestion`,
                                                    state: {
                                                        item: item
                                                    }
                                                }}
                                                >
                                                    <button type="button"
                                                            className="btn btn-info mr-1 mt-2 defaultBtnEdit">
                                                        <FontAwesomeIcon icon={faEdit} size="lg"
                                                                         style={{paddingRight: '4px'}}/>
                                                        {strings.edit}
                                                    </button>
                                                </Link>

                                                {(!this.state.data[index].active) &&
                                                <UnDeleteModal btnClass={"ml-1 QuestionAdminActionButton defaultBtn"}
                                                               showText={true}
                                                               prompt={strings.shareQuestionAgain}
                                                               doDelete={() => this.unDeleteQuestion(item.id)}/>}

                                            </div>
                                        </div>
                                        }
                                    </div>
                                    : <div className="col-12 opac">

                                        <div className="row QuestionRow">
                                            <div className="col-11">
                                                {this.state.activeLanguage === 'mk' &&
                                                <h5 className="mb-0">{item.title?.slice(0, 200)}</h5>}
                                                {this.state.activeLanguage === 'al' &&
                                                <h5>{item.title?.slice(0, 200)}</h5>}
                                            </div>
                                            <div className="col-1">
                                                <h6 style={{float: 'right', color: '#343a40'}}>
                                                    <FontAwesomeIcon icon={faComments} size="lg"
                                                                     style={{paddingRight: '4px'}}/>
                                                    {this.state.data[index].numberOfElements}
                                                </h6>
                                            </div>
                                        </div>

                                        <div className="container-fluid publicationDivContainer">
                                            {/*<div className="row announcementTitleRow">*/}
                                            {/*    <div className="col-12">*/}
                                            {/*        <h2 className="announcementTitle">{item.title}</h2>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            <div className="row announcementBodyRow">
                                                <div className="col-12" style={{textAlign: 'center'}}>
                                                    <a style={{
                                                        display: "inline-block",
                                                        textAlign: 'center',
                                                        fontSize: '20px'
                                                    }}>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-12 announcementFooterCol">
                                                <h6 style={{float: 'left', color: '#343a40'}}>
                                                    <h6>{strings.author}: {this.state.data[index].author.email}</h6>
                                                    <h6>{strings.institution}: {this.state.data[index].author.institution !== null ? this.state.data[index].author.institution.nameMk : ""}</h6>
                                                    <span><small>{strings.datePublish}: {this.state.data[index].dateCreated}</small></span>
                                                </h6>

                                            </div>

                                        </div>

                                        {(this.state.data[index].author.email === this.state.username || this.state.isAdmin) &&
                                        <div className="row">
                                            <div className="col-12 ">
                                                {(this.state.data[index].active) &&
                                                <DeleteModal btnClass={"ml-1 QuestionAdminActionButton defaultBtn"}
                                                             showText={true}
                                                             prompt={strings.deleteQuestionForQuestion}
                                                             doDelete={() => this.deleteQuestion(item.id)}/>}

                                                {(!this.state.data[index].active) &&
                                                <UnDeleteModal btnClass={"ml-1 QuestionAdminActionButton defaultBtn"}
                                                               showText={true}
                                                               prompt={strings.shareQuestionAgain}
                                                               style={{opacity: '1 !important'}}
                                                               doDelete={() => this.unDeleteQuestion(item.id)}/>}

                                            </div>
                                        </div>
                                        }
                                    </div>
                                }
                            </div>
                        )
                    }) : <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                        <InfoPopup infoMessage={strings.noPublishedQuestions}/>
                    </div>
                }
                {this.state.data.length !== 0 ?
                    <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    /> : <div></div>
                }

            </div>
        )
    }
}

export default (Question);
